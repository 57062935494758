import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

import { Table, Card, Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "../components/ConfirmationModal";

const GroupDetails = ({
  group,
  show,
  hide,
  deleteGroup,
  schedules,
  users,
  handleAddUsers,
  handleAddSchedules,
  handleRemoveUser,
  handleRemoveSchedule
}) => {
  const [confirmationModal, setConfirmationModal] = useState(false);

  const [showSchedules, setShowSchedules] = useState(false);
  const [showUsers, setShowUsers] = useState(false);

  const handleDeleteGroup = () => {
    deleteGroup(group.id);
    setConfirmationModal(false);
  };

  const userIdsInGroup = [];
  for (const user of group.users) {
    userIdsInGroup.push(user.id);
  }

  const scheduleIdsInGroup = [];
  for (const schedule of group.schedules) {
    scheduleIdsInGroup.push(schedule.id);
  }

  const usersNotInGroup = users.filter(
    user => !userIdsInGroup.includes(user.id)
  );
  const schedulesNotInGroup = schedules.filter(
    schedule => !scheduleIdsInGroup.includes(schedule.id)
  );

  const handleAddScheduleToGroup = (schedule, group) => {
    group.schedules.push(schedule);
    handleAddSchedules({ scheduleId: schedule.id, groupId: group.id });
  };

  const handleRemoveScheduleFromGroup = (schedule, group) => {
    group.schedules = group.schedules.filter(item => item.id !== schedule.id);
    handleRemoveSchedule({ scheduleId: schedule.id, groupId: group.id });
  };

  const handleAddUserToGroup = (user, group) => {
    group.users.push(user);
    handleAddUsers({ userId: user.id, groupId: group.id });
  };

  const handleRemoveUserFromGroup = (user, group) => {
    group.users = group.users.filter(item => item.id !== user.id);
    handleRemoveUser({ userId: user.id, groupId: group.id });
  };

  return (
    <div>
      <Modal show={show} onHide={hide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{group.name}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Card className="mb-4" style={{ border: "none" }}>
            <Card.Body>
              <Card.Title>
                <span> {`Users`} </span>
              </Card.Title>

              <Table responsive>
                <tbody>
                  {group.users
                    .sort((a, b) => a.id - b.id)
                    .map(u => {
                      return (
                        <tr key={u.id}>
                          <td className="p-3">
                            {" "}
                            <FontAwesomeIcon
                              onClick={() =>
                                handleRemoveUserFromGroup(u, group)
                              }
                              className="red-color"
                              icon={faMinus}
                            />{" "}
                          </td>
                          <td className="p-3 text-capitalize">{`${u.firstname}  ${u.lastname}`}</td>
                          <td className="p-3">{u.email}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>

              <span
                onClick={() => setShowUsers(!showUsers)}
                className="primary-color mt-3"
              >
                {" "}
                {showUsers
                  ? "hide users not in the group"
                  : "show users not in the group"}{" "}
              </span>
              <br />
              {showUsers && (
                <div>
                  <Table responsive>
                    <tbody>
                      {usersNotInGroup
                        .sort((a, b) => a.id - b.id)
                        .map(u => {
                          return (
                            <tr key={u.id}>
                              <td className="p-3">
                                {" "}
                                <FontAwesomeIcon
                                  onClick={() => handleAddUserToGroup(u, group)}
                                  className="primary-color"
                                  icon={faPlus}
                                />{" "}
                              </td>
                              <td className="p-3 text-capitalize">{`${u.firstname}  ${u.lastname}`}</td>
                              <td className="p-3">{u.email}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              )}
            </Card.Body>
          </Card>

          <Card className="mb-4" style={{ border: "none" }}>
            <Card.Body>
              <Card.Title>
                <span> {`Plans`} </span>
              </Card.Title>
              <Table hover responsive>
                <tbody>
                  {group.schedules
                    .sort((a, b) => a.id - b.id)
                    .map(s => {
                      return (
                        <tr key={s.id}>
                          <td className="p-3">
                            {" "}
                            <FontAwesomeIcon
                              onClick={() =>
                                handleRemoveScheduleFromGroup(s, group)
                              }
                              className="red-color"
                              icon={faMinus}
                            />{" "}
                          </td>
                          <td className="p-3 text-capitalize">{s.name}</td>
                          <td className="p-3 text-capitalize">
                            {s.description}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>

              <span
                onClick={() => setShowSchedules(!showSchedules)}
                className="primary-color mt-3"
              >
                {" "}
                {showSchedules
                  ? "hide plans not in the group"
                  : "show plans not in the group"}{" "}
              </span>
              <br />
              {showSchedules && (
                <div>
                  <Table responsive>
                    <tbody>
                      {schedulesNotInGroup
                        .sort((a, b) => a.id - b.id)
                        .map(s => {
                          return (
                            <tr key={s.id}>
                              <td className="p-3">
                                {" "}
                                <FontAwesomeIcon
                                  onClick={() =>
                                    handleAddScheduleToGroup(s, group)
                                  }
                                  className="primary-color"
                                  icon={faPlus}
                                />{" "}
                              </td>
                              <td className="p-3 text-capitalize">{`${s.name}`}</td>
                              <td className="p-3">{s.description}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              )}
            </Card.Body>
          </Card>
        </Modal.Body>

        <Modal.Footer className="justify-content-end">
          <Button variant={"danger"} onClick={() => setConfirmationModal(true)}>
            <FontAwesomeIcon icon={faTrashAlt} />
            <span> {"Delete"} </span>
          </Button>
        </Modal.Footer>
      </Modal>

      <ConfirmationModal
        titleColor={"red-color"}
        title={
          "Warning: You will not be able to recover this data once deleted"
        }
        description={`Are you sure you want to delete group ${group.name} permanently ?`}
        show={confirmationModal}
        hide={() => setConfirmationModal(false)}
        confirm={handleDeleteGroup}
      />
    </div>
  );
};

export default GroupDetails;
