import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as types from "../actions/types";

import Navigation from "../components/Navigation";
import { getCompany } from "../selectors";

const mapStateToProps = state => {
  return {
    user: state.user,
    companyName: getCompany(state)
  };
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch({ type: types.LOGOUT })
});

const NavigationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigation);

export default NavigationContainer;

Navigation.propTypes = {
  logout: PropTypes.func.isRequired,
  user: PropTypes.shape({
    currentUser: PropTypes.shape({
      email: PropTypes.string.isRequired
    })
  }),
  companyName: PropTypes.string.isRequired
};
