import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_ERROR,
  FETCH_SPECIFIC_USER_REQUEST,
  FETCH_SPECIFIC_USER_SUCCESS
} from "../actions/types";

const initialState = {
  loading: false,
  error: false,
  users: [],
  usersWithDetail: []
};

const replaceInArray = (user, array) => {
  const index = array.findIndex(u => u.id === user.id);

  const arrayCopy = [...array];
  if (index !== -1) {
    arrayCopy[index] = user;
  } else {
    arrayCopy.push(user);
  }
  return arrayCopy;
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
      return { ...state, loading: true, error: false };
    case FETCH_USERS_SUCCESS:
      return { ...state, loading: false, error: false, users: action.users };
    case FETCH_USERS_ERROR:
      return { ...state, loading: false, error: true };
    case FETCH_SPECIFIC_USER_REQUEST:
      return { ...state, loading: true, error: false };
    case FETCH_SPECIFIC_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        usersWithDetail: replaceInArray(action.user, state.usersWithDetail)
      };
  }
  return state;
}
