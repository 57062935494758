import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { Navbar, Nav } from "react-bootstrap";

import FeatureAwareContainer from "../containers/FeatureAwareContainer";

const Navigation = ({ user, logout, companyName }) => {
  const history = useHistory();

  const handleLogout = () => {
    logout();
    history.push("/login");
  };

  return (
    <div>
      <Navbar
        expand="lg"
        variant="dark"
        style={{ padding: "10px 20px" }}
        className="fixed-top nav-background-color"
      >
        <Navbar.Brand href={user && user.currentUser ? "/plans" : "/login"}>
          <img src="/perfect-day-logo.svg" height="45" alt={"Perfect Day"} />{" "}
          {/* <span
            style={{
              marginLeft: "2px",
              color: "white",
              fontSize: "14px",
              fontWeight: "600"
            }}
          >
            {"BLACKBIRD"}
          </span> */}
          {/* <h4 style={{ color: "white", margin: "0" }}>Perfect Day</h4> */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        {(() => {
          if (user && user.currentUser) {
            return (
              <Navbar.Collapse
                id="basic-navbar-nav"
                style={{ fontWeight: "600" }}
              >
                <Nav className="">
                  <Nav.Link className="style-nav-links" href="/">
                    Dashboard
                  </Nav.Link>
                  <Nav.Link className="style-nav-links" href="/plans">
                    Plans
                  </Nav.Link>
                  <FeatureAwareContainer requiredRole="COMPANY_ADMINISTRATOR">
                    <Nav.Link className="style-nav-links" href="/users">
                      Users
                    </Nav.Link>
                    <Nav.Link className="style-nav-links" href="/groups">
                      Groups
                    </Nav.Link>

                    <Nav.Link className="style-nav-links" href="/roles">
                      Roles
                    </Nav.Link>

                    <Nav.Link
                      className="style-nav-links"
                      href="/configurations"
                    >
                      Configurations
                    </Nav.Link>
                    {/* <Nav.Link className="style-nav-links" href="/manage-plans">Manage Plans</Nav.Link> */}
                  </FeatureAwareContainer>
                  <FeatureAwareContainer requiredRole="ADMINISTRATOR">
                    <Nav.Link className="style-nav-links" href="/companies">
                      Companies
                    </Nav.Link>
                  </FeatureAwareContainer>
                </Nav>
              </Navbar.Collapse>
            );
          } else {
            return (
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                <Nav>
                  <Nav.Link className="style-nav-links" href="/login">
                    Sign in
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            );
          }
        })()}

        {(() => {
          if (user && user.currentUser) {
            return (
              <Navbar.Collapse
                className="justify-content-end"
                style={{ fontWeight: "600" }}
              >
                <Navbar.Text
                  style={{
                    fontWeight: "normal",
                    marginRight: "20px",
                    color: "white"
                  }}
                >
                  Signed in as: {user.currentUser.email} - {companyName}
                </Navbar.Text>
                <Nav>
                  <Nav.Item>
                    <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            );
          }
        })()}
      </Navbar>
    </div>
  );
};

export default Navigation;

Navigation.propTypes = {
  logout: PropTypes.func.isRequired,
  user: PropTypes.shape({
    currentUser: PropTypes.shape({
      email: PropTypes.string.isRequired
    })
  }),
  companyName: PropTypes.string.isRequired
};
