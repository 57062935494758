import React from "react";

import { Row, Col, Card, Tabs, Tab, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faPlus } from "@fortawesome/free-solid-svg-icons";

import Holidays from "./HolidaysContainer";

class Configurations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHolidaysModal: false
    };
  }

  handleShowHideHolidaysModal = () => {
    this.setState({ showHolidaysModal: !this.state.showHolidaysModal });
  };

  render() {
    return (
      <div>
        <Card
          className="mb-4"
          style={{
            boxShadow:
              "0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)"
          }}
        >
          <Card.Body>
            <Row>
              <Col>
                <h3 style={{ marginTop: "1rem" }}> Configurations </h3>
              </Col>

              <Col>
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    position: "absolute",
                    bottom: "10px",
                    right: "20px"
                  }}
                >
                  <Dropdown style={{ float: "right" }}>
                    <Dropdown.Toggle
                      id={`dropdown-autoclose-true`}
                      className="remove-toggle-arrow add-background-on-hover"
                      variant="default"
                      style={{
                        border: "none",
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px"
                      }}
                    >
                      <FontAwesomeIcon
                        id={``}
                        icon={faEllipsisV}
                        className="primary-color"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={this.handleShowHideHolidaysModal}>
                        {" "}
                        <FontAwesomeIcon
                          style={{ marginRight: "5px" }}
                          icon={faPlus}
                          className="primary-color"
                        />
                        <span> Add Holidays </span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card
          className="mb-4"
          style={{
            boxShadow:
              "0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)"
          }}
        >
          <Card.Body>
            <Row>
              <Col>
                <Tabs
                  defaultActiveKey="holiday"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="holiday" title="Holidays">
                    <div>
                      <Holidays
                        showHolidaysModal={this.state.showHolidaysModal}
                        showHideHolidayModal={this.handleShowHideHolidaysModal}
                      />
                    </div>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default Configurations;
