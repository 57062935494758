import {
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_ERROR,
  ADMIN_SET_ACTIVE_COMPANY_ID
} from "../actions/types";

const initialState = {
  loading: false,
  error: false,
  companies: [],
  activeCompanyId: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_COMPANIES_REQUEST:
      return { ...state, loading: true, error: false };
    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        companies: action.companies
      };
    case FETCH_COMPANIES_ERROR:
      return { ...state, loading: false, error: true };
    case ADMIN_SET_ACTIVE_COMPANY_ID:
      return { ...state, activeCompanyId: action.activeCompanyId };
  }
  return state;
}
