import {
  LOGOUT,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR
} from "../actions/types";

const initialState = { currentUser: null, loading: false, error: false };

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return { ...state, loading: true, error: false };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        currentUser: action.payload
      };
    case FETCH_USER_ERROR:
      return { ...state, loading: false, error: true, currentUser: null };
    case LOGOUT:
      return { ...state, loading: false, error: false, currentUser: null };
    default:
  }
  return state;
}
