import React from "react";
import PropTypes from "prop-types";
import ListGroup from "react-bootstrap/ListGroup";

function CompanyList({ companies, onClickItem, activeId }) {
  return (
    <ListGroup>
      {companies.map(c => (
        <ListGroup.Item
          active={activeId === c.id}
          key={c.id}
          onClick={() => onClickItem(c.id)}
        >
          {c.name}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}

export default CompanyList;

CompanyList.propTypes = {
  activeId: PropTypes.number.isRequired,
  onClickItem: PropTypes.func.isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
};
