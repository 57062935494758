import React, { useState } from "react";
import PropTypes from "prop-types";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function CreateGroupForm({ onSubmit, modifiedItem }) {
  const [name, setName] = useState(modifiedItem?.name || "");
  const [description, setDescription] = useState(
    modifiedItem?.description || ""
  );

  return (
    <div>
      <Form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Form.Group className="mb-3" controlId="formBasicName">
          <Form.Label>Name*</Form.Label>
          <Form.Control
            value={name}
            type="text"
            placeholder="Enter Name of group"
            onChange={e => setName(e.target.value)}
          />
          <Form.Text className="text-muted">
            Enter the name of the group you want to create
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            value={description}
            type="text"
            placeholder="Description"
            onChange={e => setDescription(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Button
            className="float-end"
            disabled={!(name.length > 0)}
            onClick={() => {
              if (name.length > 0) {
                onSubmit(name, description);
              }
            }}
            variant="primary"
            type="submit"
          >
            Submit
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}

export default CreateGroupForm;

CreateGroupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
