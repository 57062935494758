import {
  FETCH_EVENTS_REQUEST,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_ERROR,
  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_ERROR
} from "../actions/types";

const initialState = { loading: false, error: false };

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_EVENTS_REQUEST:
      return { ...state, loading: true, error: false };
    case FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        [`${action.companyId}-${action.scheduleId}`]: action.events
      };
    case FETCH_EVENTS_ERROR:
      return { ...state, loading: false, error: true };
    case CREATE_EVENT_REQUEST:
      return { ...state, loading: true, error: false };
    case CREATE_EVENT_SUCCESS:
      return { ...state, loading: false, error: false }; // fetch events after so no update state here.
    case CREATE_EVENT_ERROR:
      return { ...state, loading: false, error: true };
  }
  return state;
}
