import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const roles = {
  UNKNOWN: -5,
  OPERATOR: 1,
  COMPANY_ADMINISTRATOR: 5,
  ADMINISTRATOR: 10
};

const FeatureAwareContainer = ({ children, requiredRole, role }) => {
  if (roles[requiredRole] && roles[role] >= roles[requiredRole]) {
    return children;
  } else {
    return <></>;
  }
};

const mapStateToProps = state => {
  const role =
    state.user && state.user.currentUser
      ? state.user.currentUser.role
      : "UNKNOWN";
  return {
    role
  };
};

export default connect(mapStateToProps)(FeatureAwareContainer);

FeatureAwareContainer.propTypes = {
  children: PropTypes.node,
  requiredRole: PropTypes.oneOf([
    "OPERATOR",
    "COMPANY_ADMINISTRATOR",
    "ADMINISTRATOR"
  ]),
  role: PropTypes.oneOf(["OPERATOR", "COMPANY_ADMINISTRATOR", "ADMINISTRATOR"])
};
