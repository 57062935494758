export const getCompanyId = state => {
  if (state.user.currentUser.role == "ADMINISTRATOR") {
    if (state.companies.activeCompanyId) {
      return state.companies.activeCompanyId;
    }
  }
  return state.user.currentUser.companyId;
};

export const getCompany = state => {
  try {
    if (state.user.currentUser.role == "ADMINISTRATOR") {
      if (state.companies.activeCompanyId) {
        return state.companies.companies.find(
          c => c.id == state.companies.activeCompanyId
        ).name;
      }
    }
    return state.user.currentUser.companyName;
  } catch (err) {
    return "";
  }
};
