import React, { useState } from "react";
import PropTypes from "prop-types";

import { Form, Button, Card } from "react-bootstrap";

const VerifyAccountForm = ({ onSubmit, token }) => {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [licence, setLicense] = useState(false);
  return (
    <Card
      style={{
        margin: "0 auto",
        verticalAlign: "middle",
        width: "100%",
        padding: "20px",
        boxShadow:
          "0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)",
        backgroundColor: "#fff"
      }}
    >
      <Card.Title className="text-center mt-4">
        {" "}
        <h4> Verify Account </h4>
      </Card.Title>

      <Card.Body>
        <Form>
          <Form.Group className="mb-4" controlId="formGroupPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-4" controlId="formGroupPassword2">
            <Form.Label>Repeat Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Repeat password"
              value={password2}
              onChange={e => setPassword2(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-4" controlId="formBasicCheckbox">
            <Form.Check
              onChange={() => setLicense(!licence)}
              type="checkbox"
              label="I hereby give my consent to Blackbird ApS to register and store the following data: Name, company e-mail address, company name, title, mobile phone number. I also give my consent to receive the Blackbird Newsletter. (You can always unsubscribe from the “unsubscribe” button of emails you receive)"
            />

            <Form.Text className="text-muted"></Form.Text>
          </Form.Group>

          <Button
            variant="primary"
            disabled={
              !(password.length > 7 && password === password2 && licence)
            }
            onClick={() => onSubmit(password)}
          >
            VERIFY ACCOUNT
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default VerifyAccountForm;

VerifyAccountForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired
};
