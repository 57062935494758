import React from "react";
import PropTypes from "prop-types";
import chromeLogo from "../images/chrome.jpg";
import firefoxLogo from "../images/firefox.jpg";
import safariLogo from "../images/safari.jpg";

function outDatedBrowser() {
  return (
    <div>
      <h1>You are using an Outdated Browser</h1>
      <p>Perfect Day doesn't support Internet Explorer.</p>
      <p>
        For a faster, safer browsing experience, please upgrade your web browser
        today:
      </p>
      <ul>
        <li>
          <a
            href="https://www.getfirefox.com"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <img
              src={firefoxLogo}
              border="0"
              alt="Download Firefox"
              align="top"
            />
            Download Firefox
          </a>
        </li>
        <li>
          <a
            href="https://www.google.com/chrome"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <img
              src={chromeLogo}
              border="0"
              alt="Download Google Chrome"
              align="top"
            />{" "}
            Download Google Chrome
          </a>
        </li>
        <li>
          <a
            href="https://www.apple.com/safari"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <img
              src={safariLogo}
              border="0"
              alt="Download Safari for Windows"
              align="top"
            />
            Download Safari
          </a>
        </li>{" "}
      </ul>
    </div>
  );
}

export default outDatedBrowser;

outDatedBrowser.propTypes = {};
