import React, { useState } from "react";
import moment from "moment";

import { Dropdown, DropdownButton } from "react-bootstrap";

const TODAY = "today";
const ONE_WEEK = "1_week";
const ONE_MONTH = "1_month";
const THREE_MONTHS = "3_months";

export const CustomTimeRange = ({
  handleFilter,
  defaultDropdownText = "Today"
}) => {
  const [dropdownButtonTitle, setDropdownButtonTitle] = useState(
    defaultDropdownText
  );
  const today = moment().format("YYYY-MM-DD");

  const handleDateRange = value => {
    let text = "1 Week";
    setDropdownButtonTitle(text);

    switch (value) {
      case TODAY:
        text = "Today";
        handleFilter(today);
        break;

      case ONE_WEEK:
        const oneWeek = moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD");

        text = "1 Week";
        handleFilter(oneWeek);
        break;
      case ONE_MONTH:
        const oneMonth = moment()
          .subtract(1, "months")
          .format("YYYY-MM-DD");

        text = "1 Month";
        handleFilter(oneMonth);

        break;
      case THREE_MONTHS:
        const threeMonths = moment()
          .subtract(3, "months")
          .format("YYYY-MM-DD");

        text = "3 Months";
        handleFilter(threeMonths);
        break;

      default:
        break;
    }
    setDropdownButtonTitle(text);
  };

  return (
    <DropdownButton
      id="dropdown-item-button"
      title={dropdownButtonTitle}
      style={{ display: "inline" }}
    >
      <Dropdown.Item
        as="button"
        className="btn-sm"
        onClick={e => handleDateRange(TODAY)}
      >{`Today`}</Dropdown.Item>

      <Dropdown.Item
        as="button"
        className="btn-sm"
        onClick={e => handleDateRange(ONE_WEEK)}
      >{`1 Week`}</Dropdown.Item>

      <Dropdown.Item
        as="button"
        className="btn-sm"
        onClick={e => handleDateRange(ONE_MONTH)}
      >{`1 Month`}</Dropdown.Item>

      <Dropdown.Item
        as="button"
        className="btn-sm"
        onClick={e => handleDateRange(THREE_MONTHS)}
      >{`3 Months`}</Dropdown.Item>
    </DropdownButton>
  );
};
