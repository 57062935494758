import React from "react";
import PropTypes from "prop-types";

import Modal from "react-bootstrap/Modal";

function CustomModal({ show, title, onHide, children }) {
  return (
    <Modal size="lg" show={show} onHide={() => onHide()}>
      <Modal.Header className="p-3 ps-5 pe-5" closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5 pt-3 pb-4">{children}</Modal.Body>
    </Modal>
  );
}

export default CustomModal;

CustomModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node
};
