import React, { useState } from "react";
import PropTypes from "prop-types";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const roles = ["OPERATOR", "COMPANY_ADMINISTRATOR"];

const submit = (fields, formFields, onSubmit) => {
  const obj = {};
  for (let f of fields) {
    obj[f.id] = f.defaultValue;
  }
  onSubmit({ ...obj, ...formFields });
};

function CreateForm({ fields, onSubmit, deleteButton = "" }) {
  const [formFields, setFormfields] = useState({});
  for (let field of fields) {
  }

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(roles[0]);

  return (
    <div>
      <Form
        onSubmit={e => {
          e.preventDefault();
          submit(fields, formFields, onSubmit);
        }}
      >
        {fields.map(f => (
          <Form.Group className="mb-3" key={f.id} controlId={f.id}>
            <Form.Label>{f.name}</Form.Label>
            <Form.Control
              value={formFields[f.id]}
              type={f.type}
              defaultValue={f.defaultValue}
              placeholder={f.description}
              onChange={e =>
                setFormfields({ ...formFields, [f.id]: e.target.value })
              }
            />
          </Form.Group>
        ))}
        <br />
        <Row>
          <Col>{deleteButton}</Col>

          <Col>
            <Button className="float-end" variant="primary" type="submit">
              <FontAwesomeIcon icon={faCheck} />{" "}
              <span style={{ marginLeft: "2px" }}>{`Save`}</span>
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default CreateForm;

CreateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
