import React from "react";
import moment from "moment";

import { Tooltip, OverlayTrigger } from "react-bootstrap";

export const DAYS = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY"
];

export const getItemsForDuration = (events, to, daysback, days, holidays) => {
  return Array.from(Array(daysback).keys())
    .map(a => {
      const date = moment(to.format("YYYY-MM-DD")).subtract(a, "days");
      let shouldRegister = days.includes(DAYS[date.isoWeekday() - 1]);
      const isHoliday =
        holidays &&
        holidays.find(
          holiday => moment(date).format("YYYY-MM-DD") === holiday.offDate
        );
      if (isHoliday) {
        shouldRegister = false;
      }
      const foundEvent = events.find(e => date.isSame(e.eventDate, "day"));

      return {
        date: date.format("YYYY-MM-DD"),
        index: a,
        value: "NONE",
        shouldRegister,
        ...foundEvent
      };
    })
    .reverse();
};

const StatusOverview = ({ schedule, from, days, holidays }) => {
  const eventItems = [];
  schedule.actions.map(a => {
    const res =
      a.days && getItemsForDuration(a.events, from, days, a.days, holidays);
    if (res) {
      eventItems.push(...res);
    }
  });
  const itemsToRegister = eventItems.filter(i => i.shouldRegister);
  const percentageCompleted = Math.round(
    (itemsToRegister.filter(i => i.value === "TRUE").length /
      (itemsToRegister.length > 0 ? itemsToRegister.length : 1)) *
      100
  );

  const red = itemsToRegister.filter(item => item.value === "FALSE");
  const yellow = itemsToRegister.filter(item => item.value === "YELLOW");
  const green = itemsToRegister.filter(item => item.value === "TRUE");
  const grey = itemsToRegister.filter(
    item => item.value === "NONE" && item.shouldRegister === true
  );
  return itemsToRegister.length ? (
    <span>
      <OverlayTrigger overlay={<Tooltip>{`Success rate`}</Tooltip>}>
        <div className="me-3 custom-chip background-primary">
          {`${percentageCompleted}%`}
        </div>
      </OverlayTrigger>

      <OverlayTrigger overlay={<Tooltip>{`Successful events`}</Tooltip>}>
        <div className="me-3 custom-chip background-success">
          {green.length}
        </div>
      </OverlayTrigger>

      <OverlayTrigger overlay={<Tooltip>{`Ineffective events`}</Tooltip>}>
        <div className="me-3 custom-chip background-warning">
          {yellow.length}
        </div>
      </OverlayTrigger>

      <OverlayTrigger overlay={<Tooltip>{`Bad marked events`}</Tooltip>}>
        <div className="me-3 custom-chip background-danger"> {red.length} </div>
      </OverlayTrigger>

      <OverlayTrigger overlay={<Tooltip>{`Unmarked events`}</Tooltip>}>
        <div className="me-3 custom-chip background-secondary">
          {grey.length}
        </div>
      </OverlayTrigger>
    </span>
  ) : (
    ""
  );
};

export default StatusOverview;
