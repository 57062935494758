import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import { Card, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import { enGB } from "date-fns/locale";
import staticRangesGenerator from "../../src/helpers/static-ranges";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import * as types from "../actions/types";
import { getCompanyId } from "../selectors";
import { getItemsForDuration } from "../components/StatusOverview";

const ONE_DAY = "1_day";
const ONE_WEEK = "1_week";
const ONE_MONTH = "1_month";
const THREE_MONTHS = "3_months";

const bgColors = [
  "background-success",
  "background-warning",
  "background-danger",
  "background-secondary"
];
const eventNames = ["Successful", "Ineffective", "Failed", "Unmarked"];
class HomeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: moment()
        .subtract(7, "days")
        .format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),

      isOneDayActive: false,
      isOneWeekActive: true,
      isOneMonthActive: false,
      isThreeMonthsActive: false,
      showCustomDateRange: false,
      isCustomRangeActive: false
    };

    this.wrapperRef = React.createRef();
    this.wrapperButtonRef = React.createRef();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidMount() {
    if (this.props.user.currentUser === null) {
      this.props.history.push("/login");
    } else {
      document.addEventListener("mousedown", this.handleClickOutside);
      this.props.fetchSchedules(
        this.props.companyId,
        this.state.fromDate,
        this.state.toDate
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.fromDate !== this.state.fromDate ||
      prevState.toDate !== this.state.toDate
    ) {
      this.props.fetchSchedules(
        this.props.companyId,
        this.state.fromDate,
        this.state.toDate
      );
    }
  }

  handleClickOutside = event => {
    if (
      this?.wrapperRef &&
      !this?.wrapperRef?.current?.contains(event.target) &&
      !this?.wrapperButtonRef?.current?.contains(event.target)
    ) {
      this.setState({ showCustomDateRange: false });
    }
  };
  handleCustomDateButton = () => {
    this.setState({ showCustomDateRange: !this.state.showCustomDateRange });
  };

  handleCustomDateRange = ({ selection }) => {
    const fromDate = moment(new Date(selection.startDate)).format("YYYY-MM-DD");
    const toDate = moment(new Date(selection.endDate)).format("YYYY-MM-DD");
    this.setState({
      fromDate,
      toDate,
      isOneDayActive: false,
      isOneWeekActive: false,
      isOneMonthActive: false,
      isThreeMonthsActive: false,
      isCustomRangeActive: true
    });
  };

  handleFilter = duration => {
    const today = moment().format("YYYY-MM-DD");
    switch (duration) {
      case ONE_DAY:
        this.setState({
          isOneDayActive: true,
          isOneWeekActive: false,
          isOneMonthActive: false,
          isThreeMonthsActive: false,
          isCustomRangeActive: false,
          fromDate: moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD"),
          toDate: today
        });
        break;
      case ONE_WEEK:
        this.setState({
          isOneDayActive: false,
          isOneWeekActive: true,
          isOneMonthActive: false,
          isThreeMonthsActive: false,
          isCustomRangeActive: false,
          fromDate: moment()
            .subtract(7, "days")
            .format("YYYY-MM-DD"),
          toDate: today
        });
        break;
      case ONE_MONTH:
        const dateMonthBack = moment()
          .subtract(1, "months")
          .format("YYYY-MM-DD");

        this.setState({
          isOneDayActive: false,
          isOneWeekActive: false,
          isOneMonthActive: true,
          isThreeMonthsActive: false,
          isCustomRangeActive: false,
          fromDate: dateMonthBack,
          toDate: today
        });
        break;
      case THREE_MONTHS:
        const dateThreeMonthsBack = moment()
          .subtract(3, "months")
          .format("YYYY-MM-DD");

        this.setState({
          isOneDayActive: false,
          isOneWeekActive: false,
          isOneMonthActive: false,
          isThreeMonthsActive: true,
          isCustomRangeActive: false,
          fromDate: dateThreeMonthsBack,
          toDate: today
        });
        break;
      default:
        break;
    }
  };
  renderFilters = () => {
    const staticRanges = staticRangesGenerator(enGB);

    return (
      <Row>
        <Col>
          <Button
            style={{
              width: "100px",
              padding: "5px",
              margin: "5px",
              marginRight: "20px"
            }}
            variant={this.state.isOneDayActive ? "primary" : "light"}
            size="sm"
            onClick={() => this.handleFilter(ONE_DAY)}
          >{`1 Day`}</Button>

          <Button
            style={{
              width: "100px",
              padding: "5px",
              margin: "5px",
              marginRight: "20px"
            }}
            variant={this.state.isOneWeekActive ? "primary" : "light"}
            size="sm"
            onClick={() => this.handleFilter(ONE_WEEK)}
          >{`1 Week`}</Button>

          <Button
            style={{
              width: "100px",
              padding: "5px",
              margin: "5px",
              marginRight: "20px"
            }}
            variant={this.state.isOneMonthActive ? "primary" : "light"}
            size="sm"
            onClick={() => this.handleFilter(ONE_MONTH)}
          >{`1 Month`}</Button>

          <Button
            style={{
              width: "100px",
              padding: "5px",
              margin: "5px",
              marginRight: "20px"
            }}
            variant={this.state.isThreeMonthsActive ? "primary" : "light"}
            size="sm"
            onClick={() => this.handleFilter(THREE_MONTHS)}
          >{`3 Months`}</Button>

          <Button
            ref={this.wrapperButtonRef}
            variant={this.state.isCustomRangeActive ? "primary" : "light"}
            size="sm"
            onClick={() => this.handleCustomDateButton()}
          >{`Custom Date Range`}</Button>

          {/* <span className="ms-5"> {`${this.state.fromDate} - ${this.state.toDate}`} </span> */}

          <div style={{ position: "relative", display: "inline-block" }}>
            <div
              ref={this.wrapperRef}
              id="custom-date-range-picker"
              style={{
                display: this.state.showCustomDateRange ? "inherit" : "none",
                position: "absolute",
                zIndex: 1,
                left: "-142px",
                top: "20px"
              }}
            >
              <DateRange
                locale={enGB}
                staticRanges={staticRanges}
                weekStartsOn={1}
                moveRangeOnFirstSelection={false}
                maxDate={new Date()}
                ranges={[
                  {
                    startDate: new Date(this.state.fromDate),
                    endDate: new Date(this.state.toDate),
                    key: "selection"
                  }
                ]}
                editableDateInputs={true}
                onChange={this.handleCustomDateRange}
              />
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  renderCards = numberOfDays => {
    const eventItems = [];
    this.props.scheduleList.map(schedule => {
      schedule.actions.map(action => {
        const res =
          action.days &&
          getItemsForDuration(
            action.events,
            moment(new Date(this.state.toDate)),
            numberOfDays,
            action.days
          );
        if (res) {
          eventItems.push(...res);
        }
      });
    });
    const green = eventItems.filter(item => item.value === "TRUE");
    const yellow = eventItems.filter(item => item.value === "YELLOW");
    const red = eventItems.filter(item => item.value === "FALSE");
    // const grey = eventItems.filter(
    //   item => item.value === "NONE" && item.shouldRegister === true
    // );

    return [green, yellow, red].map((item, index) => {
      return (
        <Col className="mb-2" xs={{ span: 12 }} sm={{ span: 4 }}>
          <div
            style={{
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
            }}
            className=" "
          >
            <div className={`dashboard-boxes ${bgColors[index]}`}>
              <div className="center-div">
                <h3 className="text-center"> {`${item.length}`} </h3>
              </div>
            </div>
            {/* <div>
              <p className="text-center p-2"> {`${eventNames[index]} Events`} </p>
            </div> */}
          </div>
        </Col>
      );
    });
  };

  renderBarChart = numberOfDays => {
    const scheduleNamesArray = [];
    const greens = [];
    const yellows = [];
    const reds = [];
    const greys = [];

    this.props.scheduleList.map(schedule => {
      scheduleNamesArray.push(schedule.name);

      const eventItems = [];
      schedule.actions.map(action => {
        const res =
          action.days &&
          getItemsForDuration(
            action.events,
            moment(new Date(this.state.toDate)),
            numberOfDays,
            action.days
          );
        if (res) {
          eventItems.push(...res);
        }
      });
      const green = eventItems.filter(item => item.value === "TRUE");
      const yellow = eventItems.filter(item => item.value === "YELLOW");
      const red = eventItems.filter(item => item.value === "FALSE");
      const grey = eventItems.filter(
        item => item.value === "NONE" && item.shouldRegister === true
      );
      greens.push(green.length);
      yellows.push(yellow.length);
      reds.push(red.length);
      greys.push(grey.length);
    });

    let chartHeight = this.props.scheduleList.length * 60;
    if (chartHeight < 400) {
      chartHeight = 400;
    }

    const options = {
      chart: {
        type: "bar",
        // height: "600px"
        height: chartHeight
      },
      title: {
        text: `Perfect Day Analytics ${moment(
          new Date(this.state.fromDate)
        ).format("DD  MMM, yyyy")} - ${moment(
          new Date(this.state.toDate)
        ).format("DD  MMM, yyyy")}`
      },

      xAxis: {
        categories: scheduleNamesArray,
        title: {
          text: null
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
          align: "high"
        },
        labels: {
          overflow: "justify"
        }
      },

      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "top",
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
        shadow: true
      },

      series: [
        {
          name: eventNames[0],
          data: greens,
          color: "#59b78c"
        },
        {
          name: eventNames[1],
          data: yellows,
          color: "#f8d6a7"
        },
        {
          name: eventNames[2],
          data: reds,
          color: "#d0021b"
        }
        // {
        //   name: eventNames[3],
        //   data: greys,
        //   color: "#grey"
        // }
      ]
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
  };

  render() {
    const { firstname = "", lastname = "" } = this.props.user.currentUser;
    const date1 = new Date(this.state.fromDate);
    const date2 = new Date(this.state.toDate);

    const timeDifference = date2.getTime() - date1.getTime();
    let numberOfDays = timeDifference / (1000 * 3600 * 24);
    numberOfDays = numberOfDays < 1 ? 1 : numberOfDays;

    if (this.props.scheduleList.length < 1) {
      return (
        <div className="text-center align-middle">
          <Card
            style={{
              boxShadow:
                "0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)"
            }}
            className="mb-4 p-3"
          >
            <Card.Body>
              <Card.Title className="text-capitalize">{`Welcome ${firstname} ${lastname}`}</Card.Title>
              <Card.Text>{"Let's make today a Perfect Day"}</Card.Text>

              <Link
                style={{ color: "white", textDecoration: "none" }}
                to="/plans"
              >
                <Button className="btn btn-lg">{"Go to Plans"}</Button>
              </Link>
            </Card.Body>
          </Card>
        </div>
      );
    } else {
      return (
        <div>
          <Card
            className="mb-3"
            style={{
              boxShadow:
                "0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)"
            }}
          >
            <Card.Body>
              <Row>
                <Col md={{ span: 2 }}>
                  <h3>Dashboard</h3>
                </Col>

                <Col md={{ span: 10 }}>{this.renderFilters()}</Col>
              </Row>
            </Card.Body>
          </Card>
          <Row className="mb-4">{this.renderCards(numberOfDays)}</Row>

          <Row>{this.renderBarChart(numberOfDays)}</Row>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  const scheduleList =
    state.scheduleList && state.scheduleList.schedules
      ? state.scheduleList.schedules
      : [];
  const total_schedules =
    state.scheduleList && state.scheduleList.total_schedules;

  return {
    user: state.user,
    companyId: getCompanyId(state),
    scheduleList,
    total_schedules
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchSchedules: (companyId, fromDate, toDate) =>
      dispatch({
        type: types.FETCH_SCHEDULES_REQUEST,
        companyId,
        withOverview: true,
        fromDate,
        toDate
      })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);

HomeContainer.propTypes = {
  fetchSchedules: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  scheduleList: PropTypes.arrayOf(
    PropTypes.shape({
      companyId: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  history: PropTypes.object.isRequired
};
