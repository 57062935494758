import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  Form,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  ButtonToolbar,
  Badge,
  Card
} from "react-bootstrap";
import moment from "moment";

import ConfirmationModal from "../components/ConfirmationModal";

const typeTrueFalseNone = [
  { variant: "outline-success", value: "TRUE", color: "#D0021B" },
  { variant: "outline-danger", value: "FALSE", color: "#D0021B" }
];

const typeTrueFalseMaybe = [
  ...typeTrueFalseNone,
  { variant: "outline-warning", value: "YELLOW", color: "#F8D6A7" }
];

function CreateEventForm({
  actionId,
  onSubmit,
  defaultValue,
  defaultComment,
  defaultIncapableToMark,
  date,
  isCreate,
  action,
  planType,
  inputType,
  extraValues,
  deleteEvent
}) {
  const [value, setValue] = useState(defaultValue);
  const [comment, setComment] = useState(defaultComment);
  const [number, setNumber] = useState(
    extraValues && extraValues.inputType === "NUMBER"
      ? `${extraValues.value}`
      : ""
  );
  const [incapableToMark, setIncapableToMark] = useState(
    defaultIncapableToMark
  );
  const [confirmationModal, setConfirmationModal] = useState(false);

  const validTypes =
    planType === "RED_GREEN" ? typeTrueFalseNone : typeTrueFalseMaybe;
  // const selectedColor = validTypes.find(v => v.value === value)
  //   ? validTypes.find(v => v.value === value).color
  //   : "GRAY";

  const disableSaveButton = () => {
    if (!incapableToMark) {
      return (
        value === "NONE" ||
        (inputType === "NUMBER" &&
          (isNaN(number) || number.length === 0 || isNaN(parseFloat(number))))
      );
    } else if (incapableToMark) {
      return comment ? false : true;
    }
  };

  const handleMarkAsIncapable = e => {
    setIncapableToMark(e.target.checked);
    if (e.target.checked) {
      setValue("INCAPABLE_TO_MARK");
    } else {
      setValue(defaultValue);
    }
  };

  const handleDeleteEvent = () => {
    const { actionId, id } = action;
    deleteEvent(actionId, id);
    setConfirmationModal(false);
  };

  return (
    <div>
      <h3>{action.name}</h3>
      <Form.Text className="text-muted">{action.comment}</Form.Text>
      <Form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Form.Group className="mb-3">
          <Form.Check
            checked={incapableToMark}
            type="switch"
            id="custom-switch"
            label="Not applicable (must provide comment)"
            onChange={e => handleMarkAsIncapable(e)}
          />
        </Form.Group>

        {!incapableToMark && (
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Status*</Form.Label>
            <Card>
              <ButtonToolbar
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "stretch"
                }}
              >
                <ToggleButtonGroup
                  type="radio"
                  name="options"
                  defaultValue={value}
                  style={{
                    flex: 1,
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "stretch"
                  }}
                >
                  {validTypes.map(d => (
                    <ToggleButton
                      style={{ minHeight: 40, margin: 5, flex: 1 }}
                      onClick={() => setValue(d.value)}
                      value={d.value}
                      name={d.value}
                      active={d.value === value}
                      key={d.variant}
                      size="lg"
                      action
                      variant={d.variant}
                      disabled={false}
                    ></ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </ButtonToolbar>
            </Card>
            <Form.Text className="text-muted">Select the status </Form.Text>
            <span className="float-end">
              <Badge bg="success">
                {action.goodCriteria
                  ? ` Good Criteria: ${action.goodCriteria}`
                  : ""}
              </Badge>
              {(() => {
                if (action.badCriteria && action.badCriteria.length > 0) {
                  return (
                    <Badge bg="danger ms-2">
                      {action.badCriteria
                        ? ` Bad Criteria: ${action.badCriteria}`
                        : ""}
                    </Badge>
                  );
                }
              })()}
            </span>
          </Form.Group>
        )}
        {(() => {
          if (inputType === "NUMBER") {
            return (
              <Form.Group className="mb-3" controlId="formBasicNumber">
                <Form.Label>Value</Form.Label>
                <Form.Control
                  value={number}
                  type="number"
                  placeholder="Value"
                  onChange={e => {
                    if (
                      e.target.value.match(/^(-?\d+\.\d+)$|^(-?\d+)$/) ||
                      e.target.value === ""
                    ) {
                      setNumber(e.target.value);
                    }
                  }}
                />
                <Form.Text className="text-muted">
                  Write the value here
                </Form.Text>
              </Form.Group>
            );
          }
        })()}
        <Form.Group className="mb-3" controlId="formBasicComment">
          <Form.Label>
            Comment {value === "INCAPABLE_TO_MARK" ? "*" : ""}{" "}
          </Form.Label>
          <Form.Control
            value={comment}
            as="textarea"
            rows="3"
            placeholder="Comment"
            onChange={e => setComment(e.target.value)}
            isRequired={incapableToMark}
          />
          <Form.Text className="text-muted">
            Write an optional comment
          </Form.Text>
        </Form.Group>
        {(() => {
          if (action.userInfo) {
            const updated_at = action.updatedAt
              ? moment(action.updatedAt).format("llll")
              : null;
            const name =
              action.userInfo.firstname + " " + action.userInfo.lastname;
            const email = action.userInfo.email;
            return (
              <div>
                <Form.Text className="text-muted">
                  Edited by: {name} - {email}.
                  <br />
                  Last edited: {updated_at}
                </Form.Text>
              </div>
            );
          }
        })()}

        {action.updatedAt && (
          <Button onClick={() => setConfirmationModal(true)} variant="danger">
            {" "}
            Delete{" "}
          </Button>
        )}

        <Button
          className="float-end"
          disabled={disableSaveButton()}
          onClick={() => {
            let extraValues =
              inputType === "NUMBER"
                ? { inputType, value: parseFloat(number) }
                : null;
            onSubmit(actionId, value, comment, date, isCreate, extraValues);
          }}
          variant="primary"
          type="submit"
        >
          Save
        </Button>
      </Form>

      <ConfirmationModal
        titleColor={"red-color"}
        title={
          "Warning: You will not be able to recover this data once deleted"
        }
        description={`Are you sure you want to delete this?`}
        show={confirmationModal}
        hide={() => setConfirmationModal(false)}
        confirm={handleDeleteEvent}
      />
    </div>
  );
}

export default CreateEventForm;

CreateEventForm.propTypes = {
  actionId: PropTypes.number.isRequired,
  inputType: PropTypes.oneOf(["BOOL", "NUMBER"]).isRequired,
  planType: PropTypes.oneOf(["RED_GREEN", "RED_GREEN_YELLOW"]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  action: PropTypes.shape({
    goodCriteria: PropTypes.string,
    badCriteria: PropTypes.string,
    comment: PropTypes.string,
    name: PropTypes.string.isRequired,
    userInfo: PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired
    }),
    created: PropTypes.string
  }).isRequired,
  defaultComment: PropTypes.string,
  date: PropTypes.string,
  isCreate: PropTypes.bool.isRequired
};
