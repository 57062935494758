import React from "react";
import PropTypes from "prop-types";

import Button from "react-bootstrap/Button";

function CreateButton({ onClick, disabled, children }) {
  return (
    <Button onClick={onClick} disabled={disabled} variant="success">
      {children}
    </Button>
  );
}

export default CreateButton;

CreateButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node
};
