import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as types from "../actions/types";

import { Row, Col } from "react-bootstrap";

import VerifyAccountForm from "../components/VerifyAccountForm";

class VerifyAccountContainer extends React.Component {
  submitPassword = (token, password) => {
    this.props.verifyAccount(token, password);
  };

  render() {
    const token = this.props.history.location.search
      .split("token=")[1]
      .split("&")[0];
    if (this.props.verify.loading) {
      return <div>...loading</div>;
    }
    return (
      <Row>
        <Col xs={{ span: 12 }} sm={{ span: 6, offset: 3 }}>
          <div
            className="align-middle"
            style={{
              height: "calc(100vh - 200px)",
              display: "flex",
              alignItems: "center"
            }}
          >
            {(() => {
              if (!this.props.verify.verified) {
                return (
                  <div>
                    <VerifyAccountForm
                      token={token}
                      onSubmit={password =>
                        this.submitPassword(token, password)
                      }
                    />
                    {(() => {
                      if (this.props.verify.err) {
                        return (
                          <div style={{ color: "red" }}>
                            Your account was not verified - contact
                            support@blackbird.online
                          </div>
                        );
                      }
                    })()}
                  </div>
                );
              } else {
                return (
                  <div>
                    Your account is now verified. Login to see your account
                  </div>
                );
              }
            })()}
          </div>
        </Col>
      </Row>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  verifyAccount: (token, password) =>
    dispatch({ type: types.VERIFY_ACCOUNT_REQUEST, token, password })
});

const mapStateToProps = state => ({
  verify: state.verify
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyAccountContainer);

VerifyAccountContainer.propTypes = {
  verifyAccount: PropTypes.func.isRequired,
  verify: PropTypes.shape({
    err: PropTypes.string,
    error: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    verified: PropTypes.bool.isRequired
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};
