import { all } from "redux-saga/effects";
import loginFlow from "./loginSaga";
import scheduleFlow from "./schedulesSaga";
import { eventsFlow } from "./eventsSaga";
import companyUsersFlow from "./usersSaga";
import companyGroupsFlow from "./groupsSaga";
import companiesFlow from "./companiesSaga";
import holidaysFlow from "./holidaysSaga";

export default function* rootSaga() {
  yield all([
    loginFlow(),
    scheduleFlow(),
    eventsFlow(),
    companyUsersFlow(),
    companyGroupsFlow(),
    companiesFlow(),
    holidaysFlow()
  ]);
}
