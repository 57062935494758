import * as React from "react";
import {
  ExcelExport,
  ExcelExportColumn
} from "@progress/kendo-react-excel-export";

import { Button } from "react-bootstrap";

export const ExportExcelComponent = ({ data, columns, fileName }) => {
  const _exporter = React.createRef();

  const excelExport = () => {
    save(_exporter);
  };

  const getColor = value => {
    if (value === "TRUE") {
      return "#59B78C";
    } else if (value === "FALSE") {
      return "#D0021B";
    } else if (value === "YELLOW") {
      return "#F8D6A7";
    } else if (value === "UNMARKED") {
      return "#808080";
    } else {
      return "#F0F8FF";
    }
  };

  const save = component => {
    const options = component.current.workbookOptions();
    const rows = options.sheets[0].rows;
    rows.forEach(row => {
      if (row.type === "data") {
        row.cells.forEach(cell => {
          cell.background = getColor(cell.value);
          cell.value = cell.value === "UNMARKED" ? "" : cell.value;
        });
      }
    });
    component.current.save(options);
  };

  return (
    <div>
      <Button onClick={excelExport}>Export to Excel</Button>

      <ExcelExport data={data} fileName={fileName} ref={_exporter}>
        {columns.map(column => {
          return (
            <ExcelExportColumn
              field={column}
              title={column}
              cellOptions={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
                borderLeft: "1px solid black"
              }}
            />
          );
        })}
      </ExcelExport>
    </div>
  );
};
