import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT
} from "../actions/types";

const initialState = { loading: false, error: false, loggedIn: false };

export default function(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, loading: true, error: false };
    case LOGIN_SUCCESS:
      return { ...state, loading: false, error: false, loggedIn: true };
    case LOGIN_ERROR:
      return { ...state, loading: false, error: true, loggedIn: false };
    case LOGOUT:
      return { ...state, loading: false, error: false, loggedIn: false };
    default:
  }
  return state;
}
