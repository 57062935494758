import React from "react";

import { connect } from "react-redux";
import { Table, Button } from "react-bootstrap";

import * as types from "../actions/types";
import CustomModal from "../components/CustomModal";
import HolidayForm from "../components/HolidayForm";
import { getCompanyId } from "../selectors";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

class Holidays extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modifiedItem: null
    };
  }

  componentDidMount() {
    this.props.fetchHolidays(this.props.companyId);
  }

  componentDidUpdate() {
    if (this.props.showHolidaysModal === false && this.state.modifiedItem) {
      this.setState({ modifiedItem: null });
    }
  }

  handleSaveHoliday = holiday => {
    if (holiday.id) {
      this.props.updateHoliday(this.props.companyId, holiday);
    } else {
      this.props.createHoliday(this.props.companyId, holiday);
    }
    this.props.showHideHolidayModal();
    this.setState({ modifiedItem: null });
  };

  handleEditHoliday = holiday => {
    this.setState({ modifiedItem: holiday });
    this.props.showHideHolidayModal();
  };

  handleDeleteHoliday = holidayId => {
    this.props.deleteHoliday(this.props.companyId, holidayId);
    this.props.showHideHolidayModal();
  };

  render() {
    const { holidays } = this.props;
    return (
      <div>
        <Table>
          <thead>
            <tr>
              <td> Name </td>
              <td> Date </td>
              <td> Off day type </td>
              <td>Edit</td>
              {/* <td>Delete</td> */}
            </tr>
          </thead>
          <tbody>
            {holidays.map(holiday => {
              return (
                <tr>
                  <td> {holiday.name} </td>
                  <td> {holiday.offDate} </td>
                  <td> {holiday.offDayType} </td>
                  <td>
                    <Button
                      variant="light"
                      className="round-button-sm align-middle"
                      onClick={() => this.handleEditHoliday(holiday)}
                    >
                      <FontAwesomeIcon
                        className="primary-color"
                        icon={faEdit}
                      />
                    </Button>
                  </td>

                  {/* <td>
                      <Button
                        variant="light"
                        className="round-button-sm-danger align-middle"
                        onClick={() => this.handleEditHoliday(holiday)}
                      >
                        <FontAwesomeIcon className="red-color" icon={faTrashAlt} />
                      </Button>
                    </td> */}
                </tr>
              );
            })}
          </tbody>
        </Table>

        <CustomModal
          show={this.props.showHolidaysModal}
          onHide={this.props.showHideHolidayModal}
          title={
            this.state?.modifiedItem?.id ? "Update Holiday" : "Create Holiday"
          }
        >
          <HolidayForm
            modifiedItem={this.state.modifiedItem}
            handleSaveHoliday={this.handleSaveHoliday}
            deleteHoliday={this.handleDeleteHoliday}
          />
        </CustomModal>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  fetchHolidays: companyId =>
    dispatch({ type: types.FETCH_HOLIDAY_REQUEST, companyId }),

  createHoliday: (companyId, holiday) =>
    dispatch({ type: types.CREATE_HOLIDAY_REQUEST, companyId, holiday }),

  updateHoliday: (companyId, holiday) =>
    dispatch({ type: types.UPDATE_HOLIDAY_REQUEST, companyId, holiday }),

  deleteHoliday: (companyId, holidayId) =>
    dispatch({ type: types.DELETE_HOLIDAY_REQUEST, companyId, holidayId })
});

const mapStateToProps = state => ({
  companyId: getCompanyId(state),
  holidays: state?.holidays?.holidays || []
});

export default connect(mapStateToProps, mapDispatchToProps)(Holidays);
