import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import moment from "moment";

import * as types from "../actions/types";
import Button from "react-bootstrap/Button";
import {
  Row,
  Col,
  Card,
  Dropdown,
  InputGroup,
  FormControl,
  FormSelect,
  Badge
} from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faEllipsisV,
  faPlus,
  faCopy,
  faEdit,
  faInfoCircle,
  faCheckCircle,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";

import { getCompanyId } from "../selectors";

import CustomModal from "../components/CustomModal";
import CreatePlanForm from "../components/CreatePlanForm";
import ConfirmationModal from "../components/ConfirmationModal";
import FeatureAwareContainer from "./FeatureAwareContainer";
import CustomPagination from "../components/CustomPagination";
import StatusOverview from "../components/StatusOverview";
import { CustomTimeRange } from "../components/CustomTimeRange";

const DAYS = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY"
];
const now = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

class SchedulesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      showPlanModal: false,
      popoverOpen: false,
      target: null,
      modifiedItem: null,
      searchFilter: "",
      filterByGroup: null,
      showConfirmationModal: false,
      deletePlanItem: null,
      copyPlanItem: null,
      currentPage: 1,
      pageSize: 10,
      fromDate: moment().format("YYYY-MM-DD")
    };
  }

  componentDidMount() {
    this.props.fetchSchedules(
      this.props.companyId,
      this.state.currentPage,
      this.state.pageSize,
      this.state.fromDate
    );
    this.props.fetchGroups(this.props.companyId);
    this.props.fetchHolidays(this.props.companyId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.pageSize !== this.state.pageSize ||
      prevState.currentPage !== this.state.currentPage
    ) {
      this.props.fetchSchedules(
        this.props.companyId,
        this.state.currentPage,
        this.state.pageSize,
        this.state.fromDate
      );
    }
  }

  handleTimeRangeFilter = fromDate => {
    this.setState({ fromDate });
    this.props.fetchSchedules(
      this.props.companyId,
      this.state.currentPage,
      this.state.pageSize,
      fromDate
    );
  };

  handleCurrentPage = currentPage => {
    this.setState({ currentPage });
  };

  handlePageSize = pageSize => {
    this.setState({ pageSize, currentPage: 1 });
  };

  hidePlanModal = () => {
    this.setState({ showPlanModal: false, modifiedItem: null });
  };

  createOrUpdatePlan = async (name, description, selectedGroupIds) => {
    if (this.state.modifiedItem?.id) {
      await this.props.updateSchedule(
        this.props.companyId,
        this.state.modifiedItem.id,
        { name, description, selectedGroupIds },
        this.state.currentPage,
        this.state.pageSize
      );
      this.setState({ modifiedItem: null });
    } else {
      this.props.createSchedule(this.props.companyId, {
        name,
        description,
        selectedGroupIds
      });
    }
  };

  handleCreatePlan = () => {
    this.setState({ showPlanModal: true });
  };

  handleEdit = plan => () => {
    this.setState({
      modifiedItem: plan,
      showPlanModal: true
    });
  };

  handleDeletePlan = deletePlanItem => {
    this.setState({ showConfirmationModal: true, deletePlanItem });
  };

  hideConfirmationModal = () => {
    this.setState({
      showConfirmationModal: false,
      deletePlanItem: null,
      copyPlanItem: null
    });
  };

  confirmDeletePlan = () => {
    this.props.deletePlan(this.props.companyId, this.state.deletePlanItem.id);
    this.setState({ deletePlanItem: null, showConfirmationModal: false });
  };

  handleCopyPlan = copyPlanItem => {
    this.setState({ showConfirmationModal: true, copyPlanItem });
  };

  confirmCopyPlan = () => {
    this.props.copyPlan(this.props.companyId, this.state.copyPlanItem.id);
    this.setState({ copyPlanItem: null, showConfirmationModal: false });
  };

  handleSearch = event => {
    const value = event.target.value.trim();
    this.setState({ searchFilter: value });
    if (value.length >= 3) {
      this.setState({ currentPage: 1, pageSize: "all" });
    }
  };

  handleSearchByGroup = event => {
    const value = event.target.value.trim();
    if (value) {
      this.setState({
        filterByGroup: parseInt(value),
        currentPage: 1,
        pageSize: "all"
      });
    } else {
      this.setState({ filterByGroup: null });
    }
  };

  isCurrentDateOrNull = event_date => {
    const today = moment(now.format("YYYY-MM-DD"));
    return today.isSame(event_date, "day") || event_date === null;
  };

  getItemsForDuration = (events, to, days) => {
    const date = moment(to.format("YYYY-MM-DD"));
    const isHoliday = this.props.holidays.find(
      holiday => moment(date).format("YYYY-MM-DD") === holiday.offDate
    );
    let shouldRegister = days.includes(DAYS[date.isoWeekday() - 1]);
    if (isHoliday) {
      shouldRegister = false;
    }

    const foundEvent =
      events[0] && date.isSame(events[0].event_date, "day") ? events[0] : {};
    return {
      date: date.format("YYYY-MM-DD"),
      value: "NONE",
      shouldRegister,
      ...foundEvent
    };
  };

  render() {
    const schedules = this.props.scheduleList.filter(schedule => {
      const { searchFilter, filterByGroup } = this.state;
      let stringSearch = true;
      let groupSearch = true;
      if (searchFilter) {
        stringSearch =
          schedule.name.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1;
      }
      if (filterByGroup) {
        groupSearch = schedule.groups.includes(filterByGroup);
      }
      return stringSearch && groupSearch;
    });

    return (
      <div>
        <Card
          style={{
            boxShadow:
              "0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)"
          }}
        >
          <Card.Body>
            <Row>
              <Col>
                <h3 style={{ marginTop: "1rem" }}>Plans</h3>
              </Col>
              <Col>
                <FeatureAwareContainer requiredRole="COMPANY_ADMINISTRATOR">
                  <OverlayTrigger
                    overlay={
                      <Tooltip id={`tooltip-create-plan`}>Create Plan</Tooltip>
                    }
                  >
                    <Button
                      style={{}}
                      onClick={this.handleCreatePlan}
                      className="float-end round-button"
                    >
                      <FontAwesomeIcon className="align-middle" icon={faPlus} />
                    </Button>
                  </OverlayTrigger>
                </FeatureAwareContainer>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Row>
          <InputGroup className="mb-4 mt-4">
            <Col xs={{ span: 12 }} sm={{ span: 6 }}>
              <span className="d-none d-sm-inline">
                <Button className="round-button-search">
                  <FontAwesomeIcon
                    style={{ margin: "0 auto" }}
                    icon={faSearch}
                    color="white"
                  />
                </Button>
              </span>

              <FormControl
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                placeholder="Search plans by name"
                style={{
                  border: "none",
                  borderRadius: "none",
                  borderBottom: "1px solid #ddd",
                  lineHeight: 2,
                  marginBottom: "10px",
                  marginLeft: "5px"
                }}
                onChange={this.handleSearch}
                className="remove-box-shadow d-xs-flex sm-above"
              />
            </Col>

            <Col xs={{ span: 12 }} sm={{ span: 4 }}>
              <FormSelect
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                style={{
                  border: "none",
                  borderRadius: "none",
                  borderBottom: "1px solid #ddd",
                  lineHeight: 2,
                  marginLeft: "5px",
                  marginRight: "5px",
                  marginBottom: "10px"
                }}
                onChange={this.handleSearchByGroup}
                className="remove-box-shadow d-xs-flex"
              >
                <option value="">Search by group</option>
                {this.props.groups?.map(group => {
                  return <option value={group.id}> {group.name} </option>;
                })}
              </FormSelect>
            </Col>
            <Col xs={{ span: 12 }} sm={{ span: 2 }} className="text-end mt-1">
              <CustomTimeRange handleFilter={this.handleTimeRangeFilter} />
            </Col>
          </InputGroup>
        </Row>

        <Row style={{ marginBottom: "30px" }}>
          {schedules
            .map(s => s)
            .sort((a, b) => b.id - a.id)
            .map((s, index) => {
              const date1 = new Date(this.state.fromDate);
              const date2 = new Date();

              const timeDifference = date2.getTime() - date1.getTime();
              let numberOfDays = Math.floor(
                timeDifference / (1000 * 3600 * 24)
              );

              numberOfDays = numberOfDays < 1 ? 1 : numberOfDays;

              return (
                <Col
                  sm={{
                    span: 6,
                    offset:
                      index === schedules.length - 1 && index % 2 === 0 ? 3 : 0
                  }}
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    position: "relative"
                  }}
                  key={`${s.name}-${index}`}
                >
                  <Link
                    to={`/plans/${s.id}`}
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <Card
                      style={{
                        height: "120px",
                        border: "1px solid #f8f9fa",
                        borderRadius: "0px",
                        boxShadow:
                          "0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)"
                      }}
                    >
                      <Card.Body>
                        <Card.Title
                          className="text-capitalize"
                          style={{ display: "inline" }}
                        >
                          {s.name}
                        </Card.Title>

                        <div className="me-4 float-xl-end">
                          <StatusOverview
                            schedule={s}
                            from={moment(now)}
                            days={numberOfDays}
                            holidays={this.props.holidays}
                          />
                        </div>

                        <Card.Text
                          style={{
                            color: "rgba(0,0,0,.125)",
                            marginTop: "10px",
                            marginBottom: "10px",
                            color: "#757575",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                          }}
                          className="text-lowercase"
                        >
                          {s.description || `NO DESCRIPTION GIVEN...`}
                        </Card.Text>

                        <div></div>
                      </Card.Body>
                    </Card>
                  </Link>
                  <FeatureAwareContainer requiredRole="COMPANY_ADMINISTRATOR">
                    <div
                      style={{
                        width: "50px",
                        height: "50px",
                        position: "absolute",
                        top: "10px",
                        right: "20px"
                      }}
                    >
                      <Dropdown style={{ float: "right" }}>
                        <Dropdown.Toggle
                          id={`dropdown-autoclose-true-${s.id}`}
                          className="remove-toggle-arrow add-background-on-hover"
                          variant="default"
                          style={{
                            border: "none",
                            borderRadius: "50%",
                            width: "40px",
                            height: "40px"
                          }}
                        >
                          <FontAwesomeIcon
                            id={`target-plan-${s.id}`}
                            icon={faEllipsisV}
                            className="primary-color"
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            key={s.id}
                            eventKey={s.id.toString()}
                            href={`/plans/${s.id}`}
                          >
                            {" "}
                            <FontAwesomeIcon
                              style={{ marginRight: "5px" }}
                              icon={faInfoCircle}
                              className="primary-color"
                            />
                            <span> View </span>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={this.handleEdit(s)}>
                            {" "}
                            <FontAwesomeIcon
                              style={{ marginRight: "5px" }}
                              icon={faEdit}
                              className="primary-color"
                            />
                            <span> Edit </span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#"
                            onClick={() => this.handleCopyPlan(s)}
                          >
                            {" "}
                            <FontAwesomeIcon
                              style={{ marginRight: "5px" }}
                              icon={faCopy}
                              className="primary-color"
                            />
                            <span> Copy </span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#"
                            onClick={() => this.handleDeletePlan(s)}
                          >
                            <FontAwesomeIcon
                              style={{ marginRight: "5px" }}
                              icon={faTrashAlt}
                              className="primary-color"
                            />
                            <span> Delete </span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </FeatureAwareContainer>
                </Col>
              );
            })}
        </Row>

        <CustomModal
          show={this.state.showPlanModal}
          onHide={this.hidePlanModal}
          title={this.state.modifiedItem ? "Update Plan" : "Create Plan"}
        >
          <CreatePlanForm
            onSubmit={async (name, description, selectedGroupIds) => {
              await this.createOrUpdatePlan(
                name,
                description,
                selectedGroupIds
              );
              this.hidePlanModal();
              this.props.fetchSchedules(
                this.props.companyId,
                this.state.currentPage,
                this.state.pageSize,
                this.state.fromDate
              );
            }}
            modifiedItem={this.state.modifiedItem}
            groups={this.props.groups}
          />
        </CustomModal>

        {this.state.deletePlanItem && (
          <ConfirmationModal
            titleColor={"red-color"}
            title={"Delete Confirmation"}
            description={`Are you sure you want to delete ${this.state.deletePlanItem.name}?`}
            show={this.state.showConfirmationModal}
            hide={this.hideConfirmationModal}
            confirm={this.confirmDeletePlan}
          />
        )}
        {this.state.copyPlanItem && (
          <ConfirmationModal
            title={"Copy Plan"}
            description={`Are you sure you want to copy ${this.state.copyPlanItem.name} and all it's actions ?`}
            show={this.state.showConfirmationModal}
            hide={this.hideConfirmationModal}
            confirm={this.confirmCopyPlan}
            confirmButtonText={
              <span>
                <FontAwesomeIcon
                  style={{ marginRight: "2px" }}
                  icon={faCheckCircle}
                />{" "}
                {`Yes`}{" "}
              </span>
            }
            confirmButtonVariant="primary"
          />
        )}

        <CustomPagination
          handleCurrentPage={this.handleCurrentPage}
          handlePageSize={this.handlePageSize}
          pageSize={this.state.pageSize}
          currentPage={this.state.currentPage}
          totalItems={this.props.total_schedules}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const scheduleList =
    state.scheduleList && state.scheduleList.schedules
      ? state.scheduleList.schedules
      : [];
  const total_schedules =
    state.scheduleList && state.scheduleList.total_schedules;
  const groups = state.groups?.groups || [];
  const holidays = state.holidays?.holidays || [];

  return {
    companyId: getCompanyId(state),
    scheduleList,
    groups,
    total_schedules,
    holidays
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchSchedules: (companyId, currentPage, pageSize, fromDate) =>
      dispatch({
        type: types.FETCH_SCHEDULES_REQUEST,
        companyId,
        currentPage,
        pageSize,
        withOverview: true,
        fromDate
      }),
    createSchedule: (companyId, schedule) =>
      dispatch({ type: types.CREATE_SCHEDULE_REQUEST, companyId, schedule }),
    updateSchedule: (companyId, scheduleId, schedule, currentPage, pageSize) =>
      dispatch({
        type: types.UPDATE_SCHEDULE_REQUEST,
        companyId,
        scheduleId,
        schedule,
        currentPage,
        pageSize
      }),
    deletePlan: (companyId, scheduleId) =>
      dispatch({ type: types.DELETE_SCHEDULE_REQUEST, companyId, scheduleId }),

    fetchGroups: companyId =>
      dispatch({ type: types.FETCH_GROUPS_REQUEST, companyId }),

    addScheduleToGroup: (companyId, groupId, scheduleId) =>
      dispatch({
        type: types.ADD_SCHEDULE_TO_GROUP,
        companyId,
        groupId,
        scheduleId
      }),

    deleteScheduleFromGroup: (companyId, groupId, scheduleId) =>
      dispatch({
        type: types.DELETE_SCHEDULE_FROM_GROUP,
        companyId,
        groupId,
        scheduleId
      }),

    copyPlan: (companyId, scheduleId) =>
      dispatch({
        type: types.COPY_SCHEDULE_REQUEST,
        companyId,
        scheduleId
      }),

    fetchHolidays: companyId =>
      dispatch({ type: types.FETCH_HOLIDAY_REQUEST, companyId })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulesContainer);

SchedulesContainer.propTypes = {
  fetchSchedules: PropTypes.func.isRequired,
  createSchedule: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  scheduleList: PropTypes.arrayOf(
    PropTypes.shape({
      companyId: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  history: PropTypes.object.isRequired
};
