import React from "react";
import PropTypes from "prop-types";

import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

function BackNextButtons({ onBack, onNext, backDisabled, nextDisabled }) {
  return (
    <>
      <Button
        onClick={onBack}
        disabled={backDisabled}
        variant="light"
        className="round-button-sm align-middle"
      >
        <FontAwesomeIcon className="primary-color" icon={faArrowLeft} />
      </Button>
      <Button
        onClick={onNext}
        disabled={nextDisabled}
        variant="light"
        className="round-button-sm align-middle ms-4"
      >
        <FontAwesomeIcon className="primary-color" icon={faArrowRight} />
      </Button>
    </>
  );
}

export default BackNextButtons;

BackNextButtons.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  backDisabled: PropTypes.bool.isRequired,
  nextDisabled: PropTypes.bool.isRequired
};
