import { call, put, take, all } from "redux-saga/effects";

import {
  success,
  error as errorNotification
} from "react-notification-system-redux";

import {
  FETCH_USERS_REQUEST,
  FETCH_SPECIFIC_USER_REQUEST,
  FETCH_SPECIFIC_USER_SUCCESS,
  FETCH_SPECIFIC_USER_ERROR,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_ERROR,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  ADD_USER_TO_GROUP_REQUEST,
  ADD_USER_TO_GROUP_ERROR,
  DELETE_USER_FROM_GROUP_REQUEST,
  DELETE_USER_FROM_GROUP_SUCCESS,
  DELETE_USER_FROM_GROUP_ERROR,
  UPDATE_USER_REQUEST,
  UPDATE_USER_ERROR,
  DELETE_USER_REQUEST,
  DELETE_USER_ERROR,
  RESEND_ACTIVITATION_LINK_REQUEST,
  FETCH_GROUPS_REQUEST
} from "../actions/types";
import * as Api from "../services/";

function* fetchUsers(companyId) {
  try {
    const response = yield call(Api.fetchUsers, companyId);
    yield put({ type: FETCH_USERS_SUCCESS, companyId, users: response.users });
  } catch (err) {
    yield put({ type: FETCH_USERS_ERROR, companyId, err });
  }
}

function* createUser() {
  while (true) {
    const { user, companyId } = yield take(CREATE_USER_REQUEST);
    try {
      const response = yield call(Api.createUser, companyId, user);
      if (response?.name === "error") {
        yield put(
          errorNotification({
            title: "User creation failed",
            message: response.detail,
            autoDismiss: 4
          })
        );
      }

      yield put({ type: CREATE_USER_SUCCESS, user: response.user });
      yield put({ type: FETCH_USERS_REQUEST, companyId });
    } catch (err) {
      yield put({ type: CREATE_USER_ERROR, err });
      yield put(
        errorNotification({
          title: "User failed",
          message: `Failed to create user`,
          autoDismiss: 4
        })
      );
    }
  }
}

function* updateUser() {
  while (true) {
    const { user, companyId, userId } = yield take(UPDATE_USER_REQUEST);
    try {
      yield call(Api.updateUser, user, companyId, userId);
      yield put({ type: FETCH_USERS_REQUEST, companyId });
    } catch (err) {
      yield put({ type: UPDATE_USER_ERROR, err });
      yield put(
        errorNotification({
          title: "User failed",
          message: `Failed to update user`,
          autoDismiss: 4
        })
      );
    }
  }
}

function* deleteUser() {
  while (true) {
    const { companyId, userId } = yield take(DELETE_USER_REQUEST);
    try {
      yield call(Api.deleteUser, companyId, userId);
      yield put({ type: FETCH_USERS_REQUEST, companyId });
    } catch (err) {
      yield put({ type: DELETE_USER_ERROR, err });
      yield put(
        errorNotification({
          title: "Operation failed",
          message: `Failed to delete user`,
          autoDismiss: 4
        })
      );
    }
  }
}

function* resendActivationLink() {
  while (true) {
    try {
      const { companyId, userId } = yield take(
        RESEND_ACTIVITATION_LINK_REQUEST
      );
      const response = yield call(Api.resendActivationLink, companyId, userId);
      if (response.status === 200) {
        success({
          title: "Resend Activation Link",
          message: `Activation link has been sent successfully`,
          autoDismiss: 2
        });
      }
    } catch (error) {
      yield put(
        errorNotification({
          title: "Operation failed",
          message: `An error occured while sending activation link`,
          autoDismiss: 4
        })
      );
    }
  }
}

function* fetchUser() {
  while (true) {
    try {
      const { companyId, userId } = yield take(FETCH_SPECIFIC_USER_REQUEST);
      const user = yield call(Api.fetchUser, companyId, userId);
      yield put({ type: FETCH_SPECIFIC_USER_SUCCESS, userId, companyId, user });
    } catch (err) {
      yield put({ type: FETCH_SPECIFIC_USER_ERROR, err });
    }
  }
}

function* addUserToGroupFlow() {
  while (true) {
    const { companyId, userId, groupId } = yield take(
      ADD_USER_TO_GROUP_REQUEST
    );
    try {
      yield call(Api.addUserToGroup, companyId, userId, groupId);
      yield put({ type: ADD_USER_TO_GROUP_REQUEST });
      yield put({ type: FETCH_SPECIFIC_USER_REQUEST, companyId, userId });
      yield put({ type: FETCH_GROUPS_REQUEST, companyId });

      yield put(
        success({
          title: "Group",
          message: `User was added to group`,
          autoDismiss: 2
        })
      );
    } catch (err) {
      yield put({ type: ADD_USER_TO_GROUP_ERROR });
      yield put(
        errorNotification({
          title: "Group failed",
          message: `Failed to add user to group`,
          autoDismiss: 4
        })
      );
    }
  }
}

function* deleteUserFromGroupFlow() {
  while (true) {
    const { companyId, userId, groupId } = yield take(
      DELETE_USER_FROM_GROUP_REQUEST
    );
    try {
      yield call(Api.deleteUserFromGroup, companyId, userId, groupId);
      yield put({ type: DELETE_USER_FROM_GROUP_SUCCESS });
      yield put({ type: FETCH_SPECIFIC_USER_REQUEST, companyId, userId });
      yield put({ type: FETCH_GROUPS_REQUEST, companyId });
      yield put(
        success({
          title: "Group",
          message: `User was removed from group`,
          autoDismiss: 2
        })
      );
    } catch (err) {
      yield put({ type: DELETE_USER_FROM_GROUP_ERROR });
      yield put(
        errorNotification({
          title: "Group failed",
          message: `Failed to delete user from group`,
          autoDismiss: 4
        })
      );
    }
  }
}

function* fetchUsersFlow() {
  while (true) {
    const { companyId } = yield take(FETCH_USERS_REQUEST);
    yield call(fetchUsers, companyId);
  }
}

export default function* flow() {
  yield all([
    fetchUsersFlow(),
    createUser(),
    updateUser(),
    deleteUser(),
    fetchUser(),
    addUserToGroupFlow(),
    deleteUserFromGroupFlow(),
    resendActivationLink()
  ]);
}
