import React from "react";
import { Row, Col, Button, Form } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

const CustomPagination = ({
  currentPage,
  pageSize,
  handleCurrentPage,
  handlePageSize,
  totalItems
}) => {
  const changePageSize = e => {
    const { value } = e.target;
    handlePageSize(value);
  };

  const changeCurrentPage = value => {
    handleCurrentPage(value);
  };
  const cPage = currentPage;
  const from = pageSize === "all" ? 1 : currentPage * pageSize - pageSize + 1;
  const to = pageSize === "all" ? totalItems : from + parseInt(pageSize) - 1;
  return (
    <Row style={{ fontSize: "13px" }}>
      <Col className="text-end">
        <Form.Group style={{ display: "inline" }}>
          <Form.Label> Rows per page: </Form.Label>
          <Form.Select
            value={pageSize}
            onChange={changePageSize}
            className="ms-1"
            style={{ display: "inline", width: "auto", lineHeight: 1 }}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="all">All</option>
          </Form.Select>
        </Form.Group>

        <span className="m-3">
          {`${from} - ${to < totalItems ? to : totalItems} of ${totalItems}`}
        </span>

        <Button
          disabled={currentPage === 1}
          onClick={() => changeCurrentPage(cPage - 1)}
          variant="light"
          className="btn-light-sm"
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </Button>

        <span className="m-3 fw-bolder"> {currentPage} </span>

        <Button
          disabled={pageSize === "all" || currentPage * pageSize >= totalItems}
          onClick={() => changeCurrentPage(currentPage + 1)}
          variant="light"
          className="btn-light-sm"
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </Button>
      </Col>
    </Row>
  );
};

export default CustomPagination;
