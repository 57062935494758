import {
  FETCH_GROUPS_REQUEST,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_ERROR
} from "../actions/types";

const initialState = { loading: false, error: false, groups: [] };

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_GROUPS_REQUEST:
      return { ...state, loading: true, error: false };
    case FETCH_GROUPS_SUCCESS:
      return { ...state, loading: false, error: false, groups: action.groups };
    case FETCH_GROUPS_ERROR:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}
