import localStorage from "localStorage";
import moment from "moment";

export async function resetPasswordRequest(email) {
  try {
    const response = await fetch(`/api/reset`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email
      })
    });
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    } else {
      throw new Error("Bad Request");
    }
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
}

export async function verifyAccount(token, password) {
  try {
    const response = await fetch(`/api/verify`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token,
        password
      })
    });
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    } else {
      throw new Error("Bad Request");
    }
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
}

export async function login(email, password) {
  try {
    const response = await fetch(`/api/authenticate`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email,
        password
      })
    });

    if (!response.ok) {
      throw Error("Wrong email or password");
    }
    const responseJson = await response.json();
    return responseJson.token;
  } catch (err) {
    throw new Error(err);
  }
}

export async function createUser(companyId, user) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`/api/companies/${companyId}/users`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`
      },
      body: JSON.stringify(user)
    });

    if (!response.ok) {
      throw new Error("Failed to create user");
    }
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function updateUser(user, companyId, userId) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `/api/companies/${companyId}/users/${userId}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`
        },
        body: JSON.stringify(user)
      }
    );
    if (!response.ok) {
      throw new Error("Failed to update user");
    }
  } catch (error) {
    throw new Error(error);
  }
}

export async function deleteUser(companyId, userId) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `/api/companies/${companyId}/users/${userId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`
        }
      }
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    throw new Error(error);
  }
}

export async function resendActivationLink(companyId, userId) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `/api/companies/${companyId}/resend-activitation-link/${userId}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`
        }
      }
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    throw new Error(error);
  }
}

export async function createCompany(company) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`/api/companies`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`
      },
      body: JSON.stringify(company)
    });
    if (!response.ok) {
      throw new Error("Failed to create company");
    }
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function getCompanies() {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`/api/companies`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`
      }
    });
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function deleteGroup(companyId, groupId) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `/api/companies/${companyId}/groups/${groupId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`
        }
      }
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function deleteScheduleFromGroup(companyId, groupId, scheduleId) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `/api/companies/${companyId}/groups/${groupId}/schedules/${scheduleId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`
        }
      }
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function addScheduleToGroup(companyId, groupId, scheduleId) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `/api/companies/${companyId}/groups/${groupId}/schedules/${scheduleId}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`
        }
      }
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function deleteUserFromGroup(companyId, userId, groupId) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `/api/companies/${companyId}/users/${userId}/groups/${groupId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`
        }
      }
    );
    if (!response.ok) {
      throw new Error("Failed to remove from group");
    }
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function addUserToGroup(companyId, userId, groupId) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `/api/companies/${companyId}/users/${userId}/groups/${groupId}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`
        }
      }
    );
    if (!response.ok) {
      throw new Error("failed to add to group");
    }
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function createGroup(companyId, group) {
  const token = localStorage.getItem("token");

  try {
    const response = await fetch(`/api/companies/${companyId}/groups`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`
      },
      body: JSON.stringify(group)
    });
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function updateGroup(companyId, groupId, group) {
  const token = localStorage.getItem("token");
  try {
    await fetch(`/api/companies/${companyId}/groups/${groupId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`
      },
      body: JSON.stringify(group)
    });
  } catch (error) {
    throw new Error(error);
  }
}

export async function fetchUsers(companyId) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`/api/companies/${companyId}/users`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `JWT ${token}`
      }
    });
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function fetchUser(companyId, userId) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `/api/companies/${companyId}/users/${userId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `JWT ${token}`
        }
      }
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function fetchGroups(companyId) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`/api/companies/${companyId}/groups`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `JWT ${token}`
      }
    });
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export function setToken(token) {
  localStorage.setItem("token", token);
}

export async function getEvents(companyId, scheduleId, fromDate) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `/api/companies/${companyId}/schedules/${scheduleId}/events?from=${fromDate}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`
        }
      }
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function deleteAction(companyId, scheduleId, actionId) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `/api/companies/${companyId}/schedules/${scheduleId}/actions/${actionId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`
        }
      }
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function getEventsForAction(companyId, scheduleId, actionId) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `/api/companies/${companyId}/schedules/${scheduleId}/actions/${actionId}/events`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`
        }
      }
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function createEvent(companyId, scheduleId, actionId, event) {
  const token = localStorage.getItem("token");

  try {
    const response = await fetch(
      `/api/companies/${companyId}/schedules/${scheduleId}/actions/${actionId}/events`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`
        },
        body: JSON.stringify(event)
      }
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function updateEvent(
  companyId,
  scheduleId,
  actionId,
  eventId,
  event
) {
  const token = localStorage.getItem("token");

  try {
    const response = await fetch(
      `/api/companies/${companyId}/schedules/${scheduleId}/actions/${actionId}/events/${eventId}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`
        },
        body: JSON.stringify(event)
      }
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function deleteEvent(companyId, scheduleId, actionId, eventId) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `/api/companies/${companyId}/schedules/${scheduleId}/actions/${actionId}/events/${eventId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`
        },
        body: JSON.stringify({})
      }
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function getScheduleDetail(
  companyId,
  scheduleId,
  currentPage,
  pageSize
) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `/api/companies/${companyId}/schedules/${scheduleId}?page=${currentPage}&size=${pageSize}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`
        }
      }
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function getSchedules(
  companyId,
  currentPage,
  pageSize,
  withOverview,
  fromDate,
  toDate
) {
  const token = localStorage.getItem("token");
  try {
    let url = `/api/companies/${companyId}/schedules?page=${currentPage}&size=${pageSize}&overview=${withOverview}`;
    if (fromDate) {
      toDate = !toDate ? moment().format("YYYY-MM-DD") : toDate;
      url = `/api/companies/${companyId}/schedules?page=${currentPage}&size=${pageSize}&overview=${withOverview}&from=${fromDate}&to=${toDate}`;
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`
      }
    });
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function patchAction(companyId, scheduleId, actionId, action) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `/api/companies/${companyId}/schedules/${scheduleId}/actions/${actionId}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`
        },
        body: JSON.stringify(action)
      }
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function createAction(companyId, scheduleId, action) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `/api/companies/${companyId}/schedules/${scheduleId}/actions`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`
        },
        body: JSON.stringify(action)
      }
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function createSchedule(companyId, schedule) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`/api/companies/${companyId}/schedules`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`
      },
      body: JSON.stringify(schedule)
    });
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function updateSchedule(companyId, scheduleId, schedule) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `/api/companies/${companyId}/schedules/${scheduleId}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`
        },
        body: JSON.stringify(schedule)
      }
    );
    const responseJson = await response.json();
    return responseJson.token;
  } catch (err) {
    throw new Error(err);
  }
}

export async function deleteSchedule(companyId, scheduleId) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `/api/companies/${companyId}/schedules/${scheduleId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: `JWT ${token}`
        }
      }
    );
    const responseJson = await response.json();
    return responseJson.token;
  } catch (err) {
    throw new Error(err);
  }
}

export async function copySchedule(companyId, scheduleId) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `/api/companies/${companyId}/scheduleCopy/${scheduleId}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`
        }
      }
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function copyAction(companyId, actionId) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `/api/companies/${companyId}/actionCopy/${actionId}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`
        }
      }
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function getCurrentUser() {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`/api/users/current`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`
      }
    });
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function fetchHolidays(companyId) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`/api/companies/${companyId}/holidays`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `JWT ${token}`
      }
    });
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function createHoliday(companyId, holiday) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`/api/companies/${companyId}/holidays`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`
      },
      body: JSON.stringify(holiday)
    });
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}

export async function updateHoliday(companyId, holiday) {
  const token = localStorage.getItem("token");
  const holidayId = holiday.id;
  console.log(holidayId, "HERE IS HOLIDAY ID");
  try {
    await fetch(`/api/companies/${companyId}/holidays/${holidayId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`
      },
      body: JSON.stringify(holiday)
    });
  } catch (error) {
    throw new Error(error);
  }
}

export async function deleteHoliday(companyId, holidayId) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `/api/companies/${companyId}/holidays/${holidayId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`
        }
      }
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    throw new Error(err);
  }
}
