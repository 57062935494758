import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Form, Button, Card } from "react-bootstrap";
const LoginForm = ({ onSubmit }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(email, password);
  };

  return (
    <Card
      style={{
        margin: "0 auto",
        verticalAlign: "middle",
        width: "100%",
        padding: "20px",
        boxShadow:
          "0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)",
        backgroundColor: "#fff"
      }}
    >
      <Card.Title className="text-center mt-4">
        <h4> Sign in </h4>
      </Card.Title>

      <Card.Body>
        <Form className="p-4" onSubmit={handleSubmit}>
          <Form.Group className="mb-4" controlId="formGroupEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-5" controlId="formGroupPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group>
            <Button type="submit" variant="primary">
              SIGN IN
            </Button>

            <Link className="float-end" to="forgot">
              <Button className="btn-forgot-password" variant="light">
                FORGOT PASSWORD
              </Button>
            </Link>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default LoginForm;

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
