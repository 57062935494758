import React from "react";
import PropTypes from "prop-types";
import ListGroup from "react-bootstrap/ListGroup";

function ScheduleList({ schedules, onClickItem }) {
  return (
    <ListGroup>
      {schedules.map(s => (
        <ListGroup.Item key={s.id} onClick={() => onClickItem(s.id)}>
          {s.name}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}

export default ScheduleList;

ScheduleList.propTypes = {
  onClickItem: PropTypes.func.isRequired,
  schedules: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string
    }).isRequired
  ).isRequired
};
