import { call, put, take, all } from "redux-saga/effects";
import {
  success,
  error as errorNotification
} from "react-notification-system-redux";
import {
  FETCH_GROUPS_REQUEST,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_ERROR,
  CREATE_GROUP_REQUEST,
  UPDATE_GROUP_REQUEST,
  ADD_SCHEDULE_TO_GROUP,
  DELETE_SCHEDULE_FROM_GROUP,
  DELETE_GROUP_REQUEST
} from "../actions/types";
import * as Api from "../services/";

function* fetchGroups(companyId) {
  try {
    const response = yield call(Api.fetchGroups, companyId);
    yield put({
      type: FETCH_GROUPS_SUCCESS,
      companyId,
      groups: response.groups
    });
  } catch (err) {
    yield put({ type: FETCH_GROUPS_ERROR, companyId, err });
  }
}

function* fetchGroupsFlow() {
  while (true) {
    const { companyId } = yield take(FETCH_GROUPS_REQUEST);
    yield call(fetchGroups, companyId);
  }
}

function* createGroup() {
  while (true) {
    const { companyId, group } = yield take(CREATE_GROUP_REQUEST);
    try {
      yield call(Api.createGroup, companyId, group);
      yield put({ type: FETCH_GROUPS_REQUEST, companyId });
    } catch (err) {
      console.log(err);
    }
  }
}

function* updateGroup() {
  while (true) {
    const { companyId, groupId, group } = yield take(UPDATE_GROUP_REQUEST);
    try {
      yield call(Api.updateGroup, companyId, groupId, group);
      yield put({ type: FETCH_GROUPS_REQUEST, companyId });
    } catch (error) {
      console.log(error);
    }
  }
}

function* deleteGroup() {
  while (true) {
    const { companyId, groupId } = yield take(DELETE_GROUP_REQUEST);
    try {
      yield call(Api.deleteGroup, companyId, groupId);
      yield put({ type: FETCH_GROUPS_REQUEST, companyId });
    } catch (err) {
      console.log(err);
    }
  }
}

function* addScheduleToGroup() {
  while (true) {
    const { companyId, groupId, scheduleId } = yield take(
      ADD_SCHEDULE_TO_GROUP
    );
    try {
      yield call(Api.addScheduleToGroup, companyId, groupId, scheduleId);
      yield put({ type: FETCH_GROUPS_REQUEST, companyId });
      yield put(
        success({
          title: "Operation Successful",
          message: `Plan added to the group`,
          autoDismiss: 2
        })
      );
    } catch (error) {
      errorNotification({
        title: "Operation failed",
        message: `Failed to add plan to the group`,
        autoDismiss: 4
      });
    }
  }
}

function* deleteScheduleToGroup() {
  while (true) {
    const { companyId, groupId, scheduleId } = yield take(
      DELETE_SCHEDULE_FROM_GROUP
    );
    try {
      yield call(Api.deleteScheduleFromGroup, companyId, groupId, scheduleId);
      yield put({ type: FETCH_GROUPS_REQUEST, companyId });
      yield put(
        success({
          title: "Operation Successful",
          message: `Plan removed from the group`,
          autoDismiss: 2
        })
      );
    } catch (error) {
      errorNotification({
        title: "Operation failed",
        message: `Failed to remove plan from the group`,
        autoDismiss: 4
      });
    }
  }
}

export default function* flow() {
  yield all([
    fetchGroupsFlow(),
    createGroup(),
    updateGroup(),
    addScheduleToGroup(),
    deleteGroup(),
    deleteScheduleToGroup()
  ]);
}
