import React, { useState } from "react";

import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

import GroupDetails from "./GroupDetails";

const GroupsTable = ({
  groups,
  editGroup,
  deleteGroup,
  schedules,
  users,
  handleAddSchedules,
  handleAddUsers,
  handleRemoveUser,
  handleRemoveSchedule
}) => {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupDetailModal, setGroupDetailModal] = useState(false);

  const showDetails = group => {
    setSelectedGroup(group);
    setGroupDetailModal(true);
  };

  const handleClose = () => {
    setGroupDetailModal(false);
    setSelectedGroup(null);
  };

  const handleDeleteGroup = groupId => {
    deleteGroup(groupId);
    setGroupDetailModal(false);
    setSelectedGroup(null);
  };

  const handleEdit = (event, group) => {
    event.preventDefault();
    event.stopPropagation();
    editGroup({
      id: group.id,
      name: group.name,
      description: group.description
    });
  };

  return (
    <div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th className="align-middle p-3"> Name</th>
            <th className="align-middle p-3"> Users in groups </th>
            <th className="align-middle p-3"> Plans in groups </th>
            <th className="align-middle p-3"> Edit </th>
          </tr>
        </thead>
        <tbody>
          {groups
            .sort((a, b) => a.id - b.id)
            .map(g => {
              return (
                <tr
                  style={{ cursor: "pointer" }}
                  key={g.id}
                  onClick={() => showDetails(g)}
                >
                  <td className="align-middle p-3 text-capitalize">{g.name}</td>
                  <td className="align-middle p-3 text-capitalize">
                    {g.users.length}
                  </td>
                  <td className="align-middle p-3">{g.schedules.length}</td>
                  <td className="align-middle p-3">
                    <Button
                      variant="light"
                      className="round-button-sm"
                      onClick={e => {
                        handleEdit(e, g);
                      }}
                    >
                      <FontAwesomeIcon
                        className="primary-color"
                        icon={faEdit}
                      />
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>

      {selectedGroup && (
        <GroupDetails
          show={groupDetailModal}
          hide={handleClose}
          group={selectedGroup}
          deleteGroup={handleDeleteGroup}
          schedules={schedules}
          users={users}
          handleAddSchedules={handleAddSchedules}
          handleAddUsers={handleAddUsers}
          handleRemoveUser={handleRemoveUser}
          handleRemoveSchedule={handleRemoveSchedule}
        />
      )}
    </div>
  );
};

export default GroupsTable;
