import { call, put, take, all } from "redux-saga/effects";
import {
  success,
  error as errorNotification
} from "react-notification-system-redux";
import {
  FETCH_HOLIDAY_REQUEST,
  FETCH_HOLIDAY_SUCCESS,
  FETCH_HOLIDAY_ERROR,
  CREATE_HOLIDAY_REQUEST,
  UPDATE_HOLIDAY_REQUEST,
  DELETE_HOLIDAY_REQUEST
} from "../actions/types";
import * as Api from "../services/";

function* fetchHolidays(companyId) {
  try {
    const response = yield call(Api.fetchHolidays, companyId);
    yield put({
      type: FETCH_HOLIDAY_SUCCESS,
      companyId,
      holidays: response.holidays
    });
  } catch (err) {
    yield put({ type: FETCH_HOLIDAY_ERROR, companyId, err });
  }
}

function* fetchHolidaysFlow() {
  while (true) {
    const { companyId } = yield take(FETCH_HOLIDAY_REQUEST);
    yield call(fetchHolidays, companyId);
  }
}

function* createHoliday() {
  while (true) {
    const { companyId, holiday } = yield take(CREATE_HOLIDAY_REQUEST);
    try {
      yield call(Api.createHoliday, companyId, holiday);
      yield put({ type: FETCH_HOLIDAY_REQUEST, companyId });
    } catch (err) {
      console.log(err);
    }
  }
}

function* updateHoliday() {
  while (true) {
    const { companyId, holiday } = yield take(UPDATE_HOLIDAY_REQUEST);
    try {
      yield call(Api.updateHoliday, companyId, holiday);
      yield put({ type: FETCH_HOLIDAY_REQUEST, companyId });
    } catch (error) {
      console.log(error);
    }
  }
}

function* deleteHoliday() {
  while (true) {
    const { companyId, holidayId } = yield take(DELETE_HOLIDAY_REQUEST);
    try {
      yield call(Api.deleteHoliday, companyId, holidayId);
      yield put({ type: FETCH_HOLIDAY_REQUEST, companyId });
    } catch (err) {
      console.log(err);
    }
  }
}

export default function* flow() {
  yield all([
    fetchHolidaysFlow(),
    createHoliday(),
    updateHoliday(),
    deleteHoliday()
  ]);
}
