import React from "react";
import PropTypes, { checkPropTypes } from "prop-types";
import { Card, Alert } from "react-bootstrap";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import Form from "react-bootstrap/Form";
const height = 30;
const itemWidth = 5;
const itemSpace = 2;

const renderTooltip = (props, item, holidays) => {
  const isHoliday = holidays.find(holiday => item.date === holiday.offDate);

  return (
    <Popover {...props}>
      <Popover.Header className="text-center" as="h3">
        {item.date}
      </Popover.Header>
      {isHoliday && <Alert>{isHoliday.name} </Alert>}

      {item.userInfo && (
        <Popover.Body>
          {(() => {
            if (
              item.extraValues &&
              item.extraValues.inputType &&
              item.extraValues.inputType == "NUMBER"
            ) {
              return (
                <div style={{ fontWeight: "bold" }}>
                  {item.extraValues.value
                    ? `Value: ${item.extraValues.value}`
                    : ""}{" "}
                  <br />
                </div>
              );
            }
          })()}
          {(() => {
            if (item.userInfo) {
              return (
                <div>
                  <Form.Text className="text-muted">
                    Edited by: {item.userInfo.firstname}{" "}
                    {item.userInfo.lastname} - {item.userInfo.email}.
                  </Form.Text>
                </div>
              );
            }
          })()}
          {item.comment}
          {(() => {
            if (item.cropped) {
              return (
                <div style={{ fontWeight: "bold" }}>
                  Click to see full comment
                </div>
              );
            }
          })()}
        </Popover.Body>
      )}
    </Popover>
  );
};

export function getColor(value, shouldRegister) {
  if (!shouldRegister) {
    return "#F0F8FF";
  } else {
    if (value === "TRUE") {
      return "#59B78C";
    } else if (value === "FALSE") {
      return "#D0021B";
    } else if (value === "YELLOW") {
      return "#F8D6A7";
    } else {
      return "#808080";
    }
  }
}

function ShowStatus({
  userRole,
  items,
  title,
  onDayClick,
  colorFilter,
  holidays
}) {
  const itemsToRegister = items.filter(i => i.shouldRegister);
  const percentageCompleted = Math.round(
    (itemsToRegister.filter(i => i.value == "TRUE").length /
      (itemsToRegister.length > 0 ? itemsToRegister.length : 1)) *
      100
  );
  const isUserAuthorized = () => {
    return userRole === "OPERATOR" ||
      userRole === "COMPANY_ADMINISTRATOR" ||
      userRole === "ADMINISTRATOR"
      ? true
      : false;
  };

  const renderRect = (i, index, colorFilter) => {
    const isHoliday = holidays.find(holiday => i.date === holiday.offDate);

    return (
      <g
        style={{ mask: i.value === "INCAPABLE_TO_MARK" ? "url('#mask')" : "" }}
      >
        <rect
          onClick={() =>
            (colorFilter === i.value || !colorFilter) &&
            isUserAuthorized() &&
            i.shouldRegister
              ? onDayClick(i.date, i)
              : null
          }
          height={height}
          width={itemWidth - itemSpace}
          x={index * itemWidth}
          y="0"
          fill={
            colorFilter === (i.value || !colorFilter) || !isHoliday
              ? getColor(i.value, i.shouldRegister)
              : "#F0F8FF"
          }
        />
      </g>
    );
  };

  return (
    <>
      <h6 className="text-capitalize">
        {title}:{" "}
        {itemsToRegister.length == 1 &&
        itemsToRegister[0].extraValues &&
        itemsToRegister[0].extraValues.inputType == "NUMBER"
          ? `(${itemsToRegister[0].extraValues.value})`
          : `${percentageCompleted}%`}
      </h6>
      <div>
        <svg
          width="100%"
          preserveAspectRatio="none"
          height={height}
          viewBox={`0 0 ${items.length * itemWidth - itemSpace} ${height}`}
        >
          <defs>
            <pattern
              id="stripe"
              patternUnits="userSpaceOnUse"
              width="4"
              height="4"
            >
              <path
                style={{ stroke: "#808080", strokeWidth: "2px" }}
                d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
              />
            </pattern>
            <mask id="mask">
              <rect
                height="100%"
                width="100%"
                style={{ fill: "url(#stripe)" }}
              />
            </mask>
          </defs>
          <g>
            {items.map((i, index) => (
              <OverlayTrigger
                key={i.date}
                overlay={props =>
                  renderTooltip(
                    props,
                    {
                      ...i,
                      comment:
                        i.comment && i.comment.length > 100
                          ? i.comment.substring(0, 100) + "..."
                          : i.comment,
                      cropped: i.comment && i.comment.length > 100
                    },
                    holidays
                  )
                }
              >
                {renderRect(i, index, colorFilter)}
              </OverlayTrigger>
            ))}
          </g>
        </svg>
      </div>
    </>
  );
}

export default ShowStatus;

ShowStatus.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      comment: PropTypes.string
    }).isRequired
  ).isRequired,
  title: PropTypes.string.isRequired,
  onDayClick: PropTypes.func.isRequired,
  days: PropTypes.arrayOf(
    PropTypes.oneOf([
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
      "SUNDAY"
    ])
  )
};
