import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import ListGroup from "react-bootstrap/ListGroup";
import Table from "react-bootstrap/Table";
import * as types from "../actions/types";
import { getCompanyId } from "../selectors";
import ScheduleList from "../components/ScheduleList";

class UserDetailContainer extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {
    this.props.fetchGroups(this.props.companyId);
    this.props.fetchUser(this.props.companyId, this.props.userId);
  }

  render() {
    if (!this.props.user) {
      return <div>...loading</div>;
    }

    const userGroups = this.props.allGroups.filter(g => {
      return this.props.user.groups.findIndex(u => u.id === g.id) >= 0;
    });

    const userSchedules = userGroups.map(ug => ug.schedules).flat();
    const uniq = {};
    var uniqueSchedules = userSchedules.filter(
      obj => !uniq[obj.id] && (uniq[obj.id] = true)
    );

    return (
      <div>
        <h3>User</h3>
        <Table>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            <tr key={this.props.user.id}>
              <td>{this.props.user.firstname}</td>
              <td>{this.props.user.lastname}</td>
              <td>{this.props.user.email}</td>
              <td>{this.props.user.role}</td>
            </tr>
          </tbody>
        </Table>
        <h3>Groups</h3>
        <ListGroup>
          {this.props.allGroups.map(g => (
            <ListGroup.Item
              key={g.id}
              onClick={() => {
                if (this.props.user.groups.findIndex(u => u.id === g.id) >= 0) {
                  this.props.deleteUserFromGroup(
                    this.props.companyId,
                    this.props.userId,
                    g.id
                  );
                } else {
                  this.props.addUserToGroup(
                    this.props.companyId,
                    this.props.userId,
                    g.id
                  );
                }
              }}
              variant={
                this.props.user.groups.findIndex(u => u.id === g.id) >= 0
                  ? "success"
                  : "danger"
              }
            >
              {g.name}
            </ListGroup.Item>
          ))}
        </ListGroup>
        <br />
        <h3>Plans</h3>
        <ScheduleList schedules={uniqueSchedules} onClickItem={() => {}} />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  fetchGroups: companyId =>
    dispatch({ type: types.FETCH_GROUPS_REQUEST, companyId }),
  fetchUser: (companyId, userId) =>
    dispatch({ type: types.FETCH_SPECIFIC_USER_REQUEST, companyId, userId }),
  addUserToGroup: (companyId, userId, groupId) =>
    dispatch({
      type: types.ADD_USER_TO_GROUP_REQUEST,
      companyId,
      userId,
      groupId
    }),
  deleteUserFromGroup: (companyId, userId, groupId) =>
    dispatch({
      type: types.DELETE_USER_FROM_GROUP_REQUEST,
      companyId,
      userId,
      groupId
    })
});

const mapStateToProps = (state, ownProps) => {
  const user =
    state.users && state.users.usersWithDetail
      ? state.users.usersWithDetail.find(u => u.id === ownProps.userId)
      : null;
  const allGroups =
    state.groups && state.groups.groups ? state.groups.groups : [];
  return {
    user,
    companyId: getCompanyId(state),
    allGroups
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetailContainer);

UserDetailContainer.propTypes = {
  fetchUser: PropTypes.func.isRequired,
  fetchGroups: PropTypes.func.isRequired,
  addUserToGroup: PropTypes.func.isRequired,
  deleteUserFromGroup: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired
  }),
  userId: PropTypes.number.isRequired
};
