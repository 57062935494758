import React from "react";

import { Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faCopy } from "@fortawesome/free-solid-svg-icons";

import ShowStatus from "../components/ShowStatus";
import FeatureAwareContainer from "./FeatureAwareContainer";
import { getItemsForDuration } from "../components/StatusOverview";

const Actions = ({
  actions,
  from,
  days,
  colorFilter,
  userRole,
  handleEditAction,
  handleCopyAction,
  selectTimeSpan,
  holidays
}) => {
  return (
    actions
      // .map(a => a)
      // .sort((a, b) => b.id - a.id )
      .map((a, index) => (
        <div
          key={a.id}
          className="action-card"
          style={{
            boxShadow:
              "0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)",
            borderBottom:
              index === actions.length - 1 ? "1px solid #ddd" : "none"
          }}
        >
          <div style={{ flex: 1 }}>
            <ShowStatus
              colorFilter={colorFilter}
              userRole={userRole}
              key={a.id}
              items={getItemsForDuration(
                a.events,
                from,
                days,
                a.days,
                holidays
              )}
              title={a.name}
              onDayClick={(time, item) => selectTimeSpan(a.id, time, item)}
              holidays={holidays}
            />
          </div>
          {/* {(() => {
            if (this.state.edit) {
              return ( */}
          <FeatureAwareContainer requiredRole="COMPANY_ADMINISTRATOR">
            <span className="ms-md-5 ms-3 mt-3">
              <Button
                variant="light"
                className="round-button-sm align-middle"
                onClick={() => handleEditAction(a)}
              >
                <FontAwesomeIcon className="primary-color" icon={faEdit} />
              </Button>

              <Button
                variant="light"
                className="round-button-sm ms-4"
                onClick={() => handleCopyAction(a)}
              >
                <FontAwesomeIcon className="primary-color" icon={faCopy} />
              </Button>
            </span>
          </FeatureAwareContainer>
          {/*   );
             }
           })()} */}
        </div>
      ))
  );
};

export default Actions;
