import { combineReducers } from "redux";
import { reducer as notifications } from "react-notification-system-redux";
import userReducer from "./userReducer";
import loginReducer from "./loginReducer";
import scheduleListReducer from "./scheduleListReducer";
import scheduleDetailReducer from "./scheduleDetailReducer";
import eventsReducer from "./eventsReducer";
import usersReducer from "./usersReducer";
import groupsReducer from "./groupsReducer";
import verifyReducer from "./verifyAccountReducer";
import companiesReducer from "./companiesReducer";
import holidaysReducer from "./holidaysReducer";

export default combineReducers({
  user: userReducer,
  login: loginReducer,
  scheduleList: scheduleListReducer,
  scheduleDetails: scheduleDetailReducer,
  eventsReducer: eventsReducer,
  users: usersReducer,
  groups: groupsReducer,
  verify: verifyReducer,
  notifications,
  companies: companiesReducer,
  holidays: holidaysReducer
});
