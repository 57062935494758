import {
  FETCH_SCHEDULES_REQUEST,
  FETCH_SCHEDULES_SUCCESS,
  FETCH_SCHEDULES_ERROR,
  CREATE_SCHEDULE_REQUEST,
  CREATE_SCHEDULE_SUCCESS,
  CREATE_SCHEDULE_ERROR,
  COPY_SCHEDULE_REQUEST,
  COPY_SCHEDULE_SUCCESS,
  COPY_SCHEDULE_ERROR
} from "../actions/types";

const initialState = { schedules: [], loading: false, error: false };

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_SCHEDULES_REQUEST:
      return { ...state, loading: true, error: false };
    case FETCH_SCHEDULES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        schedules: action.schedules,
        total_schedules: action.total_schedules
      };
    case FETCH_SCHEDULES_ERROR:
      return { ...state, loading: false, error: true };
    case CREATE_SCHEDULE_REQUEST:
      return { ...state, loading: true, error: false };
    case CREATE_SCHEDULE_SUCCESS:
      return { ...state, loading: false, error: false }; //no updates to payload - since we expect all schedules to be fetched after
    case CREATE_SCHEDULE_ERROR:
      return { ...state, loading: false, error: true };
    case COPY_SCHEDULE_REQUEST:
      return { ...state, loading: true, error: false };
    case COPY_SCHEDULE_SUCCESS:
      return { ...state, loading: false, error: false };
    case COPY_SCHEDULE_ERROR:
      return { ...state, loading: false, error: true };
  }
  return state;
}
