import { call, put, take, all, takeLatest, delay } from "redux-saga/effects";
import * as Api from "../services/";

import {
  show,
  success,
  error,
  warning,
  info,
  hide,
  removeAll
} from "react-notification-system-redux";

import {
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_ERROR,
  CREATE_COMPANY_REQUEST
} from "../actions/types";

function* fetchCompanies() {
  try {
    const response = yield call(Api.getCompanies);
    yield put({ type: FETCH_COMPANIES_SUCCESS, companies: response.companies });
  } catch (err) {
    yield put({ type: FETCH_COMPANIES_ERROR });
  }
}

function* createCompanyFlow() {
  while (true) {
    try {
      const { company } = yield take(CREATE_COMPANY_REQUEST);
      const response = yield call(Api.createCompany, company);

      yield put(
        show({
          title: `Company created`,
          message: `Company ${company.name} created`,
          autoDismiss: 4
        })
      );
      yield put({ type: FETCH_COMPANIES_REQUEST });
    } catch (err) {
      yield put(
        error({
          title: "Failed to create company",
          message: `Failed to create company`,
          autoDismiss: 4
        })
      );
      yield console.log(err);
    }
  }
}

function* fetchCompaniesFlow() {
  try {
    const task = yield takeLatest(FETCH_COMPANIES_REQUEST, fetchCompanies);
  } catch (err) {
    yield console.log(err);
  }
}

export default function* companiesFlow() {
  yield all([fetchCompaniesFlow(), createCompanyFlow()]);
}
