import { call, put, take, all, takeLatest, delay } from "redux-saga/effects";
import * as Api from "../services/";

import {
  FETCH_EVENTS_REQUEST,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_ERROR,
  CREATE_EVENT_REQUEST,
  UPDATE_EVENT_REQUEST,
  DELETE_EVENT_REQUEST
} from "../actions/types";

function* fetchEvents({ companyId, scheduleId, fromDate, days }) {
  while (true) {
    try {
      const events = yield call(Api.getEvents, companyId, scheduleId, fromDate);
      yield put({ type: FETCH_EVENTS_SUCCESS, events, companyId, scheduleId });
    } catch (err) {
      yield put({ type: FETCH_EVENTS_ERROR });
    }
    if (days > 8) {
      yield delay(1000 * 60 * 5);
    } else {
      yield delay(1000 * 60);
    }
  }
}

function* fetchEventsFlow() {
  try {
    yield takeLatest(FETCH_EVENTS_REQUEST, fetchEvents);
    // while (true) {
    //   try {
    //     const { companyId, scheduleId } = yield takeLatest(FETCH_EVENTS_REQUEST);
    //     const events = yield call(Api.getEvents, companyId, scheduleId);
    //     yield put({
    //       type: FETCH_EVENTS_SUCCESS,
    //       events,
    //       companyId,
    //       scheduleId
    //     });
    //   } catch (err) {
    //     yield put({ type: FETCH_EVENTS_ERROR });
    //   }
    //   yield delay(10000);
    // }
  } catch (err) {
    yield console.log(err);
  }
}

function* deleteEventFlow() {
  while (true) {
    try {
      const {
        companyId,
        scheduleId,
        actionId,
        eventId,
        fromDate,
        days
      } = yield take(DELETE_EVENT_REQUEST);
      yield call(Api.deleteEvent, companyId, scheduleId, actionId, eventId);
      yield put({
        type: FETCH_EVENTS_REQUEST,
        companyId,
        scheduleId,
        fromDate,
        days
      });
    } catch (error) {}
  }
}

function* updateEventFlow() {
  while (true) {
    try {
      const {
        companyId,
        scheduleId,
        actionId,
        eventId,
        event,
        fromDate,
        days
      } = yield take(UPDATE_EVENT_REQUEST);
      yield call(
        Api.updateEvent,
        companyId,
        scheduleId,
        actionId,
        eventId,
        event
      );
      yield put({
        type: FETCH_EVENTS_REQUEST,
        companyId,
        scheduleId,
        fromDate,
        days
      });
    } catch (err) {
      console.log({ err });
    }
  }
}

function* createEventFlow() {
  while (true) {
    try {
      const {
        companyId,
        scheduleId,
        actionId,
        event,
        fromDate,
        days
      } = yield take(CREATE_EVENT_REQUEST);
      yield call(Api.createEvent, companyId, scheduleId, actionId, event);
      yield put({
        type: FETCH_EVENTS_REQUEST,
        companyId,
        scheduleId,
        fromDate,
        days
      });
    } catch (err) {
      console.log({ err });
    }
  }
}

export function* eventsFlow() {
  yield all([
    fetchEventsFlow(),
    createEventFlow(),
    updateEventFlow(),
    deleteEventFlow()
  ]);
}
