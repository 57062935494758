import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import * as types from "../actions/types";
import CompanyList from "../components/company/CompanyList";
import { getCompanyId } from "../selectors";
import CreateForm from "../components/CreateForm";
import CustomModal from "../components/CustomModal";
import CreateButton from "../components/CreateButton";

class CompaniesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showCreateCompanyModal: false };
  }

  componentDidMount() {
    this.props.fetchCompanies();
  }

  companyClicked(id) {
    this.props.setActiveCompanyId(id);
  }

  createCompany = ({ name }) => {
    const company = {
      name
    };
    this.props.createCompany(company);
    this.setState({ showCreateCompanyModal: false });
  };

  render() {
    return (
      <div>
        <h3>Companies</h3>
        <div style={{ paddingBottom: 3 }} align="right">
          <CreateButton
            onClick={() => this.setState({ showCreateCompanyModal: true })}
          >
            Create Company
          </CreateButton>
        </div>
        <CustomModal
          show={this.state.showCreateCompanyModal}
          onHide={() => this.setState({ showCreateCompanyModal: false })}
          title={"Create company"}
        >
          <CreateForm
            fields={[
              {
                id: "name",
                name: "Name",
                description: "Name of Company",
                type: "TEXT"
              }
            ]}
            onSubmit={this.createCompany}
          />
        </CustomModal>
        <CompanyList
          companies={this.props.company.companies}
          activeId={this.props.companyId}
          onClickItem={id => this.companyClicked(id)}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  fetchCompanies: () => dispatch({ type: types.FETCH_COMPANIES_REQUEST }),
  setActiveCompanyId: activeCompanyId =>
    dispatch({ type: types.ADMIN_SET_ACTIVE_COMPANY_ID, activeCompanyId }),
  createCompany: company =>
    dispatch({ type: types.CREATE_COMPANY_REQUEST, company })
});

const mapStateToProps = state => ({
  company: state.companies,
  companyId: getCompanyId(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesContainer);

CompaniesContainer.propTypes = {
  createCompany: PropTypes.func.isRequired,
  fetchCompanies: PropTypes.func.isRequired,
  setActiveCompanyId: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
};
