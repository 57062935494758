import React from "react";
import { Spinner } from "react-bootstrap";

const Loader = () => {
  return (
    <div style={{ height: "300px", width: "100%", textAlign: "center" }}>
      <Spinner className="custom-spinner" animation="border" />
    </div>
  );
};

export default Loader;
