import {
  FETCH_HOLIDAY_REQUEST,
  FETCH_HOLIDAY_SUCCESS,
  FETCH_HOLIDAY_ERROR,
  CREATE_HOLIDAY_REQUEST,
  CREATE_HOLIDAY_SUCCESS,
  CREATE_HOLIDAY_ERROR,
  UPDATE_HOLIDAY_REQUEST,
  UPDATE_HOLIDAY_SUCCESS,
  UPDATE_HOLIDAY_ERROR
} from "../actions/types";

const initialState = { holidays: [], loading: false, error: false };

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_HOLIDAY_REQUEST:
      return { ...state, loading: true, error: false };
    case FETCH_HOLIDAY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        holidays: action.holidays
      };
    case FETCH_HOLIDAY_ERROR:
      return { ...state, loading: false, error: true };
    case CREATE_HOLIDAY_REQUEST:
      return { ...state, loading: true, error: false };
    case CREATE_HOLIDAY_SUCCESS:
      return { ...state, loading: false, error: false }; //no updates to payload - since we expect all holidays to be fetched after
    case CREATE_HOLIDAY_ERROR:
      return { ...state, loading: false, error: true };
    case UPDATE_HOLIDAY_REQUEST:
      return { ...state, loading: true, error: false };
    case UPDATE_HOLIDAY_SUCCESS:
      return { ...state, loading: false, error: false }; //no updates to payload - since we expect all holidays to be fetched after
    case UPDATE_HOLIDAY_ERROR:
      return { ...state, loading: false, error: true };
  }
  return state;
}
