import React, { useState } from "react";
import PropTypes from "prop-types";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Multiselect } from "multiselect-react-dropdown";

function CreatePlanForm({ onSubmit, modifiedItem, groups }) {
  const selectedGroupOptions =
    modifiedItem &&
    modifiedItem.groups &&
    groups?.filter(item => modifiedItem.groups.includes(item.id));

  const [name, setName] = useState(modifiedItem?.name || "");
  const [description, setDescription] = useState(
    modifiedItem?.description || ""
  );
  const [selectedGroups, setSelectedGroups] = useState(
    selectedGroupOptions || []
  );
  const onSelect = items => {
    setSelectedGroups(items);
  };

  const onRemove = items => {
    setSelectedGroups(items);
  };

  return (
    <div>
      <Form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Form.Group className="mb-3" controlId="formBasicName">
          <Form.Label>Name*</Form.Label>
          <Form.Control
            value={name}
            type="text"
            placeholder="Enter Name of plan"
            onChange={e => setName(e.target.value)}
          />
          <Form.Text className="text-muted">
            Enter the name of the plan you want to create
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            value={description}
            type="text"
            placeholder="Description"
            onChange={e => setDescription(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Add Groups</Form.Label>
          <Multiselect
            options={groups} // Options to display in the dropdown
            selectedValues={selectedGroups} // Preselected value to persist in dropdown
            onSelect={onSelect} // Function will trigger on select event
            onRemove={onRemove} // Function will trigger on remove event
            displayValue="name" // Property name to display in the dropdown options
          />
        </Form.Group>

        <br />
        <Button
          className="float-end"
          disabled={!(name.length > 0)}
          onClick={() => {
            if (name.length > 0) {
              let selectedGroupIds = [];
              if (selectedGroups.length) {
                selectedGroups.map(item => selectedGroupIds.push(item.id));
              }

              onSubmit(name, description, selectedGroupIds);
            }
          }}
          variant="primary"
          type="submit"
        >
          <FontAwesomeIcon icon={faCheck} />{" "}
          <span style={{ marginLeft: "2px" }}>{`Save`}</span>
        </Button>
      </Form>
    </div>
  );
}

export default CreatePlanForm;

CreatePlanForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
