import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { Row, Col } from "react-bootstrap";

import * as types from "../actions/types";
import LoginForm from "../components/LoginForm";

class LoginContainer extends React.Component {
  handleLogin = async (email, password) => {
    this.props.login(email, password);
    this.props.history.push("/");
  };

  render() {
    if (this.props.user.currentUser) {
      this.props.history.push("/");
    }
    return (
      <Row>
        <Col
          xs={{ span: 12 }}
          md={{ span: 6, offset: 3 }}
          xxl={{ span: 4, offset: 4 }}
        >
          <div
            className="align-middle"
            style={{
              height: "calc(100vh - 200px)",
              display: "flex",
              alignItems: "center"
            }}
          >
            <LoginForm
              onSubmit={(email, password) => this.handleLogin(email, password)}
            />
          </div>
        </Col>
      </Row>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  login: (email, password) =>
    dispatch({ type: types.LOGIN_REQUEST, email, password })
});

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);

LoginContainer.propTypes = {
  login: PropTypes.func.isRequired
};
