import { call, put, take, all } from "redux-saga/effects";

import { error as errorNotification } from "react-notification-system-redux";

import {
  FETCH_SCHEDULES_REQUEST,
  FETCH_SCHEDULES_SUCCESS,
  FETCH_SCHEDULES_ERROR,
  FETCH_SCHEDULE_DETAIL_REQUEST,
  FETCH_SCHEDULE_DETAIL_SUCCESS,
  FETCH_SCHEDULE_DETAIL_ERROR,
  CREATE_SCHEDULE_REQUEST,
  CREATE_SCHEDULE_SUCCESS,
  CREATE_SCHEDULE_ERROR,
  CREATE_ACTION_REQUEST,
  CREATE_ACTION_SUCCESS,
  CREATE_ACTION_ERROR,
  UPDATE_ACTION_REQUEST,
  DELETE_ACTION_REQUEST,
  DELETE_SCHEDULE_REQUEST,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_ERROR,
  UPDATE_SCHEDULE_REQUEST,
  COPY_SCHEDULE_REQUEST,
  COPY_SCHEDULE_SUCCESS,
  COPY_SCHEDULE_ERROR,
  COPY_ACTION_REQUEST,
  COPY_ACTION_SUCCESS
} from "../actions/types";
import * as Api from "../services/";

function* updateSchedule() {
  while (true) {
    try {
      const {
        companyId,
        scheduleId,
        schedule,
        currentPage,
        pageSize
      } = yield take(UPDATE_SCHEDULE_REQUEST);
      yield call(Api.updateSchedule, companyId, scheduleId, schedule);
      yield put({
        type: FETCH_SCHEDULE_DETAIL_REQUEST,
        companyId,
        scheduleId,
        currentPage,
        pageSize
      });
    } catch (err) {
      console.log(err);
    }
  }
}

function* updateAction() {
  while (true) {
    try {
      const {
        companyId,
        scheduleId,
        actionId,
        action,
        currentPage,
        pageSize
      } = yield take(UPDATE_ACTION_REQUEST);
      yield call(Api.patchAction, companyId, scheduleId, actionId, action);
      yield put({
        type: FETCH_SCHEDULE_DETAIL_REQUEST,
        companyId,
        scheduleId,
        currentPage,
        pageSize
      });
    } catch (err) {
      console.log(err);
    }
  }
}

function* fetchSchedules(
  companyId,
  currentPage,
  pageSize,
  withOverview,
  fromDate,
  toDate
) {
  try {
    const response = yield call(
      Api.getSchedules,
      companyId,
      currentPage,
      pageSize,
      withOverview,
      fromDate,
      toDate
    );
    yield put({
      type: FETCH_SCHEDULES_SUCCESS,
      schedules: response.schedules,
      total_schedules: response.total_schedules,
      companyId
    });
    return response.schedules;
  } catch (err) {
    yield put({ type: FETCH_SCHEDULES_ERROR });
  }
}

function* fetchScheduleDetail(companyId, scheduleId, currentPage, pageSize) {
  try {
    const scheduleDetail = yield call(
      Api.getScheduleDetail,
      companyId,
      scheduleId,
      currentPage,
      pageSize
    );
    yield put({
      type: FETCH_SCHEDULE_DETAIL_SUCCESS,
      schedule: scheduleDetail,
      companyId
    });
  } catch (err) {
    yield put({ type: FETCH_SCHEDULE_DETAIL_ERROR, companyId, scheduleId });
  }
}

function* scheduleDetailFlow() {
  while (true) {
    const {
      companyId,
      scheduleId,
      currentPage = 1,
      pageSize = 10
    } = yield take(FETCH_SCHEDULE_DETAIL_REQUEST);
    const scheduleDetail = yield call(
      fetchScheduleDetail,
      companyId,
      scheduleId,
      currentPage,
      pageSize
    );
  }
}

function* schedulesFlow() {
  while (true) {
    const {
      companyId,
      currentPage = 1,
      pageSize = "all",
      withOverview = false,
      fromDate = null,
      toDate = null
    } = yield take(FETCH_SCHEDULES_REQUEST);
    yield call(
      fetchSchedules,
      companyId,
      currentPage,
      pageSize,
      withOverview,
      fromDate,
      toDate
    );
  }
}

function* createSchedule(companyId, schedule) {
  try {
    const createdSchedule = yield call(Api.createSchedule, companyId, schedule);
    if (
      createdSchedule &&
      createdSchedule.schedule &&
      schedule.selectedGroupIds &&
      schedule.selectedGroupIds.length
    ) {
      for (const groupId of schedule.selectedGroupIds) {
        yield call(
          Api.addScheduleToGroup,
          companyId,
          groupId,
          createdSchedule.schedule.id
        );
      }
    }
    yield put({
      type: CREATE_SCHEDULE_SUCCESS,
      schedule: createdSchedule,
      companyId
    });
    return createdSchedule;
  } catch (error) {
    yield put({ type: CREATE_SCHEDULE_ERROR, error });
    yield put(
      errorNotification({
        title: "Schedule failed",
        message: `Failed to create schedule`,
        autoDismiss: 4
      })
    );
  }
}

function* createScheduleFlow() {
  while (true) {
    const { companyId, schedule } = yield take(CREATE_SCHEDULE_REQUEST);
    yield call(createSchedule, companyId, schedule);
    yield put({ type: FETCH_SCHEDULES_REQUEST, companyId });
  }
}

function* createAction(companyId, scheduleId, actionToCreate) {
  try {
    const createdAction = yield call(
      Api.createAction,
      companyId,
      scheduleId,
      actionToCreate
    );
    yield put({
      type: CREATE_ACTION_SUCCESS,
      companyId,
      scheduleId,
      action: createdAction
    });
    return createdAction;
  } catch (err) {
    yield put({ type: CREATE_ACTION_ERROR, companyId, scheduleId, err });
    yield put(
      errorNotification({
        title: "Action failed",
        message: `Failed to create action`,
        autoDismiss: 4
      })
    );
  }
}

function* createActionFlow() {
  while (true) {
    const { companyId, scheduleId, action } = yield take(CREATE_ACTION_REQUEST);
    yield call(createAction, companyId, scheduleId, action);
    yield put({ type: FETCH_SCHEDULE_DETAIL_REQUEST, companyId, scheduleId });
  }
}

function* deleteScheduleFlow() {
  while (true) {
    const { companyId, scheduleId, currentPage, pageSize } = yield take(
      DELETE_SCHEDULE_REQUEST
    );
    try {
      yield call(Api.deleteSchedule, companyId, scheduleId);
      yield put({ type: DELETE_SCHEDULE_SUCCESS });
      yield put({
        type: FETCH_SCHEDULES_REQUEST,
        companyId,
        currentPage,
        pageSize
      });
    } catch (err) {
      yield put({ type: DELETE_SCHEDULE_ERROR });
      yield put(
        errorNotification({
          title: "Schedule failed",
          message: `Failed to delete schedule`,
          autoDismiss: 4
        })
      );
    }
  }
}

function* deleteActionFlow() {
  while (true) {
    const {
      companyId,
      scheduleId,
      actionId,
      currentPage,
      pageSize
    } = yield take(DELETE_ACTION_REQUEST);
    try {
      yield call(Api.deleteAction, companyId, scheduleId, actionId);
      yield put({
        type: FETCH_SCHEDULE_DETAIL_REQUEST,
        companyId,
        scheduleId,
        currentPage,
        pageSize
      });
    } catch (err) {
      console.log(err);
      yield put(
        errorNotification({
          title: "Action failed",
          message: `Failed to delete action`,
          autoDismiss: 4
        })
      );
    }
  }
}

function* copySchedule(companyId, scheduleId) {
  try {
    const copiedSchedule = yield call(Api.copySchedule, companyId, scheduleId);
    yield put({
      type: COPY_SCHEDULE_SUCCESS,
      schedule: copiedSchedule,
      companyId
    });
    return copiedSchedule;
  } catch (error) {
    yield put({ type: COPY_SCHEDULE_ERROR, error });
    yield put(
      errorNotification({
        title: "Copying failed",
        message: `Failed to copy a schedule`,
        autoDismiss: 4
      })
    );
  }
}

function* copyScheduleFlow() {
  while (true) {
    const { companyId, scheduleId } = yield take(COPY_SCHEDULE_REQUEST);
    const copiedSchedule = yield call(copySchedule, companyId, scheduleId);
    yield put({ type: FETCH_SCHEDULES_REQUEST, companyId });
  }
}

function* copyAction(companyId, actionId) {
  try {
    const copiedAction = yield call(Api.copyAction, companyId, actionId);
    yield put({
      type: COPY_ACTION_SUCCESS,
      action: copiedAction,
      companyId
    });
    return copiedAction;
  } catch (error) {
    yield put(
      errorNotification({
        title: "Copying failed",
        message: "Failed to copy an action",
        autoMismiss: 4
      })
    );
  }
}

function* copyActionFlow() {
  while (true) {
    const { companyId, scheduleId, actionId } = yield take(COPY_ACTION_REQUEST);
    yield call(copyAction, companyId, actionId);
    yield put({ type: FETCH_SCHEDULE_DETAIL_REQUEST, companyId, scheduleId });
  }
}

export default function* flow() {
  yield all([
    schedulesFlow(),
    scheduleDetailFlow(),
    createScheduleFlow(),
    createActionFlow(),
    deleteScheduleFlow(),
    deleteActionFlow(),
    updateSchedule(),
    updateAction(),

    copyScheduleFlow(),
    copyActionFlow()
  ]);
}
