import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import * as types from "../actions/types";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

import { getCompanyId } from "../selectors";

import FeatureAwareContainer from "./FeatureAwareContainer";
import CustomModal from "../components/CustomModal";
import CreatePlanForm from "../components/CreatePlanForm";
import CreateButton from "../components/CreateButton";

class SchedulesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showCreateSchedulesModal: false };
  }

  componentDidMount() {
    this.props.fetchSchedules(this.props.companyId);
  }

  hideShowCreateModal = () => {
    this.setState({ showCreateSchedulesModal: false });
  };

  createSchedule = (name, description) => {
    this.props.createSchedule(this.props.companyId, { name, description });
  };

  render() {
    const schedules = this.props.scheduleList;
    return (
      <div>
        <h1>Plans</h1>
        <CustomModal
          show={this.state.showCreateSchedulesModal}
          onHide={this.hideShowCreateModal}
          title={"Create Plan"}
        >
          <CreatePlanForm
            onSubmit={(name, description) => {
              this.hideShowCreateModal();
              this.createSchedule(name, description);
            }}
          />
        </CustomModal>
        <FeatureAwareContainer requiredRole="COMPANY_ADMINISTRATOR">
          <div style={{ paddingBottom: 3 }} align="right">
            <CreateButton
              onClick={() => this.setState({ showCreateSchedulesModal: true })}
            >
              Create Plan
            </CreateButton>
          </div>
        </FeatureAwareContainer>
        <ListGroup>
          {schedules
            .map(s => s)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(s => (
              <ListGroup.Item
                key={s.id}
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "space-between"
                }}
              >
                {s.name}
                <ButtonToolbar>
                  <Button
                    style={{ margin: 5 }}
                    variant="info"
                    onClick={() =>
                      this.props.history.push(`/plans/${s.id}`, {
                        days: 1,
                        edit: true
                      })
                    }
                  >
                    Edit
                  </Button>
                  <Button
                    style={{ margin: 5 }}
                    variant="danger"
                    onClick={() =>
                      this.props.deletePlan(this.props.companyId, s.id)
                    }
                  >
                    Delete
                  </Button>
                </ButtonToolbar>
              </ListGroup.Item>
            ))}
        </ListGroup>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const scheduleList =
    state.scheduleList && state.scheduleList.schedules
      ? state.scheduleList.schedules
      : [];
  return {
    companyId: getCompanyId(state),
    scheduleList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchSchedules: companyId => {
      dispatch({ type: types.FETCH_SCHEDULES_REQUEST, companyId });
    },

    createSchedule: (companyId, schedule) =>
      dispatch({ type: types.CREATE_SCHEDULE_REQUEST, companyId, schedule }),
    deletePlan: (companyId, scheduleId) =>
      dispatch({ type: types.DELETE_SCHEDULE_REQUEST, companyId, scheduleId })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulesContainer);

SchedulesContainer.propTypes = {
  fetchSchedules: PropTypes.func.isRequired,
  createSchedule: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  scheduleList: PropTypes.arrayOf(
    PropTypes.shape({
      companyId: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  history: PropTypes.object.isRequired
};
