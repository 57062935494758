import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ConfirmationModal = ({
  title,
  description,
  show,
  hide,
  confirm,
  confirmButtonVariant,
  confirmButtonText,
  titleColor
}) => {
  return (
    <Modal show={show} onHide={hide}>
      <Modal.Header closeButton>
        <Modal.Title className={titleColor}>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{description}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="light" onClick={hide}>
          Cancel
        </Button>
        <Button variant={confirmButtonVariant || "danger"} onClick={confirm}>
          {confirmButtonText || "Delete"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
