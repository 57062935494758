import React from "react";
import PropTypes from "prop-types";

import {
  Row,
  Col,
  Card,
  InputGroup,
  FormControl,
  FormSelect,
  Button,
  Tooltip,
  OverlayTrigger
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPlus,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";

import { connect } from "react-redux";
import * as types from "../actions/types";
import { getCompanyId } from "../selectors";

import UserList from "../components/UserList";
import CustomModal from "../components/CustomModal";
import CreateUserForm from "../components/CreateUserForm";

import FeatureAwareContainer from "./FeatureAwareContainer";
import ConfirmationModal from "../components/ConfirmationModal";

class UsersContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modifyUserModal: false,
      modifiedItem: null,
      showConfirmationModal: false,
      deleteUserItem: null,
      selectedUser: null,
      searchFilter: "",
      filterByGroup: null
    };
  }

  componentDidMount() {
    this.props.fetchUsers(this.props.companyId);
    this.props.fetchGroups(this.props.companyId);
  }

  createOrUpdateUser = user => {
    if (this.state.modifiedItem?.id) {
      this.props.updateUser(
        this.props.companyId,
        this.state.modifiedItem.id,
        user
      );
      this.setState({ modifyUserModal: false, modifiedItem: null });
    } else {
      this.props.createUser(this.props.companyId, user);
      this.setState({ modifyUserModal: false });
    }
  };

  handleEdit = user => {
    this.setState({ modifyUserModal: true, modifiedItem: user });
  };

  handleDelete = user => {
    this.setState({ showConfirmationModal: true, deleteUserItem: user });
  };

  resendActivationLink = user => {
    this.setState({
      showConfirmationModal: true,
      selectedUser: user
    });
  };
  confirmResendActivationLink = () => {
    this.props.resendActivationLink(
      this.props.companyId,
      this.state.selectedUser.id
    );
    this.hideConfirmationModal();
  };

  hideConfirmationModal = () => {
    this.setState({
      showConfirmationModal: false,
      deleteUserItem: null,
      selectedUser: null
    });
  };

  confirmDeleteUser = () => {
    this.props.deleteUser(this.props.companyId, this.state.deleteUserItem.id);
    this.setState({ deleteUserItem: null, showConfirmationModal: false });
  };

  getFullName = item => {
    return `${item?.firstname?.charAt(0)?.toUpperCase() +
      item?.firstname?.slice(1)} ${item?.lastname?.charAt(0)?.toUpperCase() +
      item?.lastname?.slice(1)}`;
  };

  handleSearch = event => {
    const value = event.target.value.trim();
    this.setState({ searchFilter: value });
  };

  handleSearchByGroup = event => {
    const value = event.target.value.trim();
    if (value) {
      this.setState({ filterByGroup: parseInt(value) });
    } else {
      this.setState({ filterByGroup: null });
    }
  };

  render() {
    const users = this.props.users.filter(user => {
      const { searchFilter, filterByGroup } = this.state;
      let stringSearch = true;
      let groupSearch = true;
      if (searchFilter) {
        const fullName = `${user.firstname} ${user.lastname}`;
        stringSearch =
          user.firstname.toLowerCase().indexOf(searchFilter.toLowerCase()) >
            -1 ||
          user.lastname.toLowerCase().indexOf(searchFilter.toLowerCase()) >
            -1 ||
          user.email.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1 ||
          user.role.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1 ||
          fullName.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1;
      }
      if (filterByGroup) {
        groupSearch = user.groups.includes(filterByGroup);
      }
      return stringSearch && groupSearch;
    });

    return (
      <div>
        <Card
          style={{
            boxShadow:
              "0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)"
          }}
        >
          <Card.Body>
            <Row>
              <Col>
                <h3 style={{ marginTop: "1rem" }}>Users</h3>
              </Col>
              <Col>
                <FeatureAwareContainer requiredRole="COMPANY_ADMINISTRATOR">
                  <OverlayTrigger
                    overlay={
                      <Tooltip id={`tooltip-create-user`}>Create User</Tooltip>
                    }
                  >
                    <Button
                      style={{ float: "right" }}
                      onClick={() => this.setState({ modifyUserModal: true })}
                      className="round-button"
                    >
                      <FontAwesomeIcon className="align-middle" icon={faPlus} />
                    </Button>
                  </OverlayTrigger>
                </FeatureAwareContainer>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Row>
          <InputGroup className="mb-4 mt-4">
            <Col xs={{ span: 12 }} sm={{ span: 6 }}>
              <span
                style={{ width: "50px", height: "50px" }}
                className="d-none d-sm-inline"
              >
                <Button className="round-button-search">
                  <FontAwesomeIcon
                    style={{ margin: "0 auto" }}
                    icon={faSearch}
                    color="white"
                  />
                </Button>
              </span>

              <FormControl
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                placeholder="Search users by name, email or role"
                style={{
                  border: "none",
                  borderRadius: "none",
                  borderBottom: "1px solid #ddd",
                  lineHeight: 2,
                  marginBottom: "10px",
                  marginLeft: "5px"
                }}
                onChange={this.handleSearch}
                className="remove-box-shadow d-xs-flex sm-above"
              />
            </Col>

            <Col xs={{ span: 12 }} sm={{ span: 6 }}>
              <FormSelect
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                style={{
                  border: "none",
                  borderRadius: "none",
                  borderBottom: "1px solid #ddd",
                  lineHeight: 2,
                  marginLeft: "5px",
                  marginRight: "5px",
                  marginBottom: "10px"
                }}
                onChange={this.handleSearchByGroup}
                className="remove-box-shadow d-xs-flex"
              >
                <option value="">Search by group</option>
                {this.props.groups?.map(group => {
                  return <option value={group.id}> {group.name} </option>;
                })}
              </FormSelect>
            </Col>
          </InputGroup>
        </Row>

        <CustomModal
          show={this.state.modifyUserModal}
          onHide={() =>
            this.setState({ modifyUserModal: false, modifiedItem: null })
          }
          title={this.state.modifiedItem?.id ? "Update User" : "Create User"}
        >
          <CreateUserForm
            currentUser={this.props.user.currentUser}
            groups={this.props.groups}
            onSubmit={this.createOrUpdateUser}
            modifiedItem={this.state.modifiedItem}
          />
        </CustomModal>

        {this.state.deleteUserItem && (
          <ConfirmationModal
            titleColor={"red-color"}
            title={
              "Warning: You will not be able to recover this data once deleted"
            }
            description={`Are you sure you want to delete user ${this.getFullName(
              this.state.deleteUserItem
            )} permanently ?`}
            show={this.state.showConfirmationModal}
            hide={this.hideConfirmationModal}
            confirm={this.confirmDeleteUser}
          />
        )}
        {this.state.selectedUser && (
          <ConfirmationModal
            title={"Resend Confirmation"}
            description={`Resend activitation link to user:  ${this.getFullName(
              this.state.selectedUser
            )}`}
            show={this.state.showConfirmationModal}
            hide={this.hideConfirmationModal}
            confirm={this.confirmResendActivationLink}
            confirmButtonText={
              <span>
                <FontAwesomeIcon
                  style={{ marginRight: "2px" }}
                  icon={faCheckCircle}
                />{" "}
                {`Resend Activitation Link`}{" "}
              </span>
            }
            confirmButtonVariant="primary"
          />
        )}
        <Row style={{ marginBottom: "20px" }}>
          <UserList
            groups={this.props.groups}
            users={users}
            handleEditUser={this.handleEdit}
            handleDeleteUser={this.handleDelete}
            resendActivationLink={this.resendActivationLink}
            currentUser={this.props.user.currentUser}
          />
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  fetchUsers: companyId =>
    dispatch({ type: types.FETCH_USERS_REQUEST, companyId }),

  createUser: (companyId, user) =>
    dispatch({ type: types.CREATE_USER_REQUEST, user, companyId }),

  updateUser: (companyId, userId, user) =>
    dispatch({ type: types.UPDATE_USER_REQUEST, user, companyId, userId }),

  deleteUser: (companyId, userId) =>
    dispatch({ type: types.DELETE_USER_REQUEST, companyId, userId }),

  resendActivationLink: (companyId, userId) =>
    dispatch({
      type: types.RESEND_ACTIVITATION_LINK_REQUEST,
      companyId,
      userId
    }),

  fetchGroups: companyId =>
    dispatch({ type: types.FETCH_GROUPS_REQUEST, companyId })
});

const mapStateToProps = state => ({
  ...state.users,
  user: state.user,
  companyId: getCompanyId(state),
  groups: state.groups?.groups || []
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer);

UsersContainer.propTypes = {
  resendActivationLink: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  fetchGroups: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired
    }).isRequired
  ).isRequired
};
