import React, { useState } from "react";
import PropTypes from "prop-types";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Multiselect } from "multiselect-react-dropdown";

const roles = ["OPERATOR", "COMPANY_ADMINISTRATOR", "READ_ONLY"];

function CreateUserForm({ onSubmit, groups, modifiedItem, currentUser }) {
  const [firstname, setFirstname] = useState(modifiedItem?.firstname || "");
  const [lastname, setLastname] = useState(modifiedItem?.lastname || "");
  const [email, setEmail] = useState(modifiedItem?.email || "");
  const [role, setRole] = useState(modifiedItem?.role || roles[0]);
  const selectedGroupOptions =
    modifiedItem &&
    modifiedItem.groups &&
    groups?.filter(item => modifiedItem.groups.includes(item.id));

  const [selectedGroups, setSelectedGroups] = useState(
    selectedGroupOptions || []
  );

  const onSelect = items => {
    setSelectedGroups(items);
  };

  const onRemove = items => {
    setSelectedGroups(items);
  };

  return (
    <div>
      <Form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Form.Group className={"mb-3"} controlId="formBasicfirstname">
          <Form.Label>First Name*</Form.Label>
          <Form.Control
            value={firstname}
            type="text"
            placeholder="First Name"
            onChange={e => setFirstname(e.target.value)}
          />
        </Form.Group>

        <Form.Group className={"mb-3"} controlId="formBasiclastname">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            value={lastname}
            type="text"
            placeholder="Last Name"
            onChange={e => setLastname(e.target.value)}
          />
        </Form.Group>

        <Form.Group className={"mb-3"} controlId="formBasicEmail">
          <Form.Label>Email*</Form.Label>
          <Form.Control
            value={email}
            type="text"
            placeholder="Email"
            onChange={e => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group className={"mb-3"} controlId="exampleForm.ControlSelect1">
          <Form.Label>Role</Form.Label>
          <Form.Select
            disabled={
              modifiedItem &&
              (currentUser.role === roles[0] || currentUser.role === roles[2])
                ? true
                : false
            }
            value={role}
            onChange={e => setRole(e.target.value)}
            // as="select"
          >
            {roles.map(r => (
              <option
                selected={modifiedItem?.role === r ? true : false}
                key={r}
              >
                {r}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Add Groups</Form.Label>
          <Multiselect
            options={groups} // Options to display in the dropdown
            selectedValues={selectedGroups} // Preselected value to persist in dropdown
            onSelect={onSelect} // Function will trigger on select event
            onRemove={onRemove} // Function will trigger on remove event
            displayValue="name" // Property name to display in the dropdown options
          />
        </Form.Group>

        <Form.Group className={"mb-3 float-end"}>
          <Button
            disabled={!(email.length > 0)}
            onClick={() => {
              if (firstname.length > 0 && email.length > 2) {
                let selectedGroupIds = [];
                if (selectedGroups.length) {
                  selectedGroups.map(item => selectedGroupIds.push(item.id));
                }

                onSubmit({
                  firstname,
                  lastname,
                  email,
                  role,
                  selectedGroupIds
                });
              }
            }}
            variant="primary"
            type="submit"
          >
            Save
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}

export default CreateUserForm;

CreateUserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
