import React, { useState } from "react";
import PropTypes from "prop-types";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const planTypes = [
  { type: "RED_GREEN", name: "Green or Red", description: "Red or green" },
  {
    type: "RED_GREEN_YELLOW",
    name: "Green, Yellow or Red",
    description: "Red, green or yellow"
  }
];

const INPUT_TYPE_BOOL = "BOOL";
const INPUT_TYPE_NUMBER = "NUMBER";

const inputTypes = [
  {
    type: "BOOL",
    name: "Only color scale",
    description: "Select from color scale"
  },
  {
    type: "NUMBER",
    name: "Color scale + additional input",
    description: "Type in a number"
  }
];

const days = [
  { value: "MONDAY", label: "Monday" },
  { value: "TUESDAY", label: "Tuesday" },
  { value: "WEDNESDAY", label: "Wednesday" },
  { value: "THURSDAY", label: "Thursday" },
  { value: "FRIDAY", label: "Friday" },
  { value: "SATURDAY", label: "Saturday" },
  { value: "SUNDAY", label: "Sunday" }
];

function ActionForm({ onSubmit, modifiedItem, deleteButton = "" }) {
  const [name, setName] = useState(modifiedItem?.name || "");
  const [description, setDescription] = useState(
    modifiedItem?.description || ""
  );
  const [goodCriteria, setGoodCriteria] = useState(
    modifiedItem?.goodCriteria || ""
  );
  const [badCriteria, setBadCriteria] = useState(
    modifiedItem?.badCriteria || ""
  );
  const [planType, setPlanType] = useState(
    modifiedItem?.planType || planTypes[0].type
  );
  const [inputType, setInputType] = useState(
    modifiedItem?.inputType || INPUT_TYPE_BOOL
  );
  const [wizardState, setWizardState] = useState(0);

  const existingDays =
    modifiedItem && days.filter(day => modifiedItem.days.includes(day.value));
  const [selectedDays, setSelectedDays] = useState(existingDays || [...days]);

  const handleInputType = event => {
    const { checked } = event.target;
    if (checked) {
      setInputType(INPUT_TYPE_NUMBER);
    } else {
      setInputType(INPUT_TYPE_BOOL);
    }
  };

  return (
    <div>
      {(() => {
        if (wizardState == 0) {
          return (
            <Form
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Name*</Form.Label>
                <Form.Control
                  value={name}
                  type="text"
                  placeholder="Enter Name of action"
                  onChange={e => setName(e.target.value)}
                />
                <Form.Text className="text-muted">
                  Enter the name of the action you want to create
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  value={description}
                  type="text"
                  placeholder="Description"
                  onChange={e => setDescription(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="form.type">
                <Form.Label>Color scale</Form.Label>
                <Form.Select
                  // as="select"
                  value={planType}
                  onChange={e => setPlanType(e.target.value)}
                >
                  {planTypes.map(p => (
                    <option value={p.type} key={p.type}>
                      {p.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicGoodCriteria">
                <Form.Label>Good criteria</Form.Label>
                <Form.Control
                  value={goodCriteria}
                  type="text"
                  placeholder="Good criteria"
                  onChange={e => setGoodCriteria(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicGoodCriteria">
                <Form.Label>Bad criteria</Form.Label>
                <Form.Control
                  value={badCriteria}
                  type="text"
                  placeholder="Bad criteria"
                  onChange={e => setBadCriteria(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Check
                  checked={inputType === INPUT_TYPE_NUMBER ? true : false}
                  required
                  name="inputType"
                  label="Value Input"
                  onChange={handleInputType}
                />
                <Form.Text className="text-muted">
                  check this if you need additional input for value in while
                  creating events
                </Form.Text>
              </Form.Group>

              <br />

              {deleteButton}

              <Button
                className="float-end"
                disabled={!(name.length > 0)}
                onClick={() => {
                  if (name.length > 0) {
                    setWizardState(1);
                  }
                }}
                variant="primary"
                type="submit"
              >
                {`Next`}
              </Button>
            </Form>
          );
        } else {
          return (
            <Form
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <Form.Label>What days should this be done?</Form.Label>
              {days.map(day => (
                <div key={`${day.value}`}>
                  <Form.Check
                    type="checkbox"
                    id={`${day.value}`}
                    label={`${day.label}`}
                    onChange={e => {
                      let newArray = [...selectedDays];
                      const index = newArray.findIndex(
                        d => d.value == day.value
                      );
                      if (index >= 0) {
                        newArray.splice(index, 1);
                      } else {
                        newArray.push(day);
                      }
                      setSelectedDays(newArray);
                    }}
                    checked={
                      selectedDays.findIndex(d => d.value == day.value) >= 0
                    }
                  />
                </div>
              ))}
              <br />

              <Button
                disabled={!(name.length > 0)}
                onClick={() => {
                  if (name.length > 0) {
                    onSubmit(
                      name,
                      description,
                      goodCriteria,
                      badCriteria,
                      planType,
                      inputType,
                      selectedDays.map(d => d.value)
                    );
                  }
                }}
                variant="primary"
                type="submit"
              >
                <FontAwesomeIcon icon={faCheck} />{" "}
                <span style={{ marginLeft: "2px" }}>{`Save`}</span>
              </Button>
            </Form>
          );
        }
      })()}
    </div>
  );
}

export default ActionForm;

ActionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
