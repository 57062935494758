import {
  FETCH_SCHEDULE_DETAIL_REQUEST,
  FETCH_SCHEDULE_DETAIL_SUCCESS,
  FETCH_SCHEDULE_DETAIL_ERROR,
  CREATE_ACTION_REQUEST,
  CREATE_ACTION_SUCCESS,
  CREATE_ACTION_ERROR,
  COPY_ACTION_REQUEST,
  COPY_ACTION_SUCCESS,
  COPY_ACTION_ERROR
} from "../actions/types";

const initialState = { schedules: [], loading: false, error: false };

const getSchedulesAfterSuccess = (schedules, schedule) => {
  const scheduleId = schedule.id;
  const index = schedules.findIndex(s => s.id == scheduleId);
  const newSchedules = [...schedules];
  if (index < 0) {
    newSchedules.push(schedule);
  } else {
    newSchedules[index] = schedule;
  }
  return newSchedules;
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_SCHEDULE_DETAIL_REQUEST:
      return { ...state, loading: true, error: false };
    case FETCH_SCHEDULE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        schedules: getSchedulesAfterSuccess(state.schedules, action.schedule)
      };
    case FETCH_SCHEDULE_DETAIL_ERROR:
      return { ...state, loading: false, error: true };
    case CREATE_ACTION_REQUEST:
      return { ...state, loading: true, error: false };
    case CREATE_ACTION_SUCCESS:
      return { ...state, loading: false, error: false }; //fetch details after
    case CREATE_ACTION_ERROR:
      return { ...state, loading: false, error: true };
    case COPY_ACTION_REQUEST:
      return { ...state, loading: true, error: false };
    case COPY_ACTION_SUCCESS:
      return { ...state, loading: false, error: false };
    case COPY_ACTION_ERROR:
      return { ...state, loading: false, error: true };
  }
  return state;
}
