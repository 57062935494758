export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";

export const VERIFY_ACCOUNT_REQUEST = "VERIFY_ACCOUNT_REQUEST";
export const VERIFY_ACCOUNT_SUCCESS = "VERIFY_ACCOUNT_SUCCESS";
export const VERIFY_ACCOUNT_ERROR = "VERIFY_ACCOUNT_ERROR";

export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_ERROR = "FETCH_USER_ERROR";

export const FETCH_SPECIFIC_USER_REQUEST = "FETCH_SPECIFIC_USER_REQUEST";
export const FETCH_SPECIFIC_USER_SUCCESS = "FETCH_SPECIFIC_USER_SUCCESS";
export const FETCH_SPECIFIC_USER_ERROR = "FETCH_SPECIFIC_USER_ERROR";

export const ADD_USER_TO_GROUP_REQUEST = "ADD_USER_TO_GROUP_REQUEST";
export const ADD_USER_TO_GROUP_SUCCESS = "ADD_USER_TO_GROUP_SUCCESS";
export const ADD_USER_TO_GROUP_ERROR = "ADD_USER_TO_GROUP_ERROR";

export const DELETE_USER_FROM_GROUP_REQUEST = "DELETE_USER_FROM_GROUP_REQUEST";
export const DELETE_USER_FROM_GROUP_SUCCESS = "DELETE_USER_FROM_GROUP_SUCCESS";
export const DELETE_USER_FROM_GROUP_ERROR = "DELETE_USER_FROM_GROUP_ERROR";

export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_ERROR = "FETCH_USERS_ERROR";

export const FETCH_GROUPS_REQUEST = "FETCH_GROUPS_REQUEST";
export const FETCH_GROUPS_SUCCESS = "FETCH_GROUPS_SUCCESS";
export const FETCH_GROUPS_ERROR = "FETCH_GROUPS_ERROR";

export const ADD_SCHEDULE_TO_GROUP = "ADD_SCHEDULE_TO_GROUP";
export const DELETE_SCHEDULE_FROM_GROUP = "DELETE_SCHEDULE_FROM_GROUP";

export const DELETE_GROUP_REQUEST = "DELETE_GROUP_REQUEST";

export const CREATE_GROUP_REQUEST = "CREATE_GROUP_REQUEST";

export const UPDATE_GROUP_REQUEST = "UPDATE_GROUP_REQUEST";

export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

export const RESEND_ACTIVITATION_LINK_REQUEST =
  "RESEND_ACTIVITATION_LINK_REQUEST";
export const RESEND_ACTIVITATION_LINK_SUCCESS =
  "RESEND_ACTIVITATION_LINK_SUCCESS";
export const RESEND_ACTIVITATION_LINK_ERROR = "RESEND_ACTIVITATION_LINK_ERROR";

export const FETCH_SCHEDULES_REQUEST = "FETCH_SCHEDULES_REQUEST";
export const FETCH_SCHEDULES_SUCCESS = "FETCH_SCHEDULES_SUCCESS";
export const FETCH_SCHEDULES_ERROR = "FETCH_SCHEDULES_ERROR";

export const CREATE_SCHEDULE_REQUEST = "CREATE_SCHEDULE_REQUEST";
export const CREATE_SCHEDULE_SUCCESS = "CREATE_SCHEDULE_SUCCESS";
export const CREATE_SCHEDULE_ERROR = "CREATE_SCHEDULE_ERROR";

export const UPDATE_SCHEDULE_REQUEST = "UPDATE_SCHEDULE_REQUEST";

export const DELETE_SCHEDULE_REQUEST = "DELETE_SCHEDULE_REQUEST";
export const DELETE_SCHEDULE_SUCCESS = "DELETE_SCHEDULE_SUCCESS";
export const DELETE_SCHEDULE_ERROR = "DELETE_SCHEDULE_ERROR";

export const FETCH_SCHEDULE_DETAIL_REQUEST = "FETCH_SCHEDULE_DETAIL_REQUEST";
export const FETCH_SCHEDULE_DETAIL_SUCCESS = "FETCH_SCHEDULE_DETAIL_SUCCESS";
export const FETCH_SCHEDULE_DETAIL_ERROR = "FETCH_SCHEDULE_DETAIL_ERROR";

export const COPY_SCHEDULE_REQUEST = "COPY_SCHEDULE_REQUEST";
export const COPY_SCHEDULE_SUCCESS = "COPY_SCHEDULE_SUCCESS";
export const COPY_SCHEDULE_ERROR = "COPY_SCHEDULE_ERROR";

export const DELETE_ACTION_REQUEST = "DELETE_ACTION_REQUEST";

export const CREATE_ACTION_REQUEST = "CREATE_SCHEDULE_DETAIL_REQUEST";
export const CREATE_ACTION_SUCCESS = "CREATE_SCHEDULE_DETAIL_SUCCESS";
export const CREATE_ACTION_ERROR = "CREATE_SCHEDULE_DETAIL_ERROR";

export const UPDATE_ACTION_REQUEST = "UPDATE_ACTION_REQUEST";

export const COPY_ACTION_REQUEST = "COPY_SCHEDULE_DETAIL_REQUEST";
export const COPY_ACTION_SUCCESS = "COPY_SCHEDULE_DETAIL_SUCCESS";
export const COPY_ACTION_ERROR = "COPY_SCHEDULE_DETAIL_ERROR";

export const FETCH_EVENTS_REQUEST = "FETCH_EVENTS_REQUEST";
export const FETCH_EVENTS_SUCCESS = "FETCH_EVENTS_SUCCESS";
export const FETCH_EVENTS_ERROR = "FETCH_EVENTS_ERROR";

export const FETCH_COMPANIES_REQUEST = "FETCH_COMPANIES_REQUEST";
export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS";
export const FETCH_COMPANIES_ERROR = "FETCH_COMPANIES_ERROR";

export const ADMIN_SET_ACTIVE_COMPANY_ID = "ADMIN_SET_ACTIVE_COMPANY_ID";

export const CREATE_COMPANY_REQUEST = "CREATE_COMPANY_REQUEST";

export const CREATE_EVENT_REQUEST = "CREATE_EVENT_REQUEST";
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const CREATE_EVENT_ERROR = "CREATE_EVENT_ERROR";

export const UPDATE_EVENT_REQUEST = "UPDATE_EVENT_REQUEST";
export const DELETE_EVENT_REQUEST = "DELETE_EVENT_REQUEST";

export const CREATE_HOLIDAY_REQUEST = "CREATE_HOLIDAY_REQUEST";
export const CREATE_HOLIDAY_SUCCESS = "CREATE_HOLIDAY_SUCCESS";
export const CREATE_HOLIDAY_ERROR = "CREATE_HOLIDAY_ERROR";

export const UPDATE_HOLIDAY_REQUEST = "UPDATE_HOLIDAY_REQUEST";
export const UPDATE_HOLIDAY_SUCCESS = "UPDATE_HOLIDAY_SUCCESS";
export const UPDATE_HOLIDAY_ERROR = "UPDATE_HOLIDAY_ERROR";

export const FETCH_HOLIDAY_REQUEST = "FETCH_HOLIDAY_REQUEST";
export const FETCH_HOLIDAY_SUCCESS = "FETCH_HOLIDAY_SUCCESS";
export const FETCH_HOLIDAY_ERROR = "FETCH_HOLIDAY_ERROR";

export const DELETE_HOLIDAY_REQUEST = "DELETE_HOLIDAY_REQUEST";
export const DELETE_HOLIDAY_SUCCESS = "DELETE_HOLIDAY_SUCCESS";
export const DELETE_HOLIDAY_ERROR = "DELETE_HOLIDAY_ERROR";
