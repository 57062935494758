import React from "react";
import PropTypes from "prop-types";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import { Button, Row, Col, Card } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { connect } from "react-redux";
import * as types from "../actions/types";
import { getCompanyId } from "../selectors";
import CustomModal from "../components/CustomModal";
import CreateGroupForm from "../components/CreateGroupForm";

import FeatureAwareContainer from "./FeatureAwareContainer";
import GroupsTable from "./GroupsTable";

class GroupsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showGroupModal: false,
      showAddScheduleToGroupModal: false,
      selectedGroupId: null,
      modifiedItem: null
    };
  }

  componentDidMount() {
    this.props.fetchGroups(this.props.companyId);
    this.props.fetchUsers(this.props.companyId);
    this.props.fetchSchedules(this.props.companyId);
  }

  createOrUpdateGroup = (name, description) => {
    if (this.state.modifiedItem?.id) {
      this.props.updateGroup(
        this.props.companyId,
        this.state.modifiedItem?.id,
        { name, description }
      );
    } else {
      this.props.createGroup(this.props.companyId, { name, description });
    }
    this.setState({ showGroupModal: false, modifiedItem: null });
  };

  handleDeleteGroup = groupId => {
    this.props.deleteGroup(this.props.companyId, groupId);
  };

  handleEditGroup = group => {
    this.setState({ modifiedItem: group, showGroupModal: true });
  };

  handleAddSchedulesToGroup = ({ scheduleId, groupId }) => {
    this.props.addScheduleToGroup(this.props.companyId, groupId, scheduleId);
  };

  handleRemoveScheduleFromGroup = ({ scheduleId, groupId }) => {
    this.props.deleteScheduleFromGroup(
      this.props.companyId,
      groupId,
      scheduleId
    );
  };

  handleAddUserToGroup = ({ userId, groupId }) => {
    this.props.addUserToGroup(this.props.companyId, userId, groupId);
  };

  handleRemoveUserFromGroup = ({ userId, groupId }) => {
    this.props.deleteUserFromGroup(this.props.companyId, userId, groupId);
  };

  render() {
    return (
      <div>
        <Card
          className="mb-4"
          style={{
            boxShadow:
              "0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)"
          }}
        >
          <Card.Body>
            <Row>
              <Col>
                <h3 style={{ marginTop: "1rem" }}>Groups</h3>
              </Col>
              <Col>
                <FeatureAwareContainer requiredRole="COMPANY_ADMINISTRATOR">
                  <OverlayTrigger
                    overlay={
                      <Tooltip id={`tooltip-create-group`}>
                        Create Group
                      </Tooltip>
                    }
                  >
                    <Button
                      style={{
                        float: "right"
                      }}
                      className="round-button"
                      onClick={() => this.setState({ showGroupModal: true })}
                    >
                      <FontAwesomeIcon
                        color="#fff"
                        className="align-middle"
                        icon={faPlus}
                      />
                    </Button>
                  </OverlayTrigger>
                </FeatureAwareContainer>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Row>
          <GroupsTable
            groups={this.props.groups}
            deleteGroup={this.handleDeleteGroup}
            editGroup={this.handleEditGroup}
            schedules={this.props.schedules}
            users={this.props.users}
            handleAddSchedules={this.handleAddSchedulesToGroup}
            handleAddUsers={this.handleAddUserToGroup}
            handleRemoveSchedule={this.handleRemoveScheduleFromGroup}
            handleRemoveUser={this.handleRemoveUserFromGroup}
          />
        </Row>

        <CustomModal
          show={this.state.showGroupModal}
          onHide={() => this.setState({ showGroupModal: false })}
          title={this.state.modifiedItem ? "Update Group" : "Create Group"}
        >
          <CreateGroupForm
            onSubmit={this.createOrUpdateGroup}
            modifiedItem={this.state.modifiedItem}
          />
        </CustomModal>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  fetchGroups: companyId =>
    dispatch({ type: types.FETCH_GROUPS_REQUEST, companyId }),
  createGroup: (companyId, group) =>
    dispatch({ type: types.CREATE_GROUP_REQUEST, companyId, group }),
  updateGroup: (companyId, groupId, group) =>
    dispatch({ type: types.UPDATE_GROUP_REQUEST, companyId, groupId, group }),
  deleteGroup: (companyId, groupId) =>
    dispatch({ type: types.DELETE_GROUP_REQUEST, companyId, groupId }),
  addScheduleToGroup: (companyId, groupId, scheduleId) =>
    dispatch({
      type: types.ADD_SCHEDULE_TO_GROUP,
      companyId,
      groupId,
      scheduleId
    }),
  deleteScheduleFromGroup: (companyId, groupId, scheduleId) => {
    dispatch({
      type: types.DELETE_SCHEDULE_FROM_GROUP,
      companyId,
      groupId,
      scheduleId
    });
  },
  fetchUsers: companyId =>
    dispatch({ type: types.FETCH_USERS_REQUEST, companyId }),

  fetchSchedules: companyId =>
    dispatch({ type: types.FETCH_SCHEDULES_REQUEST, companyId }),

  addUserToGroup: (companyId, userId, groupId) =>
    dispatch({
      type: types.ADD_USER_TO_GROUP_REQUEST,
      companyId,
      userId,
      groupId
    }),
  deleteUserFromGroup: (companyId, userId, groupId) =>
    dispatch({
      type: types.DELETE_USER_FROM_GROUP_REQUEST,
      companyId,
      userId,
      groupId
    })
});

const mapStateToProps = state => ({
  ...state.groups,
  companyId: getCompanyId(state),
  schedules:
    state.scheduleList && state.scheduleList.schedules
      ? state.scheduleList.schedules
      : [],
  users: state.users.users || []
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupsContainer);

GroupsContainer.propTypes = {
  fetchGroups: PropTypes.func.isRequired,
  createGroup: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  addScheduleToGroup: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      companyId: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      schedules: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          description: PropTypes.string
        }).isRequired
      ).isRequired
    }).isRequired
  ).isRequired
};
