import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";

import { connect } from "react-redux";
import ForgotPasswordForm from "../components/ForgotPasswordForm";
import * as types from "../actions/types";

class ForgotPasswordContainer extends React.Component {
  handleReset = email => {
    this.props.reset(email);
    this.props.history.push("/login");
  };

  render() {
    return (
      <Row>
        <Col
          xs={{ span: 12 }}
          md={{ span: 6, offset: 3 }}
          lg={{ span: 4, offset: 4 }}
        >
          <div
            className="align-middle"
            style={{
              height: "calc(100vh - 200px)",
              display: "flex",
              alignItems: "center"
            }}
          >
            <ForgotPasswordForm onSubmit={this.handleReset} />
          </div>
        </Col>
      </Row>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  reset: email => dispatch({ type: types.RESET_PASSWORD_REQUEST, email })
});

export default connect(null, mapDispatchToProps)(ForgotPasswordContainer);

ForgotPasswordContainer.propTypes = {
  reset: PropTypes.func.isRequired
};
