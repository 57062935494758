import React from "react";

import { Card, Row, Col, Table, FormCheck, FormText } from "react-bootstrap";

const RolesContainer = () => {
  return (
    <div>
      <Card
        className="mb-4"
        style={{
          boxShadow:
            "0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)"
        }}
      >
        <Card.Body>
          <Row>
            <Col>
              <h3 style={{ marginTop: "1rem" }}>Roles and Permissions</h3>
            </Col>
          </Row>
          <Row>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th className="align-middle p-3"> Features </th>
                  <th className="align-middle p-3"> Company Administrator </th>
                  <th className="align-middle p-3">
                    {" "}
                    Operator <small>(Only in assigned groups)</small>{" "}
                  </th>
                  <th className="align-middle p-3">
                    {" "}
                    Read only <small>(Only in assigned groups)</small>{" "}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td> Plans </td>
                  <td>
                    <FormCheck
                      type="checkbox"
                      label="Read"
                      checked={true}
                      disabled={true}
                    />

                    <FormCheck
                      type="checkbox"
                      label="Write"
                      checked={true}
                      disabled={true}
                    />
                  </td>

                  <td>
                    <FormCheck
                      type="checkbox"
                      label="Read"
                      checked={true}
                      disabled={true}
                    />

                    <FormCheck
                      type="checkbox"
                      label="Write"
                      checked={false}
                      disabled={true}
                    />
                  </td>
                  <td>
                    <FormCheck
                      type="checkbox"
                      label="Read"
                      checked={true}
                      disabled={true}
                    />

                    <FormCheck
                      type="checkbox"
                      label="Write"
                      checked={false}
                      disabled={true}
                    />
                  </td>
                </tr>

                <tr>
                  <td> Actions </td>
                  <td>
                    <FormCheck
                      type="checkbox"
                      label="Read"
                      checked={true}
                      disabled={true}
                    />

                    <FormCheck
                      type="checkbox"
                      label="Write"
                      checked={true}
                      disabled={true}
                    />
                  </td>

                  <td>
                    <FormCheck
                      type="checkbox"
                      label="Read"
                      checked={true}
                      disabled={true}
                    />

                    <FormCheck
                      type="checkbox"
                      label="Write"
                      checked={false}
                      disabled={true}
                    />
                  </td>
                  <td>
                    <FormCheck
                      type="checkbox"
                      label="Read"
                      checked={true}
                      disabled={true}
                    />

                    <FormCheck
                      type="checkbox"
                      label="Write"
                      checked={false}
                      disabled={true}
                    />
                  </td>
                </tr>

                <tr>
                  <td> Events </td>
                  <td>
                    <FormCheck
                      type="checkbox"
                      label="Read"
                      checked={true}
                      disabled={true}
                    />

                    <FormCheck
                      type="checkbox"
                      label="Write"
                      checked={true}
                      disabled={true}
                    />
                  </td>

                  <td>
                    <FormCheck
                      type="checkbox"
                      label="Read"
                      checked={true}
                      disabled={true}
                    />

                    <FormCheck
                      type="checkbox"
                      label="Write"
                      checked={true}
                      disabled={true}
                    />
                  </td>
                  <td>
                    <FormCheck
                      type="checkbox"
                      label="Read"
                      checked={true}
                      disabled={true}
                    />

                    <FormCheck
                      type="checkbox"
                      label="Write"
                      checked={false}
                      disabled={true}
                    />
                  </td>
                </tr>

                <tr>
                  <td> Users </td>
                  <td>
                    <FormCheck
                      type="checkbox"
                      label="Read"
                      checked={true}
                      disabled={true}
                    />

                    <FormCheck
                      type="checkbox"
                      label="Write"
                      checked={true}
                      disabled={true}
                    />
                  </td>

                  <td>
                    <FormCheck
                      type="checkbox"
                      label="Read"
                      checked={false}
                      disabled={true}
                    />

                    <FormCheck
                      type="checkbox"
                      label="Write"
                      checked={false}
                      disabled={true}
                    />
                  </td>
                  <td>
                    <FormCheck
                      type="checkbox"
                      label="Read"
                      checked={false}
                      disabled={true}
                    />

                    <FormCheck
                      type="checkbox"
                      label="Write"
                      checked={false}
                      disabled={true}
                    />
                  </td>
                </tr>

                <tr>
                  <td> Groups </td>
                  <td>
                    <FormCheck
                      type="checkbox"
                      label="Read"
                      checked={true}
                      disabled={true}
                    />

                    <FormCheck
                      type="checkbox"
                      label="Write"
                      checked={true}
                      disabled={true}
                    />

                    <FormCheck
                      type="checkbox"
                      label="Add users to group"
                      checked={true}
                      disabled={true}
                    />

                    <FormCheck
                      type="checkbox"
                      label="Add plans to group"
                      checked={true}
                      disabled={true}
                    />
                  </td>

                  <td>
                    <FormCheck
                      type="checkbox"
                      label="Read"
                      checked={false}
                      disabled={true}
                    />

                    <FormCheck
                      type="checkbox"
                      label="Write"
                      checked={false}
                      disabled={true}
                    />

                    <FormCheck
                      type="checkbox"
                      label="Add users to group"
                      checked={false}
                      disabled={true}
                    />

                    <FormCheck
                      type="checkbox"
                      label="Add plans to group"
                      checked={false}
                      disabled={true}
                    />
                  </td>
                  <td>
                    <FormCheck
                      type="checkbox"
                      label="Read"
                      checked={false}
                      disabled={true}
                    />

                    <FormCheck
                      type="checkbox"
                      label="Write"
                      checked={false}
                      disabled={true}
                    />

                    <FormCheck
                      type="checkbox"
                      label="Add users to group"
                      checked={false}
                      disabled={true}
                    />

                    <FormCheck
                      type="checkbox"
                      label="Add plans to group"
                      checked={false}
                      disabled={true}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default RolesContainer;
