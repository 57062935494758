import React, { useState } from "react";
import PropTypes from "prop-types";

import { Form, Button, Card } from "react-bootstrap";

const ForgotPasswordForm = ({ onSubmit }) => {
  const [email, setEmail] = useState("");

  return (
    <Card
      style={{
        margin: "0 auto",
        verticalAlign: "middle",
        width: "100%",
        padding: "20px",
        boxShadow:
          "0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)",
        backgroundColor: "#fff"
      }}
    >
      <Card.Title className="text-center mt-4">
        <h4> Reset Password </h4>
      </Card.Title>

      <Card.Body>
        <Form className="p-4">
          <Form.Group className="mb-4" controlId="formGroupEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" onClick={() => onSubmit(email)}>
            RESET PASSWORD
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );

  // return (
  //   <Form>
  //     <Form.Group className="mb-4" controlId="formGroupEmail">
  //       <Form.Label>Email address</Form.Label>
  //       <Form.Control
  //         type="email"
  //         placeholder="Enter email"
  //         value={email}
  //         onChange={e => setEmail(e.target.value)}
  //       />
  //     </Form.Group>
  //     <Button variant="success" onClick={() => onSubmit(email)}>
  //       Reset Password
  //     </Button>
  //   </Form>
  // );
};

export default ForgotPasswordForm;

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
