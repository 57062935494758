import React from "react";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { store, persistor } from "./store";

import RootContainer from "./containers";

function App() {
  return (
    <>
      <div className="content">
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <RootContainer />
          </PersistGate>
        </Provider>
      </div>

      <div>
        <footer className="page-footer font-small purple bottom-fixed nav-background-color">
          <div
            style={{ color: "white" }}
            className="footer-copyright text-center py-3"
          >
            {`© ${new Date().getFullYear()} Blackbird ApS. All rights reserved.`}
          </div>
        </footer>
      </div>
    </>
  );
}

export default App;
