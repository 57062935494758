import { call, put, take, all } from "redux-saga/effects";
import * as Api from "../services/";

import { show, error } from "react-notification-system-redux";

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  VERIFY_ACCOUNT_REQUEST,
  VERIFY_ACCOUNT_SUCCESS,
  VERIFY_ACCOUNT_ERROR,
  RESET_PASSWORD_REQUEST
} from "../actions/types";

function* authorize(email, password) {
  try {
    const token = yield call(Api.login, email, password);
    yield put({ type: LOGIN_SUCCESS, token });
    yield put(
      show({
        title: "Login success",
        message: `Welcome ${email}`,
        autoDismiss: 4
      })
    );
    return token;
  } catch (err) {
    yield put(
      error({
        title: "Login error",
        message: "Wrong email or password",
        autoDismiss: 4
      })
    );
    yield put({ type: LOGIN_ERROR, err });
  }
}

function* loginFlow() {
  while (true) {
    const { email, password } = yield take(LOGIN_REQUEST);
    const token = yield call(authorize, email, password);
    yield call(Api.setToken, token);
    if (token) {
      yield put({ type: LOGIN_SUCCESS, token });
      yield put({ type: FETCH_USER_REQUEST });
      const response = yield call(Api.getCurrentUser, token);
      yield put({ type: FETCH_USER_SUCCESS, payload: response.user });

      // yield take(LOGOUT);
      //yield call(Api.logout, 'token');
    }
  }
}

function* verifyFlow() {
  while (true) {
    const { token, password } = yield take(VERIFY_ACCOUNT_REQUEST);
    try {
      yield call(Api.verifyAccount, token, password);
      yield put({ type: VERIFY_ACCOUNT_SUCCESS });
      yield put(
        show({
          title: "Account verified",
          message: `Your account has now been verified`,
          autoDismiss: 4
        })
      );
    } catch (err) {
      yield put({ type: VERIFY_ACCOUNT_ERROR, err });
      yield put(
        error({
          title: "Failed to verify account",
          message: "Failed to verify your account",
          autoDismiss: 4
        })
      );
      console.log(err);
    }
  }
}

function* resetPasswordFlow() {
  while (true) {
    const { email } = yield take(RESET_PASSWORD_REQUEST);
    try {
      yield call(Api.resetPasswordRequest, email);
      yield put(
        show({
          title: "Password reset",
          message: `Your password for ${email} was reset. Check your mailbox`,
          autoDismiss: 15
        })
      );
    } catch (err) {
      yield put(
        error({
          title: "Failed to reset password",
          message: `Failed to reset password for ${email}`,
          autoDismiss: 15
        })
      );
      console.log(err);
    }
  }
}

export default function* flow() {
  yield all([loginFlow(), verifyFlow(), resetPasswordFlow()]);
}
