import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Container from "react-bootstrap/Container";

import NavigationContainer from "./NavigationContainer";
import HomeContainer from "./HomeContainer";

import LoginContainer from "./LoginContainer";
import ForgotPasswordContainer from "./ForgotPasswordContainer";
import SchedulesContainer from "./SchedulesContainer";
import ScheduleContainer from "./ScheduleContainer";
import UsersContainer from "./UsersContainer";
import GroupsContainer from "./GroupsContainer";
import ManagePlansContainer from "./ManagePlansContainer";
import FeatureAwareContainer from "./FeatureAwareContainer";
import CompaniesContainer from "./CompaniesContainer";
import RolesContainer from "./RolesContainer";
import ConfigurationsContainer from "./ConfigurationsContainer";

import VerifyAccountContainer from "./VerifyAccountContainer";
import NotificationContainer from "./NotificationContainer";
import OutdatedBrowser from "../components/OutdatedBrowser";

function outDatedBrowser() {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf("MSIE ");
  if (msie > 0) {
    // IE 10 or older => return version number
    return true;
  }
  var trident = ua.indexOf("Trident/");
  if (trident > 0) {
    // IE 11 => return version number
    ua.indexOf("rv:");
    return true;
  }
  return false;
}

function RootContainer({ loggedIn, hasFetchedUser }) {
  if (outDatedBrowser()) {
    return <OutdatedBrowser />;
  }
  return (
    <Router>
      <NotificationContainer />
      <NavigationContainer />
      <Container className="adjust-padding" fluid={true}>
        {loggedIn && hasFetchedUser ? (
          <React.Fragment>
            <Switch>
              <Route path="/" exact component={HomeContainer} />
              <Route path="/plans" exact component={SchedulesContainer} />
              <Route path="/companies" exact component={CompaniesContainer} />
              <Route
                path="/plans/:scheduleId/days/:days"
                component={ScheduleContainer}
              />
              <Route path="/plans/:scheduleId" component={ScheduleContainer} />
              <FeatureAwareContainer requiredRole="COMPANY_ADMINISTRATOR">
                <Route path="/users" component={UsersContainer} />
                <Route path="/groups" component={GroupsContainer} />
                <Route path="/roles" component={RolesContainer} />
                <Route
                  path="/configurations"
                  component={ConfigurationsContainer}
                />
                <Route path="/manage-plans" component={ManagePlansContainer} />
              </FeatureAwareContainer>
            </Switch>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Route path="/login" component={LoginContainer} />
            <Route path="/" exact component={LoginContainer} />
            <Route path="/verify" component={VerifyAccountContainer} />
            <Route path="/forgot" component={ForgotPasswordContainer} />
          </React.Fragment>
        )}
      </Container>
    </Router>
  );
}

const mapStateToProps = state => {
  const isLoggedIn = state.login && state.login.loggedIn;
  const hasFetchedUser = state.user && state.user.currentUser;
  const role = hasFetchedUser ? state.user.currentUser.role : "UNKNOWN";
  return {
    loggedIn: isLoggedIn,
    hasFetchedUser,
    role
  };
};

export default connect(mapStateToProps)(RootContainer);

RootContainer.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  hasFetchedUser: PropTypes.bool.isRequired,
  role: PropTypes.oneOf([
    "UNKNOWN",
    "OPERATOR",
    "READ_ONLY",
    "COMPANY_ADMINISTRATOR",
    "ADMINISTRATOR"
  ])
};
