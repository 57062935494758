import {
  VERIFY_ACCOUNT_REQUEST,
  VERIFY_ACCOUNT_SUCCESS,
  VERIFY_ACCOUNT_ERROR
} from "../actions/types";

const initialState = {
  loading: false,
  error: false,
  verified: false,
  err: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case VERIFY_ACCOUNT_REQUEST:
      return { ...state, loading: true, error: false, err: null };
    case VERIFY_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        verified: true,
        err: null
      };
    case VERIFY_ACCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        verified: false,
        err: action.err
      };
  }
  return state;
}
