import React, { useState } from "react";
import PropTypes from "prop-types";

import Table from "react-bootstrap/Table";

import { Dropdown } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faEdit,
  faTrashAlt,
  faEnvelopeOpen
} from "@fortawesome/free-solid-svg-icons";

import CustomModal from "./CustomModal";
import FeatureAwareContainer from "../containers/FeatureAwareContainer";
import UserDetailContainer from "../containers/UserDetailContainer";

function UserList({
  users,
  handleEditUser,
  handleDeleteUser,
  resendActivationLink,
  groups,
  currentUser
}) {
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const handleEdit = user => {
    handleEditUser(user);
  };

  const handleDelete = user => {
    handleDeleteUser(user);
  };

  const handleResendActivationLink = user => {
    resendActivationLink(user);
  };

  const groupName = groupId => {
    const group = groups.find(group => group.id === groupId);
    return group ? <span className="m-1 chip"> {group.name} </span> : "";
  };

  const showRoles = groupIds => {
    return groupIds.map(g => groupName(g));
  };

  return (
    <div>
      <CustomModal show={showModal} onHide={() => setShowModal(false)}>
        <UserDetailContainer userId={selectedUserId} />
      </CustomModal>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th className="p-3">First Name</th>
            <th className="p-3">Last Name</th>
            <th className="p-3">Email</th>
            <th className="p-3">Groups</th>
            <th className="p-3">Role</th>
            <th className="p-3">Edit</th>
          </tr>
        </thead>
        <tbody>
          {users
            .sort((a, b) => a.id - b.id)
            .map(u => {
              return (
                <tr key={u.id}>
                  <td className="p-2 align-middle text-capitalize">
                    {u.firstname}
                  </td>
                  <td className="p-2 align-middle text-capitalize">
                    {u.lastname}
                  </td>
                  <td className="p-2 align-middle">{u.email}</td>
                  <td className="p-2 align-middle"> {showRoles(u.groups)} </td>
                  <td className="p-2 align-middle">{u.role}</td>
                  <td className="p-2 align-middle">
                    {/* <Button
                      variant="info"
                      onClick={() => {
                        setSelectedUserId(u.id);
                        setShowModal(true);
                      }}
                    >
                      Edit
                    </Button> */}
                    <FeatureAwareContainer
                      requiredRole={
                        u.role === "ADMINISTRATOR"
                          ? "ADMINISTRATOR"
                          : "COMPANY_ADMINISTRATOR"
                      }
                    >
                      <Dropdown drop="start">
                        <Dropdown.Toggle
                          className="remove-toggle-arrow"
                          variant="default"
                          style={{ border: "none", borderRadius: "50%" }}
                          disabled={
                            u.role === "ADMINISTRATOR" ||
                            u.id === currentUser.id
                              ? true
                              : false
                          }
                        >
                          <FontAwesomeIcon
                            id={`target-user-${u.id}`}
                            icon={faEllipsisH}
                            className="primary-color"
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            style={{ padding: "10px" }}
                            onClick={() => handleEdit(u)}
                          >
                            {" "}
                            <FontAwesomeIcon
                              className="primary-color"
                              style={{ marginRight: "5px" }}
                              icon={faEdit}
                              className="primary-color"
                            />{" "}
                            Edit{" "}
                          </Dropdown.Item>

                          <Dropdown.Item
                            style={{ padding: "10px" }}
                            onClick={() => handleResendActivationLink(u)}
                          >
                            {" "}
                            <FontAwesomeIcon
                              className="primary-color"
                              style={{ marginRight: "5px" }}
                              icon={faEnvelopeOpen}
                            />{" "}
                            Resend activitation link{" "}
                          </Dropdown.Item>

                          <Dropdown.Item
                            style={{ padding: "10px" }}
                            href="#"
                            onClick={() => handleDelete(u)}
                          >
                            <FontAwesomeIcon
                              className="primary-color"
                              style={{ marginRight: "5px" }}
                              icon={faTrashAlt}
                            />{" "}
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </FeatureAwareContainer>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
}

export default UserList;

UserList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired
    }).isRequired
  ).isRequired
};
