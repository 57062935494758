import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ConfirmationModal from "../components/ConfirmationModal";

const offDayTypes = ["HOLIDAY", "DAY_OFF"];

function HolidayForm({ modifiedItem, handleSaveHoliday, deleteHoliday }) {
  const [name, setName] = useState(modifiedItem?.name || "");
  const [description, setDescription] = useState(
    modifiedItem?.description || ""
  );
  const [offDate, setOffDate] = useState(
    modifiedItem?.offDate ? new Date(modifiedItem?.offDate) : ""
  );
  const [offDayType, setOffDayType] = useState(
    modifiedItem?.offDayType || offDayTypes[0]
  );
  const [confirmationModal, setConfirmationModal] = useState(false);

  const handleSave = () => {
    const holidayObj = {
      id: modifiedItem?.id || undefined,
      name,
      description,
      offDate: moment(offDate).format("YYYY-MM-DD"),
      offDayType
    };
    handleSaveHoliday(holidayObj);
  };

  const handleDeleteHoliday = () => {
    deleteHoliday(modifiedItem.id);
    setConfirmationModal(false);
  };

  return (
    <div>
      <Form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Form.Group className={"mb-3"}>
          <Form.Label>Name*</Form.Label>
          <Form.Control
            value={name}
            type="text"
            placeholder="Holiday Name"
            onChange={e => setName(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group className={"mb-3"}>
          <Form.Label> Description </Form.Label>
          <Form.Control
            value={description}
            type="text"
            placeholder="Description"
            onChange={e => setDescription(e.target.value)}
          />
        </Form.Group>

        <Form.Group className={"mb-3"}>
          <Form.Label> Day off type </Form.Label>
          <Form.Select
            required
            value={offDayType}
            onChange={e => setOffDayType(e.target.value)}
            as="select"
          >
            {offDayTypes.map(d => (
              <option
                selected={modifiedItem?.offDayType === d ? true : false}
                key={d}
              >
                {d}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className={"mb-3"}>
          <Form.Label> Date </Form.Label>
          <DatePicker
            required
            dateFormat="yyyy-MM-dd"
            selected={offDate}
            onChange={date => setOffDate(date)}
          />
        </Form.Group>

        <Form.Group className={"mb-3"}>
          {modifiedItem && (
            <Button
              variant="danger"
              type="submit"
              onClick={() => setConfirmationModal(true)}
            >
              Delete
            </Button>
          )}

          <Button
            className={"float-end"}
            variant="primary"
            type="submit"
            onClick={handleSave}
            disabled={!name || !offDate || !offDayType}
          >
            Save
          </Button>
        </Form.Group>
      </Form>

      <ConfirmationModal
        titleColor={"red-color"}
        title={
          "Warning: You will not be able to recover this data once deleted"
        }
        description={`Are you sure you want to delete holiday ${name} permanently ?`}
        show={confirmationModal}
        hide={() => setConfirmationModal(false)}
        confirm={handleDeleteHoliday}
      />
    </div>
  );
}

export default HolidayForm;

HolidayForm.propTypes = {
  //   onSubmit: PropTypes.func.isRequired
};
