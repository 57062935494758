import React from "react";
import PropTypes from "prop-types";
import "./index.css";

import { connect } from "react-redux";

import moment from "moment";
import { Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import DeleteButton from "../components/DeleteButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faEdit,
  faCheckCircle,
  faTrashAlt,
  faArrowLeft
} from "@fortawesome/free-solid-svg-icons";

import * as types from "../actions/types";
import { getCompanyId } from "../selectors";

import CustomModal from "../components/CustomModal";
import ActionForm from "../components/ActionForm";
import CreatePlanForm from "../components/CreatePlanForm";
import CreateEventForm from "../components/CreateEventForm";
import BackNextButtons from "../components/BackNextButtons";

import FeatureAwareContainer from "./FeatureAwareContainer";
import ConfirmationModal from "../components/ConfirmationModal";
import { Card, FormSelect } from "react-bootstrap";
import CustomPagination from "../components/CustomPagination";
import StatusOverview from "../components/StatusOverview";
import { ExportExcelComponent } from "../components/ExportExcel";
import Actions from "./ActionsContainer";
import Loader from "../components/Loader";
import { CustomTimeRange } from "../components/CustomTimeRange";

const DAYS = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY"
];

const now = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
class ScheduleContainer extends React.Component {
  constructor(props) {
    super(props);

    const days =
      this.props.days && this.props.days > 0
        ? this.props.days
        : this.props.location.state && this.props.location.state.days
        ? this.props.location.state.days
        : 7;
    const edit = this.props.location.state
      ? this.props.location.state.edit
      : true;

    this.state = {
      showEditScheduleModal: false,
      showActionModal: false,
      showCreateEventModal: false,
      showEditActionModal: false,
      itemSelected: null,
      days,
      offsetDay: 0,
      edit,
      isOneDayActive: false,
      isOneWeekActive: true,
      isOneMonthActive: false,
      isThreeMonthsActive: false,
      copyActionItem: null,
      showConfirmationModal: false,
      deleteActionId: null,
      currentPage: 1,
      pageSize: "all",
      modifiedActionItem: null,
      colorFilter: "",
      dropdownButtonTitle: "1 Week",
      fromDate: moment()
        .subtract(7, "days")
        .format("YYYY-MM-DD")
    };
  }

  componentDidMount() {
    this.props.fetchScheduleDetails(
      this.props.companyId,
      this.props.scheduleId,
      this.state.currentPage,
      this.state.pageSize
    );

    this.props.fetchGroups(this.props.companyId);
    this.handleFetchEvents();
    this.props.fetchHolidays(this.props.companyId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.pageSize !== this.state.pageSize ||
      prevState.currentPage !== this.state.currentPage
    ) {
      this.props.fetchScheduleDetails(
        this.props.companyId,
        this.props.scheduleId,
        this.state.currentPage,
        this.state.pageSize
      );
    }
    if (prevState.days !== this.state.days) {
      this.handleFetchEvents();
    }
  }

  handleFetchEvents = () => {
    this.props.fetchEvents(
      this.props.companyId,
      this.props.scheduleId,
      this.state.fromDate,
      this.state.days
    );
  };

  handleCurrentPage = currentPage => {
    this.setState({ currentPage });
  };

  handlePageSize = pageSize => {
    this.setState({ pageSize, currentPage: 1 });
  };

  createOrUpdateAction = (
    name,
    comment,
    goodCriteria,
    badCriteria,
    planType,
    inputType,
    selectedDays
  ) => {
    const { companyId, scheduleId } = this.props;
    if (this.state.modifiedActionItem?.id) {
      this.props.updateAction(
        this.props.companyId,
        this.props.scheduleId,
        this.state.modifiedActionItem.id,
        {
          name,
          comment,
          goodCriteria,
          badCriteria,
          planType,
          inputType,
          selectedDays
        },
        this.state.currentPage,
        this.state.pageSize
      );
      this.setState({ modifiedActionItem: null });
    } else {
      this.props.createAction(companyId, scheduleId, {
        name,
        comment,
        goodCriteria,
        badCriteria,
        planType,
        inputType,
        selectedDays
      });
    }
  };

  updateEvent = (actionId, eventId, value, comment, date, extraValues) => {
    const { companyId, scheduleId } = this.props;
    this.props.updateEvent(
      companyId,
      scheduleId,
      actionId,
      eventId,
      {
        eventDate: date,
        value,
        comment,
        extraValues
      },
      this.state.fromDate,
      this.state.days
    );
  };

  createEvent = (actionId, value, comment, date, extraValues) => {
    const { companyId, scheduleId } = this.props;
    this.props.createEvent(
      companyId,
      scheduleId,
      actionId,
      {
        eventDate: date,
        value,
        comment,
        extraValues
      },
      this.state.fromDate,
      this.state.days
    );
  };

  selectTimeSpan = (actionId, timeSelected, item) => {
    const time = moment(timeSelected).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    });
    const action = this.props.schedule.actions.find(a => a.id === actionId);
    const formattedTime = time.format("YYYY-MM-DD");
    this.setState({
      showCreateEventModal: true,
      itemSelected: {
        formattedTime,
        actionId,
        ...item,
        goodCriteria: action.goodCriteria,
        badCriteria: action.badCriteria,
        comment: action.comment,
        name: action.name
      }
    });
  };

  updateAction = (actionId, fields) => {
    this.props.updateAction(
      this.props.companyId,
      this.props.scheduleId,
      actionId,
      fields,
      this.state.currentPage,
      this.state.pageSize
    );
    this.setState({ [`showEditActionModal-${actionId}`]: false });
  };

  updateSchedule = fields => {
    this.props.updateSchedule(
      this.props.companyId,
      this.props.scheduleId,
      fields
    );
    this.setState({ showEditScheduleModal: false });
  };

  getComment = (actions, itemSelected) => {
    let defaultComment = "";
    try {
      defaultComment = actions
        .find(a => a.id === itemSelected.actionId)
        .events.find(e => e.id === itemSelected.id).comment;
    } catch (err) {}
    return defaultComment;
  };

  renderDeleteButton = actionId => {
    return (
      <DeleteButton
        bsStyle={"danger"}
        onClick={() => this.handleDeleteAction(actionId)}
      >
        <FontAwesomeIcon icon={faTrashAlt} />{" "}
        <span style={{ marginLeft: "2px" }}> {`Delete`} </span>
      </DeleteButton>
    );
  };

  handleDeleteAction = actionId => {
    this.setState({ showConfirmationModal: true, deleteActionId: actionId });
  };

  confirmDeleteAction = () => {
    this.props.deleteAction(
      this.props.companyId,
      this.props.scheduleId,
      this.state.deleteActionId
    );
    this.hideConfirmationModal();
  };

  handleCopyAction = action => {
    this.setState({ copyActionItem: action, showConfirmationModal: true });
  };

  hideConfirmationModal = () => {
    this.setState({
      showConfirmationModal: false,
      copyActionItem: null,
      deleteActionId: null,
      showActionModal: false,
      modifiedActionItem: null
    });
  };

  confirmCopyAction = () => {
    this.props.copyAction(
      this.props.companyId,
      this.props.schedule.id,
      this.state.copyActionItem.id
    );
    this.setState({
      copyActionItem: null,
      showConfirmationModal: false,
      currentPage: 1
    });
  };

  handleEditAction = action => {
    this.setState({ modifiedActionItem: action, showActionModal: true });
  };

  deleteEvent = (actionId, eventId) => {
    this.props.deleteEvent(
      this.props.companyId,
      this.props.scheduleId,
      actionId,
      eventId,
      this.state.fromDate,
      this.state.days
    );

    this.setState({
      showCreateEventModal: false,
      itemSelected: null
    });
  };

  handleGoBack = () => {
    this.props.history.push("/plans");
  };

  handleFilterByColors = event => {
    const colorFilter = event.target.value;
    this.setState({ colorFilter });
  };

  getColor = (value, shouldRegister) => {
    if (!shouldRegister) {
      return "F0F8FF";
    } else {
      if (value === "TRUE") {
        return "59B78C";
      } else if (value === "FALSE") {
        return "D0021B";
      } else if (value === "YELLOW") {
        return "F8D6A7";
      } else {
        return "808080";
      }
    }
  };

  getValue = (value, shouldRegister) => {
    if (!shouldRegister) {
      return " ";
    } else {
      if (value === "TRUE") {
        return "TRUE";
      } else if (value === "FALSE") {
        return "FALSE";
      } else if (value === "YELLOW") {
        return "YELLOW";
      } else if (value === "INCAPABLE_TO_MARK") {
        return "INCAPABLE_TO_MARK";
      } else {
        return "UNMARKED";
      }
    }
  };

  getValueforActionByDate = (events, to, daysback, days) => {
    const row = {};

    Array.from(Array(daysback).keys())
      .map(a => {
        const date = moment(to.format("YYYY-MM-DD")).subtract(a, "days");
        const isHoliday = this.props.holidays.find(
          holiday => moment(date).format("YYYY-MM-DD") === holiday.offDate
        );
        let shouldRegister = days.includes(DAYS[date.isoWeekday() - 1]);
        if (isHoliday) {
          shouldRegister = false;
        }

        const foundEvent = events.find(e => date.isSame(e.eventDate, "day"));
        const value = this.getValue(foundEvent?.value, shouldRegister);
        row[[date.format("DD-MM-YYYY")]] = value;
      })
      .reverse();
    return row;
  };

  generateColumns = (to, daysback) => {
    return Array.from(Array(daysback).keys())
      .map(a => {
        const date = moment(to.format("YYYY-MM-DD")).subtract(a, "days");
        return date.format("DD-MM-YYYY");
      })
      .reverse();
  };

  renderExcelExport = () => {
    const { days, offsetDay } = this.state;
    const from = moment(now).subtract(offsetDay, "days");
    const exportData = [];
    const cols = this.generateColumns(from, days);
    let row = {};
    this.props.schedule.actions.map(action => {
      row["ActionName"] = action.name;

      const res =
        action.days &&
        this.getValueforActionByDate(action.events, from, days, action.days);
      if (res) {
        row = { ...row, ...res };
      }
      exportData.push(row);
    });

    const dataColumns = ["ActionName", ...cols];
    return (
      <div>
        <ExportExcelComponent
          data={exportData}
          columns={dataColumns}
          fileName={this.props.schedule.name}
        />
      </div>
    );
  };

  handleTimeRangeFilter = fromDate => {
    const date1 = new Date(fromDate);
    const date2 = new Date();

    const timeDifference = date2.getTime() - date1.getTime();
    let numberOfDays = Math.floor(timeDifference / (1000 * 3600 * 24));

    numberOfDays = numberOfDays < 1 ? 1 : numberOfDays;
    this.setState({ fromDate, days: numberOfDays });
  };

  render() {
    if (!this.props.schedule) {
      return <div>...loading</div>;
    }
    const { days, offsetDay } = this.state;

    const from = moment(now).subtract(offsetDay, "days");

    return (
      <div>
        <Card
          style={{
            boxShadow:
              "0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)"
          }}
        >
          <Card.Body>
            <Row>
              <Col md={"1"}>
                <Button
                  onClick={this.handleGoBack}
                  variant="light"
                  style={{ width: "100%", height: "100%" }}
                >
                  <FontAwesomeIcon
                    className="align-middle"
                    icon={faArrowLeft}
                  />
                </Button>
              </Col>

              <Col>
                <Card.Title
                  className="text-capitalize"
                  style={{ display: "inline" }}
                >
                  {this.props.schedule.name}
                </Card.Title>
                <span className="me-5 float-end">
                  <StatusOverview
                    schedule={this.props.schedule}
                    from={from}
                    days={days}
                    holidays={this.props.holidays}
                  />
                </span>
                <Card.Text>{this.props.schedule.description}</Card.Text>
              </Col>

              <Col md={"2"}>
                {(() => {
                  if (this.state.edit) {
                    return (
                      <FeatureAwareContainer requiredRole="COMPANY_ADMINISTRATOR">
                        <div style={{ paddingBottom: 3 }} align="right">
                          <OverlayTrigger
                            overlay={
                              <Tooltip id={`tooltip-edit-plan`}>
                                Edit Plan
                              </Tooltip>
                            }
                          >
                            <Button
                              className="round-button"
                              onClick={() =>
                                this.setState({ showEditScheduleModal: true })
                              }
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                          </OverlayTrigger>

                          <OverlayTrigger
                            overlay={
                              <Tooltip id={`tooltip-create-action`}>
                                Create Action
                              </Tooltip>
                            }
                          >
                            <Button
                              className="round-button ms-4"
                              onClick={() =>
                                this.setState({ showActionModal: true })
                              }
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </Button>
                          </OverlayTrigger>
                        </div>
                      </FeatureAwareContainer>
                    );
                  }
                })()}
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <br />
        <Row>
          <Col className="mb-3">
            {(() => {
              if (
                this.props.schedule &&
                this.props.schedule.actions.length > 0
              ) {
                return (
                  <BackNextButtons
                    onNext={() => {
                      this.setState({ offsetDay: this.state.offsetDay - days });
                    }}
                    onBack={() => {
                      this.setState({ offsetDay: this.state.offsetDay + days });
                    }}
                    backDisabled={false}
                    nextDisabled={false}
                  />
                );
              }
            })()}

            {(() => {
              if (days > 1) {
                return (
                  <span variant="info" className="ms-3 p-2 primary-color">
                    {`${moment(from)
                      .subtract(days - 1, "days")
                      .format("YYYY-MM-DD")} - ${from.format("YYYY-MM-DD")} `}
                  </span>
                );
              } else {
                return (
                  <span variant="info" className="ms-3 p-2 primary-color">
                    {`${from.format("YYYY-MM-DD")}`}
                  </span>
                );
              }
            })()}
          </Col>

          <Col className="mb-3 text-md-end">
            <FormSelect
              aria-label="Default"
              aria-describedby="inputGroup-sizing-default"
              style={{
                border: "none",
                borderRadius: "none",
                borderBottom: "1px solid #ddd",
                display: "inline",
                width: "auto"
              }}
              onChange={this.handleFilterByColors}
              className="remove-box-shadow d-xs-flex me-4"
            >
              <option value="">Filter by color</option>
              <option value="TRUE">Green</option>
              <option value="YELLOW">Yellow</option>
              <option value="FALSE">Red</option>
              <option value="NONE">Grey</option>
            </FormSelect>

            <CustomTimeRange
              handleFilter={this.handleTimeRangeFilter}
              defaultDropdownText="1 Week"
            />
          </Col>
        </Row>

        <CustomModal
          title={"Edit Plan"}
          show={this.state.showEditScheduleModal}
          onHide={() => this.setState({ showEditScheduleModal: false })}
        >
          <CreatePlanForm
            onSubmit={async (name, description, selectedGroupIds) => {
              this.updateSchedule({ name, description, selectedGroupIds });
            }}
            modifiedItem={this.props.schedule}
            groups={this.props.groups}
          />
        </CustomModal>

        <CustomModal
          title={
            this.state.modifiedActionItem ? "Update action" : "Create Action"
          }
          show={this.state.showActionModal}
          onHide={() =>
            this.setState({ showActionModal: false, modifiedActionItem: null })
          }
        >
          <ActionForm
            onSubmit={(
              name,
              description,
              goodCriteria,
              badCriteria,
              planType,
              inputType,
              selectedDays
            ) => {
              this.createOrUpdateAction(
                name,
                description,
                goodCriteria,
                badCriteria,
                planType,
                inputType,
                selectedDays
              );
              this.setState({ showActionModal: false });
            }}
            modifiedItem={this.state.modifiedActionItem}
            deleteButton={
              this.state.modifiedActionItem &&
              this.renderDeleteButton(this.state.modifiedActionItem?.id)
            }
          />
        </CustomModal>

        {this.state.showCreateEventModal && (
          <CustomModal
            title={"Set Event"}
            show={this.state.showCreateEventModal}
            onHide={() => this.setState({ showCreateEventModal: false })}
          >
            {(() => {
              if (this.state.itemSelected) {
                const { formattedTime, actionId } = this.state.itemSelected;
                const action = this.props.schedule.actions.find(
                  a => a.id === actionId
                );
                return (
                  <div>
                    {action && (
                      <CreateEventForm
                        date={formattedTime}
                        actionId={actionId}
                        inputType={action.inputType}
                        planType={action.planType}
                        action={this.state.itemSelected}
                        extraValues={this.state.itemSelected.extraValues}
                        defaultValue={this.state.itemSelected.value}
                        defaultComment={this.getComment(
                          this.props.schedule.actions,
                          this.state.itemSelected
                        )}
                        defaultIncapableToMark={
                          this.state.itemSelected.value === "INCAPABLE_TO_MARK"
                            ? true
                            : false
                        }
                        isCreate={this.state.itemSelected.value === "NONE"}
                        onSubmit={(
                          actionId,
                          value,
                          comment,
                          date,
                          create,
                          extraValues
                        ) => {
                          if (create) {
                            this.createEvent(
                              actionId,
                              value,
                              comment,
                              date,
                              extraValues
                            );
                          } else {
                            this.updateEvent(
                              actionId,
                              this.state.itemSelected.id,
                              value,
                              comment,
                              date,
                              extraValues
                            );
                          }
                          this.setState({
                            showCreateEventModal: false,
                            itemSelected: null
                          });
                        }}
                        deleteEvent={this.deleteEvent}
                      />
                    )}
                  </div>
                );
              }
            })()}
          </CustomModal>
        )}

        <Row style={{ marginBottom: "20px", marginTop: "20px" }}>
          <Col>
            {this.props.eventsLoading ? (
              <Loader />
            ) : (
              <Actions
                actions={this.props.schedule.actions}
                from={from}
                days={days}
                colorFilter={this.state.colorFilter}
                userRole={this.props.userRole}
                handleEditAction={this.handleEditAction}
                handleCopyAction={this.handleCopyAction}
                selectTimeSpan={this.selectTimeSpan}
                holidays={this.props.holidays}
              />
            )}
          </Col>
        </Row>

        {this.state.copyActionItem && (
          <ConfirmationModal
            title={"Copy Action"}
            description={`Are you sure you want to copy action ${this.state.copyActionItem.name}?`}
            show={this.state.showConfirmationModal}
            hide={this.hideConfirmationModal}
            confirm={this.confirmCopyAction}
            confirmButtonText={
              <span>
                <FontAwesomeIcon
                  style={{ marginRight: "2px" }}
                  icon={faCheckCircle}
                />{" "}
                {`Yes`}{" "}
              </span>
            }
            confirmButtonVariant="primary"
          />
        )}

        {this.state.deleteActionId && (
          <ConfirmationModal
            titleColor={"red-color"}
            title={"Delete Action"}
            description={`Are you sure you want to delete this action?`}
            show={this.state.showConfirmationModal}
            hide={this.hideConfirmationModal}
            confirm={this.confirmDeleteAction}
          />
        )}

        {this.props.schedule.total_actions >= 1 && (
          <Row>
            <Col>{this.renderExcelExport()}</Col>
            <Col>
              <CustomPagination
                handleCurrentPage={this.handleCurrentPage}
                handlePageSize={this.handlePageSize}
                pageSize={this.state.pageSize}
                currentPage={this.state.currentPage}
                totalItems={this.props.schedule.total_actions}
              />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const getEvents = (state, companyId, scheduleId) => {
  if (
    state.eventsReducer &&
    state.eventsReducer[`${companyId}-${scheduleId}`]
  ) {
    return state.eventsReducer[`${companyId}-${scheduleId}`].events;
  }
  return [];
};

const getScheduleWithEvents = (schedule, events) => {
  if (!schedule) return null;
  const actionsWithEvents = schedule.schedule.actions.map(a => ({
    ...a,
    events: events.filter(e => e.actionId === a.id)
  }));
  return { ...schedule.schedule, actions: actionsWithEvents };
};

const mapStateToProps = (state, ownProps) => {
  const scheduleId = parseInt(ownProps.match.params.scheduleId, 10);
  const daysFromParams = parseInt(ownProps.match.params.days, 10);
  const companyId = getCompanyId(state);
  const schedule =
    state.scheduleDetails && state.scheduleDetails.schedules
      ? state.scheduleDetails.schedules.find(
          s => s.schedule && s.schedule.id === scheduleId
        )
      : null;
  const groups = state.groups?.groups || [];
  const holidays = state.holidays?.holidays || [];

  const userRole =
    state.user && state.user.currentUser
      ? state.user.currentUser.role
      : "UNKNOWN";
  const eventsLoading = state.eventsReducer.loading;
  return {
    companyId,
    scheduleId,
    days: daysFromParams,
    schedule: getScheduleWithEvents(
      schedule,
      getEvents(state, companyId, scheduleId)
    ),
    groups,
    userRole,
    eventsLoading,
    holidays
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchScheduleDetails: (companyId, scheduleId, currentPage, pageSize) =>
      dispatch({
        type: types.FETCH_SCHEDULE_DETAIL_REQUEST,
        companyId,
        scheduleId,
        currentPage,
        pageSize
      }),
    fetchEvents: (companyId, scheduleId, fromDate, days) => {
      return dispatch({
        type: types.FETCH_EVENTS_REQUEST,
        companyId,
        scheduleId,
        fromDate,
        days
      });
    },
    createEvent: (companyId, scheduleId, actionId, event, fromDate, days) =>
      dispatch({
        type: types.CREATE_EVENT_REQUEST,
        companyId,
        scheduleId,
        actionId,
        event,
        fromDate,
        days
      }),
    updateAction: (
      companyId,
      scheduleId,
      actionId,
      action,
      currentPage,
      pageSize
    ) =>
      dispatch({
        type: types.UPDATE_ACTION_REQUEST,
        companyId,
        scheduleId,
        actionId,
        action,
        currentPage,
        pageSize
      }),
    updateSchedule: (companyId, scheduleId, schedule, currentPage, pageSize) =>
      dispatch({
        type: types.UPDATE_SCHEDULE_REQUEST,
        companyId,
        scheduleId,
        schedule,
        currentPage,
        pageSize
      }),
    updateEvent: (
      companyId,
      scheduleId,
      actionId,
      eventId,
      event,
      fromDate,
      days
    ) =>
      dispatch({
        type: types.UPDATE_EVENT_REQUEST,
        companyId,
        scheduleId,
        actionId,
        eventId,
        event,
        fromDate,
        days
      }),
    deleteEvent: (companyId, scheduleId, actionId, eventId, fromDate, days) =>
      dispatch({
        type: types.DELETE_EVENT_REQUEST,
        companyId,
        scheduleId,
        actionId,
        eventId,
        fromDate,
        days
      }),
    deleteAction: (companyId, scheduleId, actionId, currentPage, pageSize) => {
      dispatch({
        type: types.DELETE_ACTION_REQUEST,
        companyId,
        scheduleId,
        actionId,
        currentPage,
        pageSize
      });
    },
    createAction: (companyId, scheduleId, action) =>
      dispatch({
        type: types.CREATE_ACTION_REQUEST,
        companyId,
        scheduleId,
        action
      }),

    fetchGroups: companyId =>
      dispatch({ type: types.FETCH_GROUPS_REQUEST, companyId }),

    copyAction: (companyId, scheduleId, actionId) =>
      dispatch({
        type: types.COPY_ACTION_REQUEST,
        companyId,
        scheduleId,
        actionId
      }),

    fetchHolidays: companyId =>
      dispatch({ type: types.FETCH_HOLIDAY_REQUEST, companyId })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleContainer);

ScheduleContainer.propTypes = {
  copyAction: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  fetchScheduleDetails: PropTypes.func.isRequired,
  deleteAction: PropTypes.func.isRequired,
  fetchEvents: PropTypes.func.isRequired,
  createEvent: PropTypes.func.isRequired,
  updateEvent: PropTypes.func.isRequired,
  createAction: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  scheduleId: PropTypes.number.isRequired,
  days: PropTypes.number,
  schedule: PropTypes.shape({
    name: PropTypes.string.isRequired,
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        goodCriteria: PropTypes.string,
        badCriteria: PropTypes.string
      })
    )
  })
};
